// src/app/app.component.ts

import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { LicenseManager } from 'ag-grid-enterprise';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  constructor(public auth: AuthService) {
    LicenseManager.setLicenseKey('CompanyName=Liberty Bankers Life Insurance Company,LicensedApplication=LBIG Agent Portal,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=2,LicensedProductionInstancesCount=1,AssetReference=AG-024361,ExpiryDate=10_January_2023_[v2]_MTY3MzMwODgwMDAwMA==70cbf000a477de0191960d19dcc26818');}
}
