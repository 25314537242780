import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[ag-grid-resize]'
})
export class AgGridResizeDirective {
  private gridApi: any;
  @HostListener('window:resize')
  onResize() {
    if (!this.gridApi) return;

    setTimeout(() => {
      this.gridApi.sizeColumnsToFit();
    });
  }

  @HostListener('gridReady', ['$event'])
  onGridReady(params: any) {
    this.gridApi = params.api;
    params.api.sizeColumnsToFit();
  }
}
