import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment as env } from '../../../../environments/environment';


@Component({
  selector: 'app-eapp-redirect',
  templateUrl: './eappredirect.component.html',
  styles: [
  ]
})
export class EAppRedirectComponent implements OnInit {
  caseId: string | null = "";
  constructor(private router: Router, private route: ActivatedRoute) { }


  ngOnInit(): void {
    var url = new URL(window.location.href);
    var caseId = url.searchParams.get("case") as string;

    if (caseId !== "")
      this.router.navigate(["/redirecting"]).then(result =>
      {
        window.location.href = `${env.dev.eAppRedirectUrlPart1 + encodeURIComponent(env.dev.eAppRedirectUrlPart2 + caseId)}`;
      });
  }
}
