import { Component, OnInit } from '@angular/core';
import { CarouselImage } from '../../../models/carouselimage';
import { CloudStorageService } from '../../../services/cloud-storage';
import { SiteParameterService } from '../../../services/siteparameter.service';

@Component({
  selector: 'app-hero',
  templateUrl: './hero.component.html',
})
export class HeroComponent implements OnInit {
  heroLogo = 'https://cdn.auth0.com/blog/auth0-angular-sample/assets/logo.png';
  public carouselImages!: Array<CarouselImage>;


  constructor(public cloudStorageService: CloudStorageService, private siteParmService: SiteParameterService) { }

  ngOnInit(): void {
    this.cloudStorageService.getCarouselImages().subscribe((carouselImages) => {this.carouselImages = carouselImages;});
  }
}
