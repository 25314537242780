import { Component, OnInit } from '@angular/core';
import { Profile } from '../../../models/profile';
import { AgentGrid } from '../../../models/agentGrid';
import { SiteParameterService } from '../../../services/siteparameter.service'
import { CloudStorageService } from '../../../services/cloud-storage';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-agentgrid',
  templateUrl: './agentgrid.component.html',
  styleUrls: ['./agentgrid.component.css']
})
export class AgentGridComponent implements OnInit {
  private gridApi: any;
  private gridColumnApi: any;
  public linkedAgents$?: Observable<AgentGrid[]>
  private profile!: Profile;
  public columnDefs = [
    {
      headerName: 'Default',
      width: 100,
      checkboxSelection: true,
      cellStyle: { 'pointer-events': 'none' },
      cellRenderer: (params: any) => {
        if (params.node.data.selected == 1) {
          params.node.setSelected(true);
        };
      },
      field: 'selected'
    },
    { headerName: 'Agent ID', field: 'agentId' },
    { headerName: 'Channel', field: 'channelName' },
    { headerName: 'Channel ID', field: 'channelId', hide: true },
    { headerName: 'Status', field: 'status' },
    { headerName: 'NPN', field: 'npn' },
    { headerName: 'Manager', field: 'manager' },
    { headerName: 'IMO', field: 'imo' }
  ];
  public defaultColumnDef = {
    sortable: true,
    resizable: true,
  };

  constructor(public cloudStorageService: CloudStorageService, private siteParmService: SiteParameterService) { }

  ngOnInit(): void {
    this.profile = this.siteParmService.getStaticProfile();
  }
  onRowSelected(event: any) {
    if (event.node.selected) {
      this.siteParmService.sendDefaultAgentUpdate(event.data.agentId);
      this.siteParmService.sendDefaultChannelUpdate(event.data.channelName);
    }
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
    this.linkedAgents$ = this.cloudStorageService.getLinkedAgents(this.profile.auth0Id);
  }
}
