<div class="row justify-content-center">
  <div class="col-md-12">
    <div class="card card-secondary">
      <div class="card-header">
        <h4 class="card-title w-100">
          <span>Route List</span>
        </h4>
      </div>
    </div>
    <ng-container *ngIf="showCollectionParameters">
      <div class="form-group row">
        <label class="col-md-1 col-form-label text-right">Search</label>
        <div class="col-md-2">
          <input type="text" id="search" class="form-control" (input)="onSearchChanged()" />
        </div>
        <label class="col-md-1 col-form-label text-right">Selected Agent</label>
        <div class="col-md-3">
          <!--<select id="agent" class="form-control" [(ngModel)]="agent" (change)="getDownlineAgentList($event.target.value)">
      <option *ngFor="let agent of downlineAgents" value="{{agent.agentID}}">
        {{agent.agentID}} - {{agent.agentName}}
      </option>
    </select>-->
          <select id="selectedAgent" class="form-control" [(ngModel)]="selectedAgentId" (change)="changeAgentId($event)">
            or
            <option *ngFor="let agent of agents" value="{{agent.agentId}}">
              {{agent.agentId}} - {{agent.agentName}}
            </option>
          </select>
        </div>
        <label class="col-md-2 col-form-label text-right">Collecting Agent/Manager</label>
        <div class="col-md-3">
          <select id="collectingAgent" class="form-control">
          </select>
        </div>
        <!--<div class="col-md-7 text-center">
          <input type="button" class="btn btn-secondary btn-sm input-block-level mr-2" value="Edit Route Group Contact" [disabled]="!enableEditRouteButton" />
          <input type="button" class="btn btn-secondary btn-sm input-block-level mr-2" value="Collect All Policies in Group" [disabled]="!enableCollectAllButton" (click)="openGroupCollectionForm()"/>
          <input type="button" class="btn btn-secondary btn-sm input-block-level" value="Delete All Collections in Group" [disabled]="!enableDeleteAllButton"  (click)="deleteGroupCollection(routeSequenceId)"/>
        </div>-->
        <!--{{ testText$ | async }}-->
      </div>
    </ng-container>
    <ng-container *ngIf="showCollectionList">
      <div class="row admin-box">
        <div class="col-md-12">
          <ag-grid-angular id="routeList" style="width: 100%; height: 640px;"
                           class="ag-theme-alpine"
                           [rowData]="routeList$ | async"
                           [columnDefs]="columnDefs"
                           [defaultColDef]="defaultColumnDef"
                           [autoGroupColumnDef]="autoGroupColumnDef"
                           [groupDefaultExpanded]="groupDefaultExpanded"
                           rowSelection='single'
                           [rowDeselection]="true"
                           [pagination]="true"
                           [paginationPageSize]="10"
                           [suppressRowClickSelection]="false"
                           (rowSelected)="onRowClicked($event)"
                           (gridReady)="onGridReady($event)"
                           [allowContextMenuWithControlKey]="true"
                           [getContextMenuItems]="getContextMenuItems"
                           [suppressContextMenu]="false"
                           [gridOptions]="gridOptions">
          </ag-grid-angular>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="showCollectionForm">
      <div class="card card-primary" id="CollectionForm">
        <div class="card-header">
          <div class="row">
            <div class="col-md-10">
              <h4 class="card-title w-100">
                <span>Collect Policy - {{policyNumber ? sequenceNo + " - " + policyNumber : "Loading..."}}</span>
              </h4>
            </div>
            <div class="col-md-2 my-auto text-right">
              <i class="fas fa-2x fa-times-circle" style="cursor:pointer" (click)="closeCollectionForm()"></i>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="showLapseAlert" class="alert alert-danger text-center" role="alert">Lapse Alert Notification</div>
      <div *ngIf="showNoPremiumCollected" class="alert alert-danger text-center" role="alert">No Premium Collected for this Policy</div>
      <form #addCollectionForm="ngForm" (ngSubmit)="addCollection(addCollectionForm.value)">
        <div class="card-body">
          <div class="row justify-content-center">
            <div class="col-md-3">
              <div class="form-group">
                <label>Total Modal Premium</label>
                <input type="text" class="form-control" name="modalPremium" id="modalPremium" readonly value="{{ modalPremium | currency }}">
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>Total Premium Due</label>
                <input type="text" class="form-control" name="premiumDue" id="premiumDue" readonly value="{{ premiumDue | currency }}">
              </div>
            </div>
          </div>
          <div class="row justify-content-center my-3">
            <div class="col-md-4">
              <div class="card card-secondary">
                <div class="card-body">
                  <div class="row justify-content-center">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Months To Be Paid</label>
                        <div class="input-group w-100 mb-3">
                          <button class="btn btn-secondary" type="button" (click)="decreaseMonthsPaid()">-</button>
                          <input type="text" class="form-control text-center" name="monthsPaid" id="monthsPaid" size="10" value="{{ monthsPaid }}" disabled>
                          <button class="btn btn-secondary" type="button" (click)="increaseMonthsPaid()">+</button>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Custom Collection Amt</label>
                        <div class="input-group mb-3">
                          <span class="input-group-text">$</span>
                          <input type="text" class="form-control" name="suspenseAmt" id="suspenseAmt" value="{{ suspenseAmount }}" (input)="onSuspenseAmtChanged($event)">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-md-2">
              <div class="form-group">
                <label>Premium Collected</label>
                <input type="text" class="form-control" name="premiumCollected" id="premiumCollected" readonly value="{{ premiumCollected | currency }}">
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label>Current Commission</label>
                <input type="text" class="form-control" name="currentCommRate" id="currentCommRate" readonly value="{{ currentCommRate | percent }}">
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label>Estimated Commission Amt</label>
                <input type="text" class="form-control" name="estimatedCommission" id="estimatedCommission" readonly value="{{ estimatedCommission | currency }}">
              </div>
            </div>
          </div>

        </div>
        <div class="card-footer">
          <div class="text-center">
            <input type="submit" class="btn btn-secondary input-block-level mr-3" name="saveButton" id="saveButton" value="Save">
            <input *ngIf="collectionId" type="button" class="btn btn-danger input-block-level mr-3" name="deleteButton" id="deleteButton" value="Delete" (click)="deleteCollection(collectionId)">
            <input type="button" class="btn btn-secondary input-block-level" name="cancelButton" id="cancelButton" value="Cancel" (click)="closeCollectionForm()">
          </div>
        </div>
      </form>
    </ng-container>

    <ng-container *ngIf="showGroupCollectionForm">
      <div class="card card-primary" id="groupCollectionForm">
        <div class="card-header">
          <div class="row">
            <div class="col-md-10">
              <h4 class="card-title w-100">
                <span>Collect All Policies - {{ sequenceNo }}</span>
              </h4>
            </div>
            <div class="col-md-2 my-auto text-right">
              <i class="fas fa-2x fa-times-circle" style="cursor:pointer" (click)="closeGroupCollectionForm()"></i>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="showNoGroupPremiumCollected" class="alert alert-danger text-center" role="alert">No Premium Collected for this Policy</div>
      <form #addGroupCollectionForm="ngForm" (ngSubmit)="addGroupCollection(addGroupCollectionForm.value)">
        <div class="card-body">
          <div class="row justify-content-center">
            <div class="col-md-3">
              <div class="form-group">
                <label>Total Modal Premium</label>
                <input type="text" class="form-control" name="modalGroupPremium" id="modalGroupPremium" readonly value="{{ groupModalPremium | currency }}">
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>Total Premium Due</label>
                <input type="text" class="form-control" name="premiumGroupDue" id="premiumGroupDue" readonly value="{{ groupPremiumDue | currency }}">
              </div>
            </div>
          </div>
          <div class="row justify-content-center my-3">
            <div class="col-md-4">
              <div class="card card-secondary">
                <div class="card-body">
                  <div class="row justify-content-center">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Months To Be Paid</label>
                        <div class="input-group w-100 mb-3">
                          <button class="btn btn-secondary" type="button" (click)="decreaseGroupMonthsPaid()">-</button>
                          <input type="text" class="form-control text-center" name="monthsGroupPaid" id="monthsGroupPaid" size="10" value="{{ monthsGroupPaid }}" disabled>
                          <button class="btn btn-secondary" type="button" (click)="increaseGroupMonthsPaid()">+</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-md-2">
              <div class="form-group">
                <label>Premium Collected</label>
                <input type="text" class="form-control" name="premiumGroupCollected" id="premiumGroupCollected" readonly value="{{ premiumGroupCollected | currency }}">
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label>Current Commission</label>
                <input type="text" class="form-control" name="currentGroupCommRate" id="currentGroupCommRate" readonly value="{{ currentGroupCommRate | percent }}">
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label>Estimated Commission Amt</label>
                <input type="text" class="form-control" name="estimatedGroupCommission" id="estimatedGroupCommission" readonly value="{{ estimatedGroupCommission | currency }}">
              </div>
            </div>
          </div>

        </div>
        <div class="card-footer">
          <div class="text-center">
            <input type="submit" class="btn btn-secondary input-block-level mr-3" name="saveGroupButton" id="saveGroupButton" value="Save">
            <input type="button" class="btn btn-secondary input-block-level" name="cancelGroupButton" id="cancelGroupButton" value="Cancel" (click)="closeGroupCollectionForm()">
          </div>
        </div>
      </form>
    </ng-container>

    <ng-container *ngIf="showSequenceNumberForm">
      <div class="card card-primary" id="SequenceNumberForm">
        <div class="card-header">
          <div class="row">
            <div class="col-md-10">
              <h4 class="card-title w-100">
                <span>Edit Sequence Number</span>
              </h4>
            </div>
            <div class="col-md-2 my-auto text-right">
              <i class="fas fa-2x fa-times-circle" style="cursor:pointer" (click)="closeSequenceNumberForm()"></i>
            </div>
          </div>
        </div>
      </div>
      <form #updateSequenceNumberForm="ngForm" (ngSubmit)="updateSequenceNumber(updateSequenceNumberForm.value)">
        <div class="card-body">
          <div class="row justify-content-center">
            <div class="col-md-3">
              <div class="form-group">
                <label>Sequence #</label>
                <input type="text" class="form-control" name="sequenceNo" id="sequenceNo" [(ngModel)]="sequenceNo">
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="text-center">
            <input type="submit" class="btn btn-secondary input-block-level mr-3" name="saveButton" id="saveButton" value="Save">
            <input type="button" class="btn btn-secondary input-block-level" name="cancelButton" id="cancelButton" value="Cancel" (click)="closeSequenceNumberForm()">
          </div>
        </div>
      </form>
    </ng-container>

    <ng-container *ngIf="showSequenceContactForm">
      <div class="card card-primary" id="SequenceContactForm">
        <div class="card-header">
          <div class="row">
            <div class="col-md-10">
              <h4 class="card-title w-100">
                <span>Edit Sequence Details {{contactName ? "" : "- Loading..."}}</span>
              </h4>
            </div>
            <div class="col-md-2 my-auto text-right">
              <i class="fas fa-2x fa-times-circle" style="cursor:pointer" (click)="closeSequenceContactForm()"></i>
            </div>
          </div>
        </div>
      </div>
      <form #updateSequenceContactForm="ngForm" (ngSubmit)="updateSequenceContact(updateSequenceContactForm.value)">
        <div class="card-body">
          <div class="row justify-content-center">
            <div class="col-md-6">
              <div class="form-group">
                <label>Sequence #</label>
                <input type="text" class="form-control" name="sequenceNo" id="sequenceNo" [(ngModel)]="sequenceNo">
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-md-6">
              <div class="form-group">
                <label>Name</label>
                <input type="text" class="form-control" name="contactName" id="contactName" [(ngModel)]="contactName">
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-md-6">
              <div class="form-group">
                <label>Address</label>
                <input type="text" class="form-control mb-1" name="contactAddressLine1" id="contactAddressLine1" [(ngModel)]="contactAddressLine1">
                <input type="text" class="form-control" name="contactAddressLine2" id="contactAddressLine2" [(ngModel)]="contactAddressLine2">
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-md-2">
              <div class="form-group">
                <label>City</label>
                <input type="text" class="form-control" name="contactCity" id="contactCity" [(ngModel)]="contactCity">
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label>State</label>
                <input type="text" class="form-control" name="contactState" id="contactState" [(ngModel)]="contactState">
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label>Zip</label>
                <input type="text" class="form-control" name="contactZip" id="contactZip" [(ngModel)]="contactZip">
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-md-6">
              <div class="form-group">
                <label>Email</label>
                <input type="text" class="form-control" name="contactEmail" id="contactEmail" [(ngModel)]="contactEmail">
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-md-6">
              <div class="form-group">
                <label>Phone</label>
                <input type="text" class="form-control" name="contactPhone" id="contactPhone" [(ngModel)]="contactPhone">
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-md-6">
              <div class="form-group">
                <label>Notes</label>
                <textarea class="form-control" rows="3" name="contactNotes" id="contactNotes" [(ngModel)]="contactNotes"></textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="text-center">
            <input type="submit" class="btn btn-secondary input-block-level mr-3" name="saveButton" id="saveButton" value="Save">
            <input type="button" class="btn btn-secondary input-block-level" name="cancelButton" id="cancelButton" value="Cancel" (click)="closeSequenceContactForm()">
          </div>
        </div>
      </form>
    </ng-container>

  </div>
</div>
