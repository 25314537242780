<!--src/app/pages/profile/profile.component.html-->
<div>
  <form #profileForm="ngForm" (ngSubmit)="onSubmit(profileForm.value)">
    <div class="card card-secondary">
      <div class="card-header">
        <h4 class="card-title w-100">
          <span>Profile Settings</span>
        </h4>
      </div>
      <div class="card-body box-profile">
        <div class="text-center">
          <img [src]="profile.avatarLink"
               alt="User's profile picture"
               class="rounded-circle img-fluid profile-picture" />
        </div>
        <h3 class="profile-username text-center"><b>{{profile.emailAddress}}</b></h3>
        <div class="form-group">
          <label>My Linked Agent IDs</label>
          <app-agentgrid></app-agentgrid>
        </div>
        <div class="form-group">
          <label>Display Name</label>
          <input class="form-control" name="displayName" [(ngModel)]="displayName" [value]="displayName">
        </div>
      </div>
      <div class="card-footer">
        <div class="text-center">
          <input type="submit" class="btn btn-secondary input-block-level" value="Save Changes">
        </div>
      </div>
    </div>
  </form>
</div>

