<div class="col-sm-12">
  <app-channelnews></app-channelnews>
</div>
<div class="nav-container mb-1">
  <nav class="navbar navbar-expand-md navbar-light bg-light">
    <div class="container-fluid justify-content-center">
      <ul class="nav navbar-nav" id="menu">
        <li class="nav-item">
          <a class="nav-link router-link-exact-active" id="Performance-tab" data-toggle="tab" href="#Performance">Performance</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="Policy-tab" data-toggle="tab" href="#Policy">Policy Lookup</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="Links-tab" data-toggle="tab" href="#Links">Docs & Tools</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="Reports-tab" data-toggle="tab" href="#Reports">Reports</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="Commissions-tab" data-toggle="tab" href="#Commissions">Statements</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="Agent-tab" data-toggle="tab" href="#Agent">Agent Info</a>
        </li>
        <app-agentpicker></app-agentpicker>
      </ul>
    </div>
  </nav>
</div>
<br />
<!-- Tab panes -->
<div class="tab-content clearfix" id="tabPanes">
  <div class="tab-pane active" id="Performance">
    <app-kpis></app-kpis>
  </div>
  <div class="tab-pane" id="Links">
    <app-quicklinks></app-quicklinks>
  </div>
  <div class="tab-pane" id="Policy">
    <app-policyinfo></app-policyinfo>
  </div>
  <div class="tab-pane" id="Reports">
    <app-reports></app-reports>
  </div>
  <div class="tab-pane" id="Commissions">
    <app-commissions></app-commissions>
  </div>
  <div class="tab-pane" id="Agent">
    <app-agent-info-message></app-agent-info-message>
    <div class="row">
      <div class="col-md-6">
        <app-agent-hierarchy></app-agent-hierarchy>
      </div>
      <div class="col-md-6">
        <app-agent-detail></app-agent-detail>
      </div>
    </div>
  </div>
</div>


