//import * as Rollbar from 'rollbar'; // When using Typescript < 3.6.0.
 import Rollbar from 'rollbar'; 
// However, it will only work when setting either `allowSyntheticDefaultImports` 
// or `esModuleInterop` in your Typescript options.

import {
  Injectable,
  Inject,
  InjectionToken,
  ErrorHandler
} from '@angular/core';

const rollbarConfig = {
  accessToken: '0dadd8790caf4e5da2bf738003214845',
  captureUncaught: true,
  captureUnhandledRejections: true,
};

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
  constructor(@Inject(RollbarService) private rollbar: Rollbar) { }

  handleError(err: any): void {
    try {
      //this.rollbar.error(err.originalError || err);
    }
    catch  {
      //swallow exception if our rollbar account has reached the monthly limit
    }
  }
}

export function rollbarFactory() {
  return new Rollbar(rollbarConfig);
}



