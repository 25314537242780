import { ValueFormatterParams } from "ag-grid-community";

export function currencyFormatter(params: ValueFormatterParams) {
  var currencyValue = isNaN(params.value) ? "" : formatNumber(params.value);
  return currencyValue;
}

export function formatNumber(number: number) {
  return number.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD'
  });
}
