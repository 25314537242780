import { Component, OnInit } from "@angular/core";
import { GridApi } from "ag-grid";
import { take } from "rxjs/operators";
import { Profile } from "../../../models/profile";
import { CloudStorageService } from "../../../services/cloud-storage";
import { OnPremStorageService } from "../../../services/onprem-storage";
import { SiteParameterService } from "../../../services/siteparameter.service";

@Component({
  selector: 'app-pendingcollections',
  templateUrl: './pendingcollections.component.html'
})
export class PendingCollectionsComponent implements OnInit {
  public pendingCollectionsRowData: [] = [];
  private gridApi!: GridApi;
  public noRowsTemplate: string = "";
  private gridColumnApi: any;

  public profile: Profile = <Profile>{};
  //private impersonationSubscription: Subscription;
  //public agents: AgentGrid[] = [];
  public agents: TempPendingCollectionsAgent[] = [];
  public selectedAgentId: string = "";
  public selectedAgentName: string = "";

  columnDefs = [
    { field: 'sequenceNo', headerName: 'Route Seq #' },
    { field: 'insuredName', headerName: 'Insured' },
    { field: 'policyNum', headerName: 'Policy Number' },
    { field: 'cashAmt', headerName: 'Cash' },
    { field: 'suspenseAmt', headerName: 'Suspense' },
    { field: 'estCommissionAmt', headerName: 'Est. Commission' }
  ];

  defaultColumnDef = {
    sortable: true,
    resizable: true,
  };

  constructor(public onPremService: OnPremStorageService, private siteParmService: SiteParameterService, public cloudStorageService: CloudStorageService,) {
    this.noRowsTemplate = this.siteParmService.getGridNoResults();
    //this.agentUpdate = this.siteParmService.getAgentUpdate().subscribe
    //  (message => {
    //    this.selectedAgentID = message.value;
    //    this.getdownlineList();
    //  });
    let agent: TempPendingCollectionsAgent = {
      agentId: "M1030301",
      agentName: "Stone, Todd A"
    };
    this.selectedAgentId = agent.agentId;
    this.selectedAgentName = agent.agentName;
    this.getPendingCollections();
  }

  ngOnInit(): void {
    this.profile = this.siteParmService.getStaticProfile();
    this.getAgentList();
  }

  getPendingCollections() {
    this.onPremService.getPendingCollections(this.selectedAgentId)
      .pipe(take(1))
      .subscribe(policyInfo => {
        this.pendingCollectionsRowData = policyInfo.data;
      });
  }

  getAgentList(): void {
    //this.cloudStorageService.getLinkedAgents(this.profile.auth0Id)
    //  .subscribe(agents => {
    //    this.agents = agents;
    //  });
    let agents: TempPendingCollectionsAgent[] = [];

    let agent1: TempPendingCollectionsAgent = {
      agentId: "M1030301",
      agentName: "Stone, Todd A"
    };
    let agent2: TempPendingCollectionsAgent = {
      agentId: "M1030665",
      agentName: "Hessenauer, James"
    };
    let agent3: TempPendingCollectionsAgent = {
      agentId: "AML11135",
      agentName: "LASTRAPES, MARK STEPHEN"
    };
    let agent4: TempPendingCollectionsAgent = {
      agentId: "LWWG",
      agentName: "GUERRIER, WILNER"
    };
    agents.push(agent1);
    agents.push(agent2);
    agents.push(agent3);
    agents.push(agent4);

    this.agents = agents;
  }

  changeAgentId(e: any) {
    this.selectedAgentId = e.target.value;

    //Temporarily acquire selected name for UserID
    let arr = e.target.options[e.target.options.selectedIndex].text.split("-");
    this.selectedAgentName = arr[1].trim();

    this.getPendingCollections();
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  }

  onSearchChanged() {
    this.gridApi.setQuickFilter(
      (document.getElementById('search') as HTMLInputElement).value
    );
  }
}

export interface TempPendingCollectionsAgent {
  agentId: string,
  agentName: string
}
