import { Component, OnInit } from '@angular/core';
import { Profile } from '../../../models/profile';
import { SiteParameterService } from '../../../services/siteparameter.service';

@Component({
  selector: 'app-adminPage',
  templateUrl: './admin.component.html',
  styles: [
  ]
})
export class AdminPageComponent implements OnInit {
  public profile!: Profile;
  public showAssociation = true;
  public showLinkCode = true;
  public showStatementSearch = true;

  constructor(private siteParmService: SiteParameterService) { }

  ngOnInit(): void {
    //this.profile = this.siteParmService.getStaticProfile();

    //if (this.profile.permissions && this.profile.permissions.find(x => x === "edit:associations")) {
    //  this.showAssociation = true;
    //}
    //if (this.profile.permissions && this.profile.permissions.find(x => x === "read:link-code")) {
    //  this.showLinkCode = true;
    //}
    //if (this.profile.permissions && this.profile.permissions.find(x => x === "read:statements")) {
    //  this.showStatementSearch = true;
    //}

    $('#menu li a').click(function () {
      $('#menu').find("*").removeClass("router-link-exact-active");
      $(this).addClass('router-link-exact-active');
    });
  }

}
