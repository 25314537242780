<div style="padding-top:50px;">
  <app-hero></app-hero>
</div>
<div *ngIf="showFirstTimeMessage && !showLinkMessage">
  <hr />
  <h2 class="my-2 text-center">How Does This Thing Work?</h2>
  <div class="row justify-content-center">
    <div class="col-md-6">
      <p>Since this is your first time take a look at the top right corner of the screen. The first menu item allows you to change your business channel. The second menu item provides access to your profile.</p>
    </div>
  </div>
</div>
<div *ngIf="showLinkMessage">
  <hr />
  <h2 class="my-2 text-center">How Do I See My Agent Data?</h2>
  <div class="row justify-content-center">
    <div class="col-md-6">
      <p>Your account profile is not yet linked to your Liberty Agent IDs.  In order to view your specific agent information – including policy details, commission data, and reports – please click the button below to perform a one-time link so that your login profile can be securely associated with your agent contracts.</p>
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-md-6">
      <a routerLink="/completeprofile" class="nav-link">
        <button class="btn btn-primary btn-block">
          Link my Agent IDs
        </button>
      </a>
    </div>
  </div>
</div>
