<div class="row">
  <div class="col-md-3">&nbsp;</div>
  <div *ngIf="auth.user$ | async as user" class="col-md-6">
    <form #profileForm="ngForm" (ngSubmit)="onSubmit(profileForm.value)" *ngIf="showForm">
      <div class="card card-outline">
        <div class="card-header">
          <h2 class="card-title"><b>Link my Agent IDs</b></h2>
        </div>
        <div class="card-body box-profile">
          <p class="card-text">In order to securely associate your agent contracts to your login account, we need to ask you for three details related to one of your Agent IDs that have been assigned to you during the contracting process.  Please enter the data below and use any one of your Agent IDs that we have assigned to you so we can safely verify you in our systems.</p>
          <div class="form-group">
            <label>DOB</label>
            <input type="text" class="form-control date-slashes" id="dob" placeholder="date of birth mm/dd/yyyy" value="" maxlength="10" name="dob" [(ngModel)]="dob"
                   onkeyup="this.value=this.value.replace(/^(\d\d)(\d)$/g,'$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g,'$1/$2').replace(/[^\d\/]/g,'')" (focus)="inputFocused($event)">
          </div>
          <div class="form-group">
            <label>Last 4 of SSN or EIN or Tax ID</label>
            <input type="text" class="form-control" id="ssn" placeholder="last 4 digits of ssn or ein or tax id" value="" minlength="4" maxlength="4" name="ssn" [(ngModel)]="ssn" (focus)="inputFocused($event)">
          </div>
          <div class="form-group">
            <label>Agent Number</label>
            <input type="text" class="form-control" id="ssn" placeholder="your agent number" value="" maxlength="100" name="agentId" [(ngModel)]="agentId" (focus)="inputFocused($event)">
          </div>
          <div class="card-body text-center">
            <div class="alert alert-light" role="alert">
              <i>If an LBIG support rep directs you to, click this <a (click)="btnLinkCode();" class="alert-link link-pointer">LINK</a> to proceed using a one-time code.</i>
            </div>
          </div>
          <div class="form-group mt-4" *ngIf="showLinkCode">
            <label>Enter One-Time Code Below</label>
            <div class="row justify-content-center mt-2">
              <div class="col-md-2">
                <input type="text" class="form-control form-control-lg text-center" id="linkCode1" name="linkCode1" maxlength="1" (keydown)="disableArrowKeys($event)" (keyup)="setFocus($event,1)" (focus)="inputFocused($event)" [(ngModel)]="linkCode1" oninput="this.value = this.value.toUpperCase()"/>
              </div>
              <div class="col-md-2">
                <input type="text" class="form-control form-control-lg text-center" id="linkCode2" name="linkCode2" maxlength="1" (keydown)="disableArrowKeys($event)" (keyup)="setFocus($event,2)" (focus)="inputFocused($event)" [(ngModel)]="linkCode2" oninput="this.value = this.value.toUpperCase()"/>
              </div>
              <div class="col-md-2">
                <input type="text" class="form-control form-control-lg text-center" id="linkCode3" name="linkCode3" maxlength="1" (keydown)="disableArrowKeys($event)" (keyup)="setFocus($event,3)" (focus)="inputFocused($event)" [(ngModel)]="linkCode3" oninput="this.value = this.value.toUpperCase()"/>
              </div>
              <div class="col-md-2">
                <input type="text" class="form-control form-control-lg text-center" id="linkCode4" name="linkCode4" maxlength="1" (keydown)="disableArrowKeys($event)" (keyup)="setFocus($event,4)" (focus)="inputFocused($event)" [(ngModel)]="linkCode4" oninput="this.value = this.value.toUpperCase()"/>
              </div>
              <div class="col-md-2">
                <input type="text" class="form-control form-control-lg text-center" id="linkCode5" name="linkCode5" maxlength="1" (keydown)="disableArrowKeys($event)" (keyup)="setFocus($event,5)" (focus)="inputFocused($event)" [(ngModel)]="linkCode5" oninput="this.value = this.value.toUpperCase()"/>
              </div>
              <div class="col-md-2">
                <input type="text" class="form-control form-control-lg text-center" id="linkCode6" name="linkCode6" maxlength="1" (keydown)="disableArrowKeys($event)" (focus)="inputFocused($event)" [(ngModel)]="linkCode6" oninput="this.value = this.value.toUpperCase()"/>
              </div>
            </div>
          </div>

        </div>
        <div class="card-footer">
          <div class="text-center">
            <input type="submit" class="btn btn-primary input-block-level col-sm-4" value="Verify My Agent ID">
            &nbsp;&nbsp;
            <input type="button" class="btn btn-secondary input-block-level col-sm-4" value="Cancel" (click)="btnClick();">
          </div>
        </div>
      </div>
    </form>
    <div *ngIf="showGrid">
      <div class="row justify-content-center">
        <div class="col-md-8">
          <div class="form-group">
            <label>You may change your default Agent ID at anytime using the profile link located in the drop down under the avatar at the top right of the screen or by clicking the button below.</label>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label>My Linked Agent IDs</label>
        <app-agentgrid></app-agentgrid>
      </div>

      <div class="row justify-content-center">
        <div class="col-md-4">
          <a routerLink="/profile" class="nav-link">
            <button class="btn btn-secondary btn-block">
              Change Default Agent ID
            </button>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
