import { AgentDownline } from "../models/downline";

export function ReturnDateRangeFromType(rangeType: string): [Date?, Date?] {

  var startDate: Date | null;
  var endDate: Date | null;

  switch (rangeType) {
    case "MTD": {
      let [firstDay, lastDay] = getFirstDayofMonth(new Date());
      startDate = firstDay;
      endDate = lastDay;
      break;
    }
    case "QTD": {
      let [firstDay, lastDay] = getFirstDayofQuarter(new Date());
      startDate = firstDay;
      endDate = lastDay;
      break;
    }
    case "WTD": {
      let [firstDay, lastDay] = getFirstDayofWeek(new Date());
      startDate = firstDay;
      endDate = lastDay;      
      break;
    }
    case "YTD": {      
      let [firstDay, lastDay] = getFirstDayofYear(new Date());
      startDate = firstDay;
      endDate = lastDay;
      break;
    }
    case "MTDP": {
      let [firstDay, lastDay] = getPreviousMonth(new Date());
      startDate = firstDay;
      endDate = lastDay;
      break;
    }
    case "QTDP": {
      let [firstDay, lastDay] = getPreviousQuarter(new Date());
      startDate = firstDay;
      endDate = lastDay;
      break;
    }
    case "WTDP": {
      let [firstDay, lastDay] = getPreviousWeek(new Date());
      startDate = firstDay;
      endDate = lastDay;
      break;
    }
    case "YTDP": {
      let [firstDay, lastDay] = getPreviousYear(new Date());
      startDate = firstDay;
      endDate = lastDay;
      break;
    }
    case "ALL": {
      startDate = null;
      endDate = null;
      break;
    }
  }

  return [startDate!, endDate!];
}

function getFirstDayofWeek(d: Date): [Date, Date] {
    const day = d.getDay(),
    diff = d.getDate() - day + (day == 0 ? -6:1);
  const firstday = new Date(d.setDate(diff));
  const lastday = new Date(firstday.getFullYear(), firstday.getMonth(), firstday.getDate() + 6);
  return [firstday, lastday]
}

function getPreviousWeek(d: Date): [Date, Date] {
    const day = d.getDay(), 
    diff = d.getDate() - day + (day == 0 ? -6:1);
    const firstday = new Date(d.setDate(diff - 7));
    const lastday = new Date(firstday.getFullYear(), firstday.getMonth(), firstday.getDate() +6);
    return[firstday, lastday]
}

function getFirstDayofMonth(d: Date): [Date, Date] {
    const monthDate = d.getMonth();
  const firstday = new Date(d.getFullYear(), monthDate, 1);
  const lastday = new Date(d.getFullYear(), monthDate + 1, 0);
  return [firstday, lastday];  
}

function getPreviousMonth(d: Date): [Date, Date] {
    const month = d.getMonth();
    const monthDate = month - 1;

    const firstday = new Date(d.getFullYear(), monthDate, 1);
    const lastday = new Date(d.getFullYear(), monthDate + 1, 0);

    return[firstday, lastday];
}

function getFirstDayofQuarter(d: Date): [Date, Date] {
    const quarter = Math.floor((d.getMonth() / 3));
  const firstday = new Date(d.getFullYear(), quarter * 3, 1);
  const lastday = new Date(firstday.getFullYear(), firstday.getMonth() + 3, 0);
  return [firstday, lastday];  
}

function getPreviousQuarter(d: Date): [Date, Date] {
    const quarter = Math.floor((d.getMonth() / 3));    
    const firstday = new Date(d.getFullYear(), quarter * 3 - 3, 1);
    const lastday = new Date(firstday.getFullYear(), firstday.getMonth() + 3, 0);

    return[firstday, lastday];
}

function getFirstDayofYear(d: Date): [Date, Date] {
    const year = d.getFullYear();
  const firstday = new Date(year, 0, 1);
  const lastday = new Date(year + 1, 0, 0);
  return [firstday, lastday];  
}

function getPreviousYear(d: Date): [Date, Date] {
    const year = d.getFullYear();
    const yearDate = year - 1;

    const firstday = new Date(yearDate, 0, 1);
    const lastday = new Date(yearDate + 1, 0, 0);

    return[firstday, lastday];
}

export function DateComparator(date1: string, date2: string) {
  const date1Number = monthToComparableNumber(date1);
  const date2Number = monthToComparableNumber(date2);
  if (date1Number === null && date2Number === null) {
    return 0;
  }
  if (date1Number === null) {
    return -1;
  }
  if (date2Number === null) {
    return 1;
  }
  return date1Number - date2Number;
}

function monthToComparableNumber(date: string) {
  if (date === undefined || date === null || date.length !== 10) {
    return null;
  }
  const yearNumber = Number.parseInt(date.substring(6, 10));
  const dayNumber = Number.parseInt(date.substring(3, 5));
  const monthNumber = Number.parseInt(date.substring(0, 2));
  return yearNumber * 10000 + monthNumber * 100 + dayNumber;
}
