import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'component-loading',
  templateUrl: './componentloading.component.html',
})
export class ComponentLoadingComponent implements OnInit {
  loadingImg =
    'https://cdn.auth0.com/blog/auth0-react-sample/assets/loading.svg';
  constructor() {}

  ngOnInit(): void {}
}
