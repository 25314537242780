import { Injectable, Injector } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { AuthService } from '@auth0/auth0-angular';
import { Observable } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { RollbarService } from '../core/rollbar';
import { environment as env } from '../../environments/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private injector: Injector,public auth: AuthService) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    var allowedList = env.dev.anonymousAllowedList.split("|");
    if (allowedList.some(item => request.url.includes(item))) {

      return next.handle(request);

    }

    return this.auth.getAccessTokenSilently().pipe(
      mergeMap(token => {
        const tokenReq = request.clone({
          headers: request.headers.set('Content-Type', 'application/json').set('Authorization', `Bearer ${token}`)
        });
        return next.handle(tokenReq);
      }),
      catchError((error: HttpErrorResponse) => {

        //const rollbar = this.injector.get(RollbarService);

        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = 'Error: ${ error.error.message }';

        } else {
          // server-side error
          errorMessage = 'Error Code: ${ error.status } \nMessage: ${ error.message }';
        }
        //rollbar.error(new Error(error.message).stack)
        return throwError(errorMessage);
      })
    );
  }

}

