import { Component, OnInit } from "@angular/core";
import { GridApi } from "ag-grid";
import { Observable } from "rxjs";
import { take } from "rxjs/operators";
import { Profile } from "../../../models/profile";
import { CloudStorageService } from "../../../services/cloud-storage";
import { OnPremStorageService } from "../../../services/onprem-storage";
import { SiteParameterService } from "../../../services/siteparameter.service";

@Component({
  selector: 'app-batchhistory',
  templateUrl: './batchhistory.component.html'
})
export class BatchHistoryComponent implements OnInit {
  public batchHistoryRowData: [] = [];
  private gridApi!: GridApi;
  public noRowsTemplate: string = "";
  private gridColumnApi: any;

  public profile: Profile = <Profile>{};
  //private impersonationSubscription: Subscription;
  //public agents: AgentGrid[] = [];
  public agents: TempBatchHistoryAgent[] = [];
  public selectedAgentId: string = "";
  public selectedAgentName: string = "";

  columnDefs = [
    { field: 'batchNum', headerName: 'Batch No.' },
    { field: 'cashAmt', headerName: 'Cash' },
    { field: 'suspenseAmt', headerName: 'Suspense' },
    { field: 'estCommAmt', headerName: 'Est. Commission' },
    { field: 'createDTM', headerName: 'Batch Date' },
    { field: 'collectionBatchStatus', headerName: 'Status' },
    { field: 'statusUpdateDTM', headerName: 'Status Date' }
  ];
  defaultColumnDef = {
    sortable: true,
    resizable: true,
  };

  constructor(public onPremService: OnPremStorageService, private siteParmService: SiteParameterService, public cloudStorageService: CloudStorageService,) {
    this.noRowsTemplate = this.siteParmService.getGridNoResults();
    //this.agentUpdate = this.siteParmService.getAgentUpdate().subscribe
    //  (message => {
    //    this.selectedAgentID = message.value;
    //    this.getdownlineList();
    //  });
    let agent: TempBatchHistoryAgent = {
      agentId: "M1030301",
      agentName: "Stone, Todd A"
    };
    this.selectedAgentId = agent.agentId;
    this.selectedAgentName = agent.agentName;
    this.getbatchHistory();
  }

  ngOnInit(): void {
    this.profile = this.siteParmService.getStaticProfile();
    this.getAgentList();
  }

  getbatchHistory() {
    this.onPremService.getBatchHistory(this.selectedAgentId)
      .pipe(take(1))
      .subscribe(policyInfo => {
        this.batchHistoryRowData = policyInfo.data;
      });
   }

  getAgentList(): void {
    //this.cloudStorageService.getLinkedAgents(this.profile.auth0Id)
    //  .subscribe(agents => {
    //    this.agents = agents;
    //  });
    let agents: TempBatchHistoryAgent[] = [];

    let agent1: TempBatchHistoryAgent = {
      agentId: "M1030301",
      agentName: "Stone, Todd A"
    };
    let agent2: TempBatchHistoryAgent = {
      agentId: "M1030665",
      agentName: "Hessenauer, James"
    };
    let agent3: TempBatchHistoryAgent = {
      agentId: "AML11135",
      agentName: "LASTRAPES, MARK STEPHEN"
    };
    let agent4: TempBatchHistoryAgent = {
      agentId: "LWWG",
      agentName: "GUERRIER, WILNER"
    };
    agents.push(agent1);
    agents.push(agent2);
    agents.push(agent3);
    agents.push(agent4);

    this.agents = agents;
  }

  changeAgentId(e: any) {
    this.selectedAgentId = e.target.value;

    //Temporarily acquire selected name for UserID
    let arr = e.target.options[e.target.options.selectedIndex].text.split("-");
    this.selectedAgentName = arr[1].trim();

    this.getbatchHistory();
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  }

  onSearchChanged() {
    this.gridApi.setQuickFilter(
      (document.getElementById('search') as HTMLInputElement).value
    );
  }
}
export interface TempBatchHistoryAgent {
  agentId: string,
  agentName: string
}
