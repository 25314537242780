<div class="container w-100 vh-100">
  <div class="mb-3">
    <ng-container *ngIf="!isLoggedIn">
      <div class="row mt-3">
        <div class="navbar-brand col-md-12 text-center">
          <img src="../../../../assets/images/LBIG Logo For Life 725x150.png" width="300" />
        </div>
      </div>
      <div class="row mt-3 mb-3">
        <div class="col-md-12 text-md-left text-danger">
          <p>
            <b>If this is your first time logging in to this portal,</b> you will need to create an account first by using
            the “First-Time Sign Up” button.  Once created and logged in, you will be prompted to perform a one-time link using one of your LBIG Agent IDs so
            that you can have access to all of your Agent IDs and data.  Please make sure to use your full Agent ID for successful linking.
          </p>
        </div>
      </div>
      <div class="row mt-3 mb-3">
        <div class="col-md-12 text-center">
          <div class="nav-container">
            <nav class="navbar navbar-expand annuityloginmenu">
              <div class="container-fluid justify-content-center">
                <ul class="nav navbar-nav" id="annuitymenu">
                  <li class="nav-item mr-3">
                    <a class="nav-link annuityloginlink" id="login-link" data-toggle="tab" href="#" (click)="login()">
                      <i class="fas fa-sign-in-alt"></i>&nbsp;&nbsp;Agent Login
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link annuityloginlink" id="signup-link" data-toggle="tab" href="#" (click)="signup()">
                      <i class="fas fa-user-edit"></i>&nbsp;&nbsp;First-Time Sign Up
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </ng-container>
    <app-annuitybankrates></app-annuitybankrates>
    <div class="row">
      <div class="col-md-10 mx-auto text-center" style="padding-top:10px">
        <p>This site is intended for sole use by bank representatives of Liberty Bankers Insurance Group and any of its affiliate companies. By entering the site, you agree to abide by its <a routerLink="/terms" target="new">Terms of Use.</a></p>
      </div>
    </div>
  </div>
</div>

