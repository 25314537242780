  <ag-grid-angular style="width: 100%; height: 300px;"
                   class="ag-theme-alpine"
                   [rowData]="linkedAgents$ | async"
                   [columnDefs]="columnDefs"
                   [defaultColDef]="defaultColumnDef"
                   rowSelection='single'
                   [suppressRowClickSelection]="false"
                   (gridReady)="onGridReady($event)"
                   (rowSelected)="onRowSelected($event)" >
  </ag-grid-angular>

