<div class="card card-secondary h-100">
  <div class="card-header">
    <h4 class="card-title w-100">
      <span>Agent Detail</span>
    </h4>
  </div>
  <div class="card-body">
    <div class="min-vh-75">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>Agent</label>
            <input class="form-control" disabled value="{{agentDetail.agentID}}">
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Name</label>
            <input class="form-control" disabled value="{{agentDetail.name}}">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label>Address</label>
            <input class="form-control" disabled value="{{agentDetail.address}}">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <label>City</label>
            <input class="form-control" disabled value="{{agentDetail.city}}">
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label>State</label>
            <input class="form-control" disabled value="{{agentDetail.state}}">
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label>Zip</label>
            <input class="form-control" disabled value="{{agentDetail.zipcode}}">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>Phone</label>
            <input class="form-control" disabled value="{{agentDetail.phone}}">
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Fax</label>
            <input class="form-control" disabled value="{{agentDetail.fax}}">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>Email Address</label>
            <input class="form-control" disabled value="{{agentDetail.email}}">
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Commission Level</label>
            <input class="form-control" disabled value="{{agentDetail.commissionLevel}}">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>Status</label>
            <input class="form-control" disabled value="{{agentDetail.status}}">
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Licenses</label>
            <input class="form-control" disabled value="{{agentDetail.licenses}}">
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>Advance Type</label>
            <input class="form-control" disabled value="{{agentDetail.advanceType}}">
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Advance Months</label>
            <input class="form-control" disabled value="{{agentDetail.advanceMonths}}">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>Contract Code</label>
            <input class="form-control" disabled value="{{agentDetail.contractCode}}">
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Reserve %</label>
            <input class="form-control" disabled value="{{agentDetail.reservePct}}">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>Pay Method</label>
            <input class="form-control" disabled value="{{agentDetail.payMethod}}">
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Bank Account</label>
            <input class="form-control" disabled value="{{agentDetail.bankAcct}}">
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

