import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { Observable, of, Subject } from 'rxjs';
import { map, mergeMap, switchMap } from 'rxjs/operators';
import { Profile } from '../models/profile';

@Injectable({
  providedIn: 'root'
})
export class SiteParameterService {  
  private _profile!: Profile;
  private endpoint = './api/clouddata/';
  public profileLoadComplete = false;
  public setProfile(profile: Profile) { this._profile = profile; this.profileLoadComplete = true; this.sendParmServiceInit(this._profile); }
  public getStaticProfile(): Profile { return this._profile };
  private gridNoResults = '<div class="row"><div class="col-md-12"><div class="info-box"><span class="bg-light info-box-icon"><i class="fad fa-search" aria-hidden="true"></i></span><div class="info-box-content"><div class="info-box-text"><big>No search results</big></div><div class="row justify-content-end"><small><i></i>&nbsp;</small></div></div></div></div></div>';
  public getGridNoResults(): string { return this.gridNoResults };

  constructor(public auth: AuthService, private http: HttpClient) {}
  public loadProfile() {
    return this.auth.user$.pipe(mergeMap((auth: any) => this.http.get<Profile>(this.endpoint + 'GetUserProfile?auth0ID=' + auth.sub).pipe(map((profile: Profile) => { this.setProfile(profile) }))
      .pipe(switchMap(l => { return of(true) }))));
  }
  private parmServiceInit = new Subject<any>(); //need to create a subject
  sendParmServiceInit(message: any) { //the component that wants to update something, calls this fn
    this.parmServiceInit.next({ value: message }); //next() will feed the value in Subject
  }
  getParmServiceInit(): Observable<any> { //the receiver component calls this function
    return this.parmServiceInit.asObservable(); //it returns as an observable to which the receiver function will subscribe
  }

  private impersonation = new Subject<any>();
  sendImpersonationUpdate(message: any) { //the component that wants to update something, calls this fn
    this.impersonation.next({ value: message }); //next() will feed the value in Subject
  }
  getImpersonationUpdate(): Observable<Profile> { //the receiver component calls this function
    return this.impersonation.asObservable(); //it returns as an observable to which the receiver function will subscribe
  }

  private channelUpdate = new Subject<any>(); //need to create a subject
  sendChannelUpdate(message: any) { //the component that wants to update something, calls this fn
    this.channelUpdate.next({ value: message }); //next() will feed the value in Subject
  }
  getChannelUpdate(): Observable<any> { //the receiver component calls this function 
    return this.channelUpdate.asObservable(); //it returns as an observable to which the receiver function will subscribe
  }

  private channelInit = new Subject<any>(); //need to create a subject
  sendChannelInit(message: any) { //the component that wants to update something, calls this fn
    this.channelInit.next({ value: message }); //next() will feed the value in Subject
  }
  getChannelInit(): Observable<any> { //the receiver component calls this function 
    return this.channelInit.asObservable(); //it returns as an observable to which the receiver function will subscribe
  }

  private agentUpdate = new Subject<any>(); //need to create a subject
  sendAgentUpdate(message: any) { //the component that wants to update something, calls this fn
    this.agentUpdate.next({ value: message }); //next() will feed the value in Subject
  }
  getAgentUpdate(): Observable<any> { //the receiver component calls this function
    return this.agentUpdate.asObservable(); //it returns as an observable to which the receiver funtion will subscribe
  }

  private defaultAgentUpdate = new Subject<any>(); //need to create a subject
  sendDefaultAgentUpdate(message: any) { //the component that wants to update something, calls this fn
    this.defaultAgentUpdate.next({ value: message }); //next() will feed the value in Subject
  }
  getDefaultAgentUpdate(): Observable<any> { //the receiver component calls this function
    return this.defaultAgentUpdate.asObservable(); //it returns as an observable to which the receiver funtion will subscribe
  }

  private defaultChannelUpdate = new Subject<any>(); //need to create a subject
  sendDefaultChannelUpdate(message: any) { //the component that wants to update something, calls this fn
    this.defaultChannelUpdate.next({ value: message }); //next() will feed the value in Subject
  }
  getDefaultChannelUpdate(): Observable<any> { //the receiver component calls this function
    return this.defaultChannelUpdate.asObservable(); //it returns as an observable to which the receiver funtion will subscribe
  }

  private agentDetailUpdate = new Subject<any>(); //need to create a subject
  sendAgentDetailUpdate(message: any) { //the component that wants to update something, calls this fn
    this.defaultChannelUpdate.next({ value: message }); //next() will feed the value in Subject
  }
  getAgentDetailUpdate(): Observable<any> { //the receiver component calls this function
    return this.defaultChannelUpdate.asObservable(); //it returns as an observable to which the receiver funtion will subscribe
  }

  private kpiSearchUpdate = new Subject<any>(); //need to create a subject
  sendkpiSearchUpdate(message: any) { //the component that wants to update something, calls this fn
    this.kpiSearchUpdate.next({ value: message }); //next() will feed the value in Subject
  }
  getkpiSearchUpdate(): Observable<any> { //the receiver component calls this function
    return this.kpiSearchUpdate.asObservable(); //it returns as an observable to which the receiver funtion will subscribe
  }

  private unlinkedAgentUpdate = new Subject<any>(); //need to create a subject
  sendunlinkedAgentUpdate(message: any) { //the component that wants to update something, calls this fn
    this.unlinkedAgentUpdate.next({ value: message }); //next() will feed the value in Subject
  }
  getunlinkedAgentUpdate(): Observable<any> { //the receiver component calls this function
    return this.unlinkedAgentUpdate.asObservable(); //it returns as an observable to which the receiver funtion will subscribe
  }
}
