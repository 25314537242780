import { Component, OnInit } from '@angular/core';
import { SiteParameterService } from '../../../services/siteparameter.service';
import { CloudStorageService } from '../../../services/cloud-storage';
import { Profile } from '../../../models/profile';
import { ToastrService } from 'ngx-toastr';
import { OnPremStorageService } from '../../../services/onprem-storage';
import { AgentLinkResponse } from '../../../models/agentLinkResponse';
import { AgentLink } from '../../../models/agentLink';
import { AuthService } from '@auth0/auth0-angular';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit {
  profile!: Profile;
  linkedAgentResponse!: AgentLinkResponse;
  linkedAgents!: AgentLink[];
  public showLinkMessage = false;
  public showFirstTimeMessage = false;
  
  constructor(private onPremService: OnPremStorageService, private siteParmService: SiteParameterService, public cloudStorageService: CloudStorageService, private toastr: ToastrService, private router: Router) { }

  ngOnInit(): void {
    
    if (!this.siteParmService.profileLoadComplete) {
      this.siteParmService.loadProfile()
        .pipe(take(1))
        .subscribe(f => {
        this.profile = this.siteParmService.getStaticProfile();
        if (this.profile.agents.length < 1) {
          this.showLinkMessage = true
        }
        else {
          this.profile = this.siteParmService.getStaticProfile();
          if (this.profile.updateRequired)
          {
            this.onPremService.getAgentList().subscribe(agents => {
                this.linkedAgents = agents;
              this.linkedAgentResponse = {
                auth0Id: this.profile.auth0Id, linkIdentities: this.linkedAgents, responseCode: 0, emailAddress: '', eAppUser: [{clientID: '', clientSecret: '', name: '', username: ''}] };
              this.cloudStorageService.updateLinkedAgents(this.linkedAgentResponse)
                .pipe(take(1))
                .subscribe(p => {
                  if (p.agents.length < 1) {
                    this.showLinkMessage = true;
                    //this.siteParmService.setProfile(<Profile>{}); //clear out cached profile
                    this.siteParmService.sendunlinkedAgentUpdate('all linked agents have been removed');
                    this.router.navigate(['']);
                  } else {
                    this.siteParmService.setProfile(p);
                  }                  
                });
              });
          }
          if (this.profile && !this.profile.homeVisited) {
            this.toastr.info('The top navigation bar provides access to different business channels, system status and your profile. Your Profile is used to set the default business channel. ', 'Information', {
              positionClass: 'toast-nav-info', tapToDismiss: true, timeOut: 0
            });
          }          
          this.cloudStorageService.updatePageVisited(this.profile.auth0Id, 1)
            .pipe(take(1))
            .subscribe((resp: boolean) => { });
        }
      });
    } else {
      var agents = this.siteParmService.getStaticProfile().agents;
      if (agents.length === 0) { this.showLinkMessage = true }
    }
  }
}
