import { Component, OnInit } from '@angular/core';
import { SiteParameterService } from '../../../services/siteparameter.service'
import { Subscription } from 'rxjs';
import { Profile } from '../../../models/profile';
import { Agent } from '../../../models/agent';

@Component({
  selector: 'app-agentpicker',
  templateUrl: './agentpicker.component.html',
  styleUrls: ['./agentpicker.component.css']
})
export class AgentPickerComponent implements OnInit {
  public agents!: Array<Agent>;
  public profile!: Profile;
  public selectedAgentId = "";
  private subscriptionName: Subscription;
   constructor(private siteParmService: SiteParameterService) {
    this.subscriptionName = this.siteParmService.getChannelUpdate().subscribe
      (message => {
        this.profile = siteParmService.getStaticProfile();
        this.selectedAgentId = this.profile.selectedAgentNumber;
        this.profile.selectedAgentNumber = this.selectedAgentId;
        this.agents = this.profile.agents.filter(x => x.channel == this.profile.channelPreferenceValue);
        this.siteParmService.sendAgentUpdate(this.selectedAgentId); //send update to subscribers for default first agent        
      });
  }

  ngOnInit(): void {    
    this.profile = this.siteParmService.getStaticProfile();
    if (!this.profile.selectedAgentNumber) {
      this.selectedAgentId = this.profile.defaultAgentNumber;      
    } else {
      this.selectedAgentId = this.profile.selectedAgentNumber;
    }    
    this.agents = this.profile.agents.filter(x => x.channel === this.profile.channelPreferenceValue);
  }
  ngOnDestroy() { // It's a good practice to unsubscribe to ensure no memory leaks
    this.subscriptionName.unsubscribe();
  }
  public changeAgent(selectedAgent: string, selectedChannel: number): void {
    this.selectedAgentId = selectedAgent;
    this.profile.selectedAgentNumber = selectedAgent;
    this.siteParmService.setProfile(this.profile);
    this.siteParmService.sendAgentUpdate(selectedAgent);
  }
}
