<div class="row" *ngIf="showHeaderText">
  <div class="col-md-12">
    <div class="formheadertext text-left">
      <p innerHTML={{headerText}}></p>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <div class="card card-secondary forms-box">
      <div class="card-header">
        <h4 class="card-title w-100">
          <span>Search</span>
        </h4>
      </div>
      <div class="card-body">
        <div class="row justify-content-center">
          <div class="col-sm-2">
            <div class="form-group">
              <label>Form Name</label>
              <input type="text" id="description" class="form-control" [(ngModel)]="description" />
            </div>
          </div>
          <div class="col-sm-2">
            <div class="form-group">
              <label>State</label>
              <select id="state" class="form-control" [(ngModel)]="state">
                <option value="0">Any</option>
                <option *ngFor="let select of parms.formStates" value='{{select.valueAsText}}'>{{select.text}}</option>
              </select>
            </div>
          </div>
        <!--</div>
        <div class="row">-->
          <div class="col-md-2">
            <div class="form-group">
              <label>Form Class</label>
              <select id="class" class="form-control" [(ngModel)]="formClass">
                <option value="0">Any</option>
                <option *ngFor="let select of parms.formClasses" value='{{select.value}}'>{{select.text}}</option>
              </select>
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <label>Form Type</label>
              <select id="type" class="form-control" [(ngModel)]="type">
                <option value="0">Any</option>
                <option *ngFor="let select of parms.formTypes" value='{{select.value}}'>{{select.text}}</option>
              </select>
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <label>Product</label>
              <select id="product" class="form-control" [(ngModel)]="product">
                <option value="0">Any</option>
                <option *ngFor="let select of parms.formProducts" value='{{select.value}}'>{{select.text}}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="text-center">
          <input type="button" class="btn btn-secondary input-block-level" value="Search" (click)="searchForms()">
          &nbsp;<input type="button" class="btn btn-secondary input-block-level" value="Reset" (click)="resetSearch()">
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <div class="card card-secondary commissions-box" *ngIf="showSearch">
      <div class="card-header">
        <h4 [ngSwitch]="(forms$ | async)?.length" class="card-title w-100">
          <span *ngSwitchCase="1">Search Results (1 Form)</span>
          <span *ngSwitchCase="0">Search Results (0 Forms)</span>
          <span *ngSwitchDefault>Search Results ({{(forms$ | async)?.length}} Forms)</span>
        </h4>
      </div>
      <div class="card-body">
        <div class="row justify-content-center" *ngIf="showSpinner">
          <component-loading></component-loading>
        </div>
        <div *ngIf="forms$ | async; else loading;">
          <ag-grid-angular style="width: 100%; height: 350px;"
                           class="ag-theme-alpine"
                           [rowData]="forms$ | async"
                           [columnDefs]="columnDefs"
                           [defaultColDef]="defaultColumnDef"
                           rowSelection="multiple"
                           (rowSelected)="onRowSelected($event)"
                           [suppressRowClickSelection]="true"
                           (gridReady)="onGridReady($event)">
          </ag-grid-angular>
        </div>
      </div>
      <ng-template #loading>
        <div class="row justify-content-center">
          <component-loading></component-loading>
        </div>
      </ng-template>
      <div class="card-footer">
        <div class="text-center text-md">
          <input type="button" class="btn btn-secondary input-block-level" value="Download Forms" (click)="downloadForms()" [disabled]="individualDisabled">&nbsp;&nbsp;
          <input type="button" class="btn btn-secondary input-block-level" value="Send by Email" (click)="emailForms()" [disabled]="individualDisabled || sendEmailDisabled">&nbsp;&nbsp;&nbsp;
          <input type="checkbox" style="transform: scale(1.5);" [(ngModel)]="combineForms" [disabled]="combineDisabled" />&nbsp;&nbsp;<label>Combine into single PDF</label>
        </div>
      </div>
    </div>
  </div>
</div>

<script type="text/javascript">
  function showWait() {
    alert('clicked');
    document.body.style.cursor = 'wait';
  }
</script>
