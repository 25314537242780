<div class="container w-100">
  <div class="bg-landing">
    <div class="row mobile-heading">      
    </div>
    <div class="row" style="padding-top:20px;">
      <div class="col-md-4">
        <h1 class="text-primary landing-headline text-center"><b>Agent/Producer Portal</b></h1>
      </div>
    </div>
    <div class="row" style="padding-top:40px;padding-bottom:40px">
      <div class="col-sm-4">
        <button class="btn btn-primary btn-block" (click)="login()">Agent Login</button>
        <div style="padding-top:20px;">
          <button class="btn btn-primary btn-block" (click)="signup()" [disabled]="disableSignUp">Sign Up</button>
        </div>
        <div class="col-10 mx-auto text-center text-danger" *ngIf="disableSignUp">
          <h4>New Sign Ups are not available at this time.</h4>
        </div>
      </div>
      <div class="overlay">
        <div class="col-md-6">
          <p>
            <b>If this is your first time logging in to this portal,</b> you will need to create an account first by using
              the “Sign Up” button.  Once created and logged in, you will be prompted to perform a one-time link using one of your LBIG Agent IDs so
              that you can have access to all of your Agent IDs and data.  Please make sure to use your full Agent ID for successful linking.
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-10 mx-auto text-center">
      <p>
        <b>
          If you are interested in becoming a contracted agent, we’d love to connect with
          you and discuss how you can be part of providing insurance and financial protection to your clients … For Life. Please contact us at 800-731-4300 for full details.
        </b>
      </p>
    </div>
  </div>
  <div class="row">
    <div class="col-md-10 mx-auto text-center" style="padding-top:10px">
      <p>This site is intended for sole use by contracted agent producers of Liberty Bankers Insurance Group and any of its affiliate companies. By entering the site, you agree to abide by its <a routerLink="/terms" target="new">Terms of Use.</a></p>
    </div>
  </div>
</div>
