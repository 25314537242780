import { Component, Inject, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
})
export class LoginPageComponent implements OnInit {  
  public loginRedirect = false;
  public disableSignUp = false;
  constructor(public auth: AuthService) { }
  
  ngOnInit(): void {
  }
  login(): void {
    this.auth.loginWithRedirect({ appState: { target: '/home' }, registration_code: '123456789' });
    
  }
  signup(): void {
    this.auth.loginWithRedirect({ appState: { target: '/home' }, screen_hint: 'signup' });

  }
}
