import { HttpClient, HttpBackend, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment as env } from '../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  private http: HttpClient;

  constructor(backend: HttpBackend) {
    this.http = new HttpClient(backend);
  }

  public upload(fileTargetId: number, channelId: number, emailAddress: string, auth0Id: string, agentId: string, policyNumber: string,
    clientName: string, fileName: string, file: File): Observable<any> {
    const formData = new FormData();
    formData.append("fileTargetId", fileTargetId.toString());
    formData.append("channelId", channelId.toString());
    formData.append("emailAddress", emailAddress);
    formData.append("authUserId", auth0Id);
    formData.append("agentID", agentId);
    formData.append("policyNumber", policyNumber);
    formData.append("clientName", clientName);
    formData.append("fileName", fileName);
    formData.append('file', file, file.name);

    return this.http.post<HttpEvent<any>>(`${env.dev.onPremServerUrl + 'file/upload'}`, formData, {
      reportProgress: true,
      observe: 'events',
    })
  }
}
