import { Component, OnInit } from '@angular/core';
import { PolicyRoute } from '../../../models/policyRoute';
import { ColDef, GridApi, ValueFormatterParams } from 'ag-grid-enterprise';
import { OnPremStorageService } from '../../../services/onprem-storage';
import { SiteParameterService } from '../../../services/siteparameter.service';
import { Observable, Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { CloudStorageService } from '../../../services/cloud-storage';
import { Profile } from '../../../models/profile';
import { AgentGrid } from '../../../models/agentGrid';
import { GetContextMenuItemsParams, GridOptions, MenuItemDef, RowSelectedEvent, SelectionChangedEvent } from 'ag-grid-community';
import '../../../../assets/css/routelist.css';
import { trim } from 'jquery';

@Component({
  selector: 'app-routelist',
  templateUrl: './routelist.component.html'
})
export class RouteListComponent implements OnInit {
  public showCollectionParameters: boolean = true;
  public showCollectionList: boolean = true;
  public showCollectionForm: boolean = false;
  public showGroupCollectionForm: boolean = false;
  public showSequenceNumberForm: boolean = false;
  public showSequenceContactForm: boolean = false;
  public showLapseAlert: boolean = false;
  public showNoPremiumCollected: boolean = false;
  public showNoGroupPremiumCollected: boolean = false;
  //public enableEditRouteButton: boolean = false;
  //public enableCollectAllButton: boolean = false;
  //public enableDeleteAllButton: boolean = false;
  public routeList$?: Observable<PolicyRoute[]>

  public policyNumber: string = "";
  public sequenceNo: string = "";
  public routeSequenceId: string = "";
  public modalPremium: number = 0.00;
  public premiumDue: number = 0.00;
  public monthsPaid: number = 0;
  public minimumMonths: number = 0;
  public groupModalPremium: number = 0.00;
  public groupPremiumDue: number = 0.00;
  public premiumGroupCollected: number = 0.00;
  public monthsGroupPaid: number = 0;
  public currentGroupCommRate: number = 0.00;
  public estimatedGroupCommission: number = 0.00;

  private collectionId!: string;
  private regionId: number = 0;
  private collectionAgentId: string = "";
  private cashAmount: number = 0.00;
  private suspenseAmount: number = 0.00;
  private insuredName: string = "";
  private payorName: string = "";
  public premiumCollected: number = 0.00;
  public currentCommRate: number = 0.00;
  public estimatedCommission: number = 0.00;

  public contactName: string = "";
  public contactAddressLine1: string = "";
  public contactAddressLine2: string = "";
  public contactCity: string = "";
  public contactState: string = "";
  public contactZip: string = "";
  public contactEmailAddress: string = "";
  public contactPhone: string = "";
  public contactNotes: string = "";


  private gridApi!: GridApi;
  public noRowsTemplate: string = "";
  public groupDefaultExpanded = 1;
  private gridColumnApi: any;
  public profile: Profile = <Profile>{};
  //private impersonationSubscription: Subscription;
  //public agents: AgentGrid[] = [];
  public agents: TempAgent[] = [];
  public selectedAgentId: string = "";
  public selectedAgentName: string = "";

  public testText$?: Observable<number>;

  public columnDefs = [
    { field: 'sequenceNo', headerName: 'Route Seq #', rowGroup: true, hide: true },
    { field: 'insuredName', headerName: 'Insured' },
    { field: 'payorName', headerName: 'Payor' },
    { field: 'policyNum', headerName: 'Policy #', minWidth: 100, maxWidth: 100 },
    { field: 'modalPremium', headerName: 'Modal Prem', valueFormatter: currencyFormatter, minWidth: 130, maxWidth: 130 },
    { field: 'monthsDue', headerName: 'Months Due', minWidth: 130, maxWidth: 130 },
    { field: 'premiumDue', headerName: 'Prem Due', minWidth: 125, maxWidth: 125, valueFormatter: currencyFormatter },
    { field: 'paidToDate', headerName: 'Paid-To Date', minWidth: 150, maxWidth: 150 },
    { field: 'collectionAmt', headerName: 'Prem Collected', minWidth: 150, maxWidth: 150, valueFormatter: currencyFormatter }
  ];
  public defaultColumnDef = {
    sortable: true,
    resizable: true,
  };
  public autoGroupColumnDef: ColDef = {
    headerName: 'Route Seq #',
    cellRendererParams: {
      suppressCount: true,
      //checkbox: true,
      //checkboxSelection: true,
    },
    minWidth: 200, maxWidth: 200,
    //cellStyle: { color: 'red', backgroundColor: 'green' }
  };

  public gridOptions: GridOptions = {
    context: this
  }

  constructor(public onPremService: OnPremStorageService, private siteParmService: SiteParameterService, public cloudStorageService: CloudStorageService,) {
    this.noRowsTemplate = this.siteParmService.getGridNoResults();
    //this.agentUpdate = this.siteParmService.getAgentUpdate().subscribe
    //  (message => {
    //    this.selectedAgentID = message.value;
    //    this.getdownlineList();
    //  });
    let agent: TempAgent = {
      agentId: "M1030301",
      agentName: "Stone, Todd A"
    };
    this.selectedAgentId = agent.agentId;
    this.selectedAgentName = agent.agentName;
    this.getRouteList();
    this.getTotalCollectedAmount();
  }

  ngOnInit(): void {
    this.profile = this.siteParmService.getStaticProfile();
    this.getAgentList();
  }

  getRouteList() {
    this.routeList$ = this.onPremService.getRouteList(this.selectedAgentId,'').pipe(map(response => response.data));



  }

  getTotalCollectedAmount() {
    this.testText$ = this.routeList$?.pipe(
      map(arr => arr.reduce((a, b) => a + b.collectionAmt, 0)));
  }

  getAgentList(): void {
    //this.cloudStorageService.getLinkedAgents(this.profile.auth0Id)
    //  .subscribe(agents => {
    //    this.agents = agents;
    //  });
    let agents: TempAgent[] = [];

    let agent1: TempAgent = {
      agentId: "M1030301",
      agentName: "Stone, Todd A"
    };
    let agent2: TempAgent = {
      agentId: "M1030665",
      agentName: "Hessenauer, James"
    };
    let agent3: TempAgent = {
      agentId: "AML11135",
      agentName: "LASTRAPES, MARK STEPHEN"
    };
    let agent4: TempAgent = {
      agentId: "LWWG",
      agentName: "GUERRIER, WILNER"
    };
    agents.push(agent1);
    agents.push(agent2);
    agents.push(agent3);
    agents.push(agent4);

    this.agents = agents;
  }

  changeAgentId(e: any) {
    this.selectedAgentId = e.target.value;

    //Temporarily acquire selected name for UserID
    let arr = e.target.options[e.target.options.selectedIndex].text.split("-");
    this.selectedAgentName = arr[1].trim();

    this.getRouteList();
  }

  clearForm() {
    this.policyNumber = "";
    this.sequenceNo = "";
    this.routeSequenceId = "";
    this.modalPremium = 0.00;
    this.premiumDue = 0.00;
    this.monthsPaid = 0;
    this.minimumMonths = 0;
    this.collectionId = "";
    this.regionId = 0;
    this.collectionAgentId = "";
    this.cashAmount = 0.00;
    this.suspenseAmount = 0.00;
    this.insuredName = "";
    this.payorName = "";

    this.groupModalPremium = 0.00;
    this.groupPremiumDue = 0.00;
    this.premiumGroupCollected = 0.00;
    this.monthsGroupPaid = 0;
  }

  onRowClicked(event: RowSelectedEvent) {
    if (event.node.isSelected()) {
      if (!event.node.key) {
        this.getCollection(event.data);
        this.openCollectionForm();
      }
    }
  }

  openCollectionForm() {
    this.showCollectionParameters = false;
    this.showCollectionList = false;
    this.showCollectionForm = true;
  }

  closeCollectionForm() {
    this.clearForm();
    this.showCollectionParameters = true;
    this.showCollectionList = true;
    this.showCollectionForm = false;
    this.showNoPremiumCollected = false;
  }

  openGroupCollectionForm() {
    this.showCollectionParameters = false;
    this.showCollectionList = false;
    this.showGroupCollectionForm = true;
  }

  closeGroupCollectionForm() {
    this.clearForm();
    this.showCollectionParameters = true;
    this.showCollectionList = true;
    this.showGroupCollectionForm = false;
    this.showNoGroupPremiumCollected = false;
  }

  getCollection(data: any): void {
    if (data.collectionID === null) {
      this.policyNumber = data.policyNum;
      this.sequenceNo = data.sequenceNo;
      this.routeSequenceId = data.routeSequenceID
      this.modalPremium = data.modalPremium;
      this.premiumDue = data.premiumDue;
      this.monthsPaid = data.monthsDue;
      this.showLapseAlert = data.monthsDue > 3 ;
      this.minimumMonths = data.monthsDue > 3 ? this.monthsPaid : 0;
      this.premiumCollected = data.premiumDue;
      this.currentCommRate = data.currentCommRate;
      this.estimatedCommission = this.premiumCollected * this.currentCommRate;

      this.regionId = data.regionID;
      this.collectionAgentId = this.selectedAgentName;
      this.cashAmount = this.premiumDue;
      this.insuredName = data.insuredName;
      this.payorName = data.payorName;
    } else {
      this.onPremService.getCollection(data.collectionID)
        .pipe(take(1))
        .subscribe(response => {
          this.collectionId = response.data[0].collectionID;
          this.policyNumber = response.data[0].policyNum;
          this.sequenceNo = response.data[0].sequenceNo;
          this.modalPremium = response.data[0].modalPremium;
          this.premiumDue = response.data[0].premiumDue;
          this.monthsPaid = response.data[0].monthsPaid;
          this.showLapseAlert = response.data[0].monthsDue > 3;
          this.minimumMonths = response.data[0].monthsDue > 3 ? response.data[0].monthsDue : 0;
          this.premiumCollected = response.data[0].cashAmt + response.data[0].suspenseAmt;
          this.currentCommRate = response.data[0].currentCommRate;
          this.estimatedCommission = response.data[0].premiumDue * response.data[0].currentCommRate;
          this.regionId = response.data[0].regionID;
          this.collectionAgentId = response.data[0].collectionAgentID;
          this.cashAmount = response.data[0].cashAmt;
          this.suspenseAmount = response.data[0].suspenseAmt;
          this.insuredName = response.data[0].insuredName;
          this.payorName = response.data[0].payorName;
        });
    }
  }

  addCollection(formData: any): void {
    if (this.premiumCollected > 0) {
      //this.onPremService.saveCollection(this.collectionId, this.regionId, this.policyNumber, this.selectedAgentId, this.collectionAgentId,
      //  this.monthsPaid, this.cashAmount, this.suspenseAmount, this.selectedAgentName, this.modalPremium, this.currentCommRate, this.insuredName,
      //  this.payorName, this.sequenceNo)
      //  .pipe(take(1))
      //  .subscribe(response => {
      //    this.getTotalCollectedAmount();
      //  });

      this.closeCollectionForm();
    }
    else {
      this.showNoPremiumCollected = true;
    }
  }

  deleteCollection(collectionId: string): void {
    if (confirm("Are you sure you want to delete this payment?")) {
      this.onPremService.deleteCollection(collectionId)
        .pipe(take(1))
        .subscribe(response => {
          this.getTotalCollectedAmount();
        });

      this.closeCollectionForm();
    }
  }

  setGroupCollectionValues(listCollection: any) {
    var premiumSum = 0;
    var premiumDueSum = 0;
    this.collectionId = listCollection[0].data.collectionID;
    this.sequenceNo = listCollection[0].data.sequenceNo;
    this.routeSequenceId = listCollection[0].data.routeSequenceID;
    this.monthsGroupPaid = listCollection[0].data.monthsDue;
    this.showLapseAlert = listCollection[0].data.monthsDue > 3;
    this.minimumMonths = listCollection[0].data.monthsDue > 3 ? this.monthsGroupPaid : 0;
    this.currentGroupCommRate = listCollection[0].data.currentCommRate;
    this.collectionAgentId = this.selectedAgentName;

    for (var i = 0; i < listCollection.length; i++) {
      premiumSum += listCollection[i].data.modalPremium;
      premiumDueSum += listCollection[i].data.premiumDue;
    }

    this.groupModalPremium = premiumSum;
    this.groupPremiumDue = premiumDueSum;

    this.premiumGroupCollected = premiumSum * listCollection[0].data.monthsDue;
    this.estimatedGroupCommission = premiumSum * listCollection[0].data.monthsDue * listCollection[0].data.currentCommRate;
  }

  checkToDisableAddGroupCollection(listCollection: any): boolean {
    var disable = false;
    var collectedSum = 0;
    var monthsDueArray = [];

    if (listCollection[0].data.sequenceNo == 'UNSEQUENCED') {
      disable = true;
    }
    else {
      for (var i = 0; i < listCollection.length; i++) {
        collectedSum += listCollection[i].data.collectionAmt;
        monthsDueArray.push(listCollection[i].data.monthsDue)
      }

      if (collectedSum > 0 || !(monthsDueArray.every(value => { return value === listCollection[0].data.monthsDue; }))) {
        disable = true;
      }
    }
    return disable;
  }

  addGroupCollection(formData: any): void {
    if (this.premiumGroupCollected > 0) {      
      this.onPremService.saveGroupCollection(this.routeSequenceId, this.selectedAgentId, this.collectionAgentId, this.monthsGroupPaid,
        this.premiumGroupCollected, this.selectedAgentName)
        .finally(() => {
          this.closeGroupCollectionForm();
          this.getTotalCollectedAmount();
        });  
    }
    else {
      this.showNoGroupPremiumCollected = true;
    }
  }

  checkToDeleteGroupCollection(listCollection: any): boolean {
    var collectedSum = 0;

    for (var i = 0; i < listCollection.length; i++) {
      collectedSum += listCollection[i].data.collectionAmt;
    }

    return collectedSum === 0;
  }

  deleteGroupCollection(routeSequenceId: string): void {
    if (confirm("Are you sure you want to delete this group payment?")) {
      this.onPremService.deleteGroupCollection(routeSequenceId)
        .finally(() => {
          this.getTotalCollectedAmount();
          this.refreshGrid();
        });
    }
  }

  openSequenceNumberForm() {
    this.showCollectionParameters = false;
    this.showCollectionList = false;
    this.showSequenceNumberForm = true;
  }

  closeSequenceNumberForm() {
    this.showCollectionParameters = true;
    this.showCollectionList = true;
    this.showSequenceNumberForm = false;
  }

  getSequenceNumberInfo(data: any): void {
    this.regionId = data.regionID;
    this.policyNumber = data.policyNum;
    this.sequenceNo = data.sequenceNo === 'UNSEQUENCED' ? '' : data.sequenceNo;
  }

  updateSequenceNumber(formData: any): void {
    this.onPremService.saveSequence(this.regionId, this.policyNumber, this.selectedAgentId, trim(formData.sequenceNo), this.selectedAgentName)
      .pipe(take(1))
      .subscribe();

    this.closeSequenceNumberForm(); 
  }

  openSequenceContactForm() {
    this.showCollectionParameters = false;
    this.showCollectionList = false;
    this.showSequenceContactForm = true;
  }

  closeSequenceContactForm() {
    this.showCollectionParameters = true;
    this.showCollectionList = true;
    this.showSequenceContactForm = false;
  }

  getSequenceContactInfo(data: any): void {
    this.routeSequenceId = data.routeSequenceID;
    this.sequenceNo = data.sequenceNo;

    this.onPremService.getSequenceContact(this.routeSequenceId)
      .pipe(take(1))
      .subscribe(response => {
        this.contactName = response.data[0].name;
        this.contactAddressLine1 = response.data[0].addressLine1;
        this.contactAddressLine2 = response.data[0].addressLine2;
        this.contactCity = response.data[0].city;
        this.contactState = response.data[0].state;
        this.contactZip = response.data[0].zip;
        this.contactEmailAddress = response.data[0].emailAddress;
        this.contactPhone = response.data[0].phone;
        this.contactNotes = response.data[0].notes;
      });
  }

  updateSequenceContact(formData: any): void {
    this.onPremService.saveSequenceContact(this.routeSequenceId, this.sequenceNo, this.contactName, this.contactAddressLine1, this.contactAddressLine2,
    this.contactCity, this.contactState, this.contactZip, this.contactEmailAddress, this.contactPhone, this.contactNotes, this.selectedAgentName )
      .pipe(take(1))
      .subscribe();

    this.closeSequenceContactForm(); 
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  }

  getContextMenuItems(
    params: GetContextMenuItemsParams
  ): (string | MenuItemDef)[] {
    var result: (string | MenuItemDef)[] = [
      {
        name: 'Collect/Update Payment',
        action: () => {
          params.context.openCollectionForm();
          params.context.getCollection(params.node!.data);
        }
      },
      {
        name: 'Delete Payment',
        action: () => {
          params.context.deleteCollection(params.node!.data.collectionID);
          params.context.refreshGrid();
        },
        disabled: !params.node!.data.collectionID,
      },
      {
        name: 'Edit Sequence Number',
        action: () => {
          params.context.openSequenceNumberForm();
          params.context.getSequenceNumberInfo(params.node!.data);
        },
      },
      'separator',
      {
        name: 'Collect Entire Sequence',
        action: () => {
          params.context.setGroupCollectionValues(params.node!.parent!.allLeafChildren);
          params.context.openGroupCollectionForm();
        },
        disabled: params.context.checkToDisableAddGroupCollection(params.node!.parent!.allLeafChildren)
      },
      {
        name: 'Delete For Entire Sequence',
        action: () => {
          params.context.deleteGroupCollection(params.node!.data.routeSequenceID);
        },
        disabled: params.context.checkToDeleteGroupCollection(params.node!.parent!.allLeafChildren)
      },
      {
        name: 'Edit Sequence Contact',
        action: () => {
          params.context.openSequenceContactForm();
          params.context.getSequenceContactInfo(params.node!.data);
        },
        disabled: params.node!.data.sequenceNo == 'UNSEQUENCED'
      },
    ];

    return result;
  }

  refreshGrid() {
    this.getRouteList();
  }

  onSearchChanged() {
    this.gridApi.setQuickFilter(
      (document.getElementById('search') as HTMLInputElement).value
    );
  }

  //onCollectionChanged(e: any) {
  //  this.monthsPaid = e.target.value;
  //  this.cashAmount = this.modalPremium * this.monthsPaid;
  //  this.premiumCollected = this.cashAmount + this.suspenseAmount;
  //  this.estimatedCommission = this.cashAmount * this.currentCommRate;
  //}

  onMonthsPaidChanged() {
    this.cashAmount = this.modalPremium * this.monthsPaid;
    this.premiumCollected = this.cashAmount + this.suspenseAmount;
    this.estimatedCommission = this.cashAmount * this.currentCommRate;
  }

  onMonthsGroupPaidChanged() {
    this.premiumGroupCollected = this.groupModalPremium * this.monthsGroupPaid;
    this.estimatedGroupCommission = this.premiumGroupCollected * this.currentGroupCommRate;
  }

  onSuspenseAmtChanged(e: any) {
    this.suspenseAmount = +e.target.value;
    this.premiumCollected = this.cashAmount + this.suspenseAmount;
  }

  increaseMonthsPaid() {
    var value = this.monthsPaid;
    value = isNaN(value) ? 0 : value;
    value++;
    this.monthsPaid = value;
    this.onMonthsPaidChanged();
  }

  decreaseMonthsPaid() {
    var value = this.monthsPaid;
    var minValue = this.minimumMonths;
    value = isNaN(value) ? 0 : value;
    value < 1 ? value = 1 : '';
    if (minValue < value) {
      value--;
      this.monthsPaid = value;
      this.onMonthsPaidChanged();
    }
  }

  increaseGroupMonthsPaid() {
    var value = this.monthsGroupPaid;
    value = isNaN(value) ? 0 : value;
    value++;
    this.monthsGroupPaid = value;
    this.onMonthsGroupPaidChanged();
  }

  decreaseGroupMonthsPaid() {
    var value = this.monthsGroupPaid;
    var minValue = this.minimumMonths;
    value = isNaN(value) ? 0 : value;
    value < 1 ? value = 1 : '';
    if (minValue < value) {
      value--;
      this.monthsGroupPaid = value;
      this.onMonthsGroupPaidChanged();
    }
  }

  //ngOnDestroy() { // It's a good practice to unsubscribe to ensure no memory leaks
  //  this.impersonationSubscription.unsubscribe()
  //}
}

function currencyFormatter(params: ValueFormatterParams) {
  var currencyValue = isNaN(params.value) ? "" : formatNumber(params.value);
  return currencyValue;
}

function formatNumber(number: number) {
  return number.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD'
  });
}

export interface TempAgent {
  agentId: string,
  agentName: string
}

function wait(ms: number) {
  var start = new Date().getTime();
  var end = start;
  while (end < start + ms) {
    end = new Date().getTime();
  }
}
