import { Component, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SiteParameterService } from '../../../services/siteparameter.service'
import { Profile } from '../../../models/profile';
import { profileOptions } from '../../../models/profileOptions';
import { AgentLinkResponse } from '../../../models/agentLinkResponse';
import { OnPremStorageService } from '../../../services/onprem-storage';
import { CloudStorageService } from '../../../services/cloud-storage';
import { APIResponse } from '../../../models/apiresponse';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-profile',
  templateUrl: './completeprofile.component.html',
})
export class CompleteProfileComponent implements OnInit {
  private errorCode!: number;
  private emailAddress = "";
  private auth0ID = "";
  private avatarLink = "";
  private updateSuccess = false;
  private updateFailed = false;
  private showForm = true;
  private showGrid = false;
  private showLinkCode = false;

  public profile!: Profile;
  public profileOptions!: profileOptions;
  public profileJson = "";
  constructor(public auth: AuthService, public router: Router, private onPremService: OnPremStorageService, public cloudStorageService: CloudStorageService, private siteParmService: SiteParameterService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.auth.user$.subscribe(
      (profile) => (this.profileJson = JSON.stringify(profile, null, 2),
        this.emailAddress = profile.email,
        this.auth0ID = profile.sub,
        this.avatarLink = profile.picture)
    );
  }
  btnClick(): void {
    this.router.navigate(['/home']);
  };
  btnLinkCode(): void {
    this.onPremService.addAgentLinkCodeRecord(this.emailAddress)
      .pipe(take(1))
      .subscribe();

    this.showLinkCode = true;
  }
  inputFocused(event: any) {
    event.target.select()
  }
  disableArrowKeys(event: any) {
    var key = event.keyCode;
    if (key >= 37 && key <= 40) {
      return false;
    }
    else
      return true;
  }
  setFocus(event: any, input: number): void {
    var key = event.keyCode;
    if ((key >= 48 && key <= 57) || (key >= 65 && key <= 90) || (key >= 96 && key <= 105)) {
      var next = input + 1;
      var elementReference = document.getElementById('linkCode' + next);
      if (elementReference instanceof HTMLElement) {
        elementReference.focus();
        elementReference.scrollIntoView();
      }
    }
  }
  onSubmit(formData: any): void {
    var linkCode = formData.linkCode1 + formData.linkCode2 + formData.linkCode3 + formData.linkCode4 + formData.linkCode5 + formData.linkCode6;
    this.onPremService.linkAgent(this.emailAddress, formData.agentId, formData.ssn, formData.dob, linkCode)
      .pipe(take(1))
      .subscribe((resp: APIResponse) => {
        if (resp.data.responseCode > 0) {
          resp.data.emailAddress = this.emailAddress;
          resp.data.avatarLink = this.avatarLink;
          resp.data.auth0Id = this.auth0ID;
          this.cloudStorageService.createUserProfile(resp.data)
            .pipe(take(1))
            .subscribe((resp: Profile) => {
              if (resp.userId !== '') {
                this.updateSuccess = true;
                this.siteParmService.setProfile(resp); //save to local storage
                this.siteParmService.sendChannelInit(resp.channelPreferenceValue); //alert navbar etc of registered agent
                this.showForm = false;
                this.showGrid = true;
                this.toastr.success('Your profile has been created and all of you agent numbers are now linked to your account', 'Profile Created', {
                  positionClass: 'toast-product-info', tapToDismiss: true, timeOut: 0
                });
              } else {
                this.toastr.warning('We could not locate your account using the information provided. Please verify and try again.', '', {
                  positionClass: 'toast-product-info', tapToDismiss: true, timeOut: 0
                });
              }
            });
        } else {
          this.errorCode = resp.data.responseCode * -1;
          this.toastr.error('We could not locate your account using the information provided. Please verify and try again.<br/><br/><b>Error Code: ' + this.errorCode + '</b>', '', {
            positionClass: 'toast-product-info', tapToDismiss: true, enableHtml: true, timeOut: 0
          });
        }
      });
  }
}
