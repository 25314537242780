<div class="row">
  <div class="col-md-6">
    <div class="accordion" id="leftaccordion">
      <div class="card card-secondary" *ngFor="let group of groupsLeft; let i = index">
        <div class="card-header" id="linkHeadingL{{i}}">
          <h4 class="card-title w-100" (click)="toggleAccordion('L',i)">
            <a class="d-block w-100 collapsed" data-toggle="collapse" href="#linkCollapseL{{i}}" aria-expanded="false">
              {{group.description}}&nbsp;&nbsp;<span><i class="{{getAccordionArrowStyle('L',i)}}"></i></span>
            </a>
          </h4>
        </div>
        <div id="linkCollapseL{{i}}" class="collapse show" aria-labelledby="linkHeadingL{{i}}" data-parent="#leftaccordion">
          <div class="card-body">
            <div class="row">
              <div *ngFor="let document of group.documents" class="col-sm-6">
                <a href="{{document.linkUrl}}" target="new" class='card-body-links' *ngIf="!document.externalLink">
                  <div class="info-box grow" style="cursor: pointer; min-height: 92px;">
                    <span class="info-box-icon bg-gradient-light">
                      <i class="{{document.icon}}"></i>
                    </span>
                    <div class="info-box-content">
                      <span class="info-box-text text-wrap" style="white-space: normal !important;text-wrap:">
                        <span [innerHTML]="document.title"></span>
                      </span>
                      <div class="info-box-text text-wrap"><small>{{document.description}}</small></div>
                    </div>
                  </div>
                </a>
                <a href="javascript:void(0)" class='card-body-links' (click)="externalLinkClick(document.linkUrl)" *ngIf="document.externalLink">
                  <div class="info-box grow" style="cursor: pointer; min-height: 92px;">
                    <span class="info-box-icon bg-gradient-light">
                      <i class="{{document.icon}}"></i>
                    </span>
                    <div class="info-box-content">
                      <span class="info-box-text text-wrap" style="white-space: normal !important;text-wrap:">
                        <span [innerHTML]="document.title"></span>
                      </span>
                      <div class="info-box-text text-wrap"><small>{{document.description}}</small></div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="accordion" id="rightaccordion">
      <div class="card card-secondary" *ngFor="let group of groupsRight; let i = index">
        <div class="card-header" id="linkHeadingR{{i}}">
          <h4 class="card-title w-100" (click)="toggleAccordion('R',i)">
            <a class="d-block w-100 collapsed" data-toggle="collapse" href="#linkCollapseR{{i}}" aria-expanded="false">
              {{group.description}}&nbsp;&nbsp;<span><i class="{{getAccordionArrowStyle('R',i)}}"></i></span>
            </a>
          </h4>
        </div>
        <div id="linkCollapseR{{i}}" class="collapse show" aria-labelledby="linkHeadingR{{i}}" data-parent="#leftaccordion">
          <div class="card-body">
            <div class="row">
              <div *ngFor="let document of group.documents" class="col-sm-6">
                <a href="{{document.linkUrl}}" target="new" class='card-body-links' *ngIf="!document.externalLink">
                  <div class="info-box grow" style="cursor: pointer; min-height: 92px;">
                    <span class="info-box-icon bg-gradient-light">
                      <i class="{{document.icon}}"></i>
                    </span>
                    <div class="info-box-content text-wrap">
                      <span class="info-box-text" style="white-space: normal !important;">
                        <span [innerHTML]="document.title"></span>
                      </span>
                      <div class="info-box-text text-wrap"><small>{{document.description}}</small></div>
                    </div>
                  </div>
                </a>
                <a href="javascript:void(0)" class='card-body-links' (click)="externalLinkClick(document.linkUrl)" *ngIf="document.externalLink">
                  <div class="info-box grow" style="cursor: pointer; min-height: 92px;">
                    <span class="info-box-icon bg-gradient-light">
                      <i class="{{document.icon}}"></i>
                    </span>
                    <div class="info-box-content text-wrap">
                      <span class="info-box-text" style="white-space: normal !important;">
                        <span [innerHTML]="document.title"></span>
                      </span>
                      <div class="info-box-text text-wrap"><small>{{document.description}}</small></div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
