<div class="row justify-content-center">
  <div class="col-md-12">
    <div class="card card-secondary">
      <div class="card-header">
        <h4 class="card-title w-100">
          <span>Pending Collections</span>
        </h4>
      </div>
    </div>
    <div class="form-group row">
      <!--<label class="col-md-1 col-form-label text-right">Search</label>
      <div class="col-md-2">
        <input type="text" id="search" class="form-control" (input)="onSearchChanged()" />
      </div>-->
      <label class="col-md-2 col-form-label text-right">Selected Agent</label>
      <div class="col-md-3">
        <select id="selectedAgent" class="form-control" [(ngModel)]="selectedAgentId" (change)="changeAgentId($event)">
          or
          <option *ngFor="let agent of agents" value="{{agent.agentId}}">
            {{agent.agentId}} - {{agent.agentName}}
          </option>
        </select>
      </div>
      <label class="col-md-2 col-form-label text-right">Collecting Agent/Manager</label>
      <div class="col-md-3">
        <select id="collectingAgent" class="form-control">
        </select>
      </div>
    </div>
    <div class="row admin-box justify-content-center">
      <div class="col-md-12">
        <ag-grid-angular id="pendingCollectionsList" style="width: 100%; height: 640px;"
                         class="ag-theme-alpine"
                         [rowData]="pendingCollectionsRowData"
                         [columnDefs]="columnDefs"
                         [defaultColDef]="defaultColumnDef"
                         [pagination]="true"
                         [paginationPageSize]="10"
                         (gridReady)="onGridReady($event)">
        </ag-grid-angular>
      </div>
    </div>
  </div>
</div>
