import { Component, OnInit } from '@angular/core';
import { SiteParameterService } from '../../../services/siteparameter.service'
import { Observable, Subscription } from 'rxjs';
import { OnPremStorageService } from '../../../services/onprem-storage';
import { Profile } from '../../../models/profile';
import { map, take } from 'rxjs/operators';
import { Report } from '../../../models/report';
import { AgentLink } from '../../../models/agentLink';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {
  warning: string = "Warning";
  info: string = "Info";
  selectedAgentID: string = "";
  agentToken: string = "";
  profile!: Profile;
  subscriptionName: Subscription;
  public downlineAgentId?: string;
  public downlineAgents$?: Observable<AgentLink[]>;
  public reports$?: Observable<Report[]>;

  constructor(private onPremService: OnPremStorageService, private siteParmService: SiteParameterService) {
    this.subscriptionName = this.siteParmService.getAgentUpdate().subscribe
      (message => {
        this.selectedAgentID = message.value;
        this.downlineAgentId = this.selectedAgentID;
        this.getDownlineAgentList();
        this.getReports();
      });
  }
  ngOnInit(): void {
    this.profile = this.siteParmService.getStaticProfile();
    this.selectedAgentID = this.profile.selectedAgentNumber;
    this.getReports();
    this.downlineAgentId = this.selectedAgentID;
    this.getDownlineAgentList();
  }
  getDownlineAgentList(): void {
    this.downlineAgents$ = this.onPremService.getAgentHierarchyList(this.selectedAgentID).pipe(
      map(apiReponse => apiReponse.data));
  }
  getReports(): void {
    this.reports$ = this.onPremService.getReports(this.selectedAgentID).pipe(
      map(report => report.data));
  }
  getReport(url: string): void {
    this.onPremService.getAgentToken(this.downlineAgentId!)
      .pipe(take(1))
      .subscribe(t => {
        let reportURL = url.replace(this.selectedAgentID, this.downlineAgentId!) + t.data.token;
        window.open(reportURL, '_blank');
      });
  }
  ngOnDestroy() { // It's a good practice to unsubscribe to ensure no memory leaks
    this.subscriptionName.unsubscribe();
  }
}
