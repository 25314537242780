import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { SiteParameterService } from '../services/siteparameter.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileGuard implements CanActivate, CanActivateChild {
  private endpoint = './api/clouddata/';
  constructor(private router: Router, public siteParameterService: SiteParameterService) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this.siteParameterService.profileLoadComplete) {
      return this.siteParameterService.loadProfile();
    }
    else {
      return of(true);
    }
  }
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this.siteParameterService.profileLoadComplete) {
      return this.siteParameterService.loadProfile();
    }
    else {
      return of(true);
    }
  }
}
