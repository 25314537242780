<div class="nav-container mb-3">
  <nav class="navbar navbar-expand-md navbar-light bg-light">
    <div class="container-fluid justify-content-center">
      <ul class="nav navbar-nav" id="menu">
        <li class="nav-item">
          <a class="nav-link router-link-exact-active" id="Annuity-tab" data-toggle="tab" href="#Annuity">Annuity</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="HomeService-tab" data-toggle="tab" href="#HomeService">Home Service</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="MedicareSupp-tab" data-toggle="tab" href="#MedicareSuppLBL">Medicare Supplement (LBL)</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="MedicareSupp-tab" data-toggle="tab" href="#MedicareSuppCAP">Medicare Supplement (CAP)</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="MedicareSupp-tab" data-toggle="tab" href="#MedicareSuppABL">Medicare Supplement (ABL)</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="Ordinary-tab" data-toggle="tab" href="#Ordinary">Ordinary Life</a>
        </li>
        <!--<li class="nav-item">
          <a class="nav-link" id="PreneedABL-tab" data-toggle="tab" href="#PreneedABL">Pre-Need (ABL)</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="PreneedAML-tab" data-toggle="tab" href="#PreneedAML">Pre-Need (AML)</a>
        </li>-->
        <li class="nav-item">
          <a class="nav-link" id="SuppHealth-tab" data-toggle="tab" href="#SuppHealth">Supp Health</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="SuppHealthHIP-tab" data-toggle="tab" href="#SuppHealthHIP">Supp Health (Plus Series)</a>
        </li>
      </ul>
    </div>
  </nav>
</div>
<br />
<!-- Tab panes -->
<div class="tab-content clearfix" id="tabPanes">
  <div class="tab-pane" id="Annuity">
    <div class="col-md-8 mx-auto">
      <div class="card card-primary">
        <div class="card-header">
          <h4 class="card-title w-100">
            <span>Marketing</span>
          </h4>
        </div>
        <div class="card-body">
          <table class="table table-hover text-nowrap table-striped">
            <tbody>
              <tr>
                <td><b>Phone</b></td>
                <td>800-274-4829</td>
                <td><b>Email</b></td>
                <td>AnnuityMarketing@lbig.com</td>
              </tr>
              <tr>
                <td><b>Mailing Address</b></td>
                <td>9800 Woodway Drive <br /> Waco, TX 76712</td>
                <td><b>Fax</b></td>
                <td>254-751-0115</td>
              </tr>
              <tr>
                <td><b>Hours of Operation</b></td>
                <td colspan="3">M-Th 8:00am-5:00pm <br /> F 8:00am-3:00pm</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="col-md-8 mx-auto">
      <div class="card card-secondary">
        <div class="card-header">
          <h4 class="card-title w-100">
            <span>Additional Contact Information</span>
          </h4>
        </div>
        <div class="card-body">
          <div class="card-body table-responsive p-0">
            <table class="table table-hover text-nowrap table-striped">
              <tbody>
                <tr>
                  <th></th>
                  <th>New Business</th>
                  <th>Commissions</th>
                  <th>Policy Holder Service</th>
                  <th>Claims</th>
                </tr>
                <tr>
                  <td><b>Phone</b></td>
                  <td>800-745-4927</td>
                  <td>800-745-4927</td>
                  <td>800-745-4927</td>
                  <td>800-745-4927</td>
                </tr>
                <tr>
                  <td><b>Email</b></td>
                  <td>annuitynewbiz@insadmin.com</td>
                  <td>annuityCSR@insadmin.com</td>
                  <td>annuityCSR@insadmin.com</td>
                  <td>annuityCSR@insadmin.com</td>
                </tr>
                <tr>
                  <td><b>Mailing Address</b></td>
                  <td>PO Box 224 <br />Brownwood, TX 76804-0224</td>
                  <td>PO Box 224 <br />Brownwood, TX 76804-0224</td>
                  <td>PO Box 224 <br />Brownwood, TX 76804-0224</td>
                  <td>PO Box 224 <br />Brownwood, TX 76804-0224</td>
                </tr>
                <tr>
                  <td><b>Fax</b></td>
                  <td>844-351-6806</td>
                  <td>254-751-0115</td>
                  <td>844-351-6806</td>
                  <td>844-351-6806</td>
                </tr>
                <tr>
                  <td><b>Hours of Operation</b></td>
                  <td>M-F 8:00am-5:00pm</td>
                  <td>M-F 8:00am-5:00pm</td>
                  <td>M-F 8:00am-5:00pm</td>
                  <td>M-F 8:00am-5:00pm</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="tab-pane" id="HomeService">
    <div class="col-md-8 mx-auto">
      <div class="card card-primary">
        <div class="card-header">
          <h4 class="card-title w-100">
            <span>Marketing</span>
          </h4>
        </div>
        <div class="card-body">
          <table class="table table-hover text-nowrap table-striped">
            <tbody>
              <tr>
                <td><b>Phone</b></td>
                <td>1-866-615-2309</td>
                <td><b>Email</b></td>
                <td>sheri.fields@lbig.com</td>
              </tr>
              <tr>
                <td><b>Mailing Address</b></td>
                <td>6956 SW Woodbine Way<br /> Palm City, FL 34990</td>
                <td><b>Fax</b></td>
                <td>772-232-6640</td>
              </tr>
              <tr>
                <td><b>Hours of Operation</b></td>
                <td>M-Th 8:00am-5:30pm <br /> F 8:00am-4:00pm</td>
                <td colspan="2"></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="col-md-8 mx-auto">
      <div class="card card-secondary">
        <div class="card-header">
          <h4 class="card-title w-100">
            <span>Additional Contact Information</span>
          </h4>
        </div>
        <div class="card-body">
          <div class="card-body table-responsive p-0">
            <table class="table table-hover text-nowrap table-striped">
              <tbody>
                <tr>
                  <th></th>
                  <th>New Business</th>
                  <th>Commissions</th>
                  <th>Policy Holder Service</th>
                  <th>Claims</th>
                </tr>
                <tr>
                  <td><b>Phone</b></td>
                  <td>1-800-972-6615</td>
                  <td>1-800-972-6615</td>
                  <td>1-800-972-6615</td>
                  <td>1-800-972-6615</td>
                </tr>
                <tr>
                  <td><b>Email</b></td>
                  <td>newbiz@lbladmin.com</td>
                  <td>homeservice@life-insurers.com</td>
                  <td>homeservice@life-insurers.com</td>
                  <td>homeservice@life-insurers.com</td>
                </tr>
                <tr>
                  <td><b>Mailing Address</b></td>
                  <td>PO Box 224<br /> Brownwood, TX 76804-0224</td>
                  <td>PO Box 224<br /> Brownwood, TX 76804-0224</td>
                  <td>PO Box 224<br /> Brownwood, TX 76804-0224</td>
                  <td>PO Box 224<br /> Brownwood, TX 76804-0224</td>
                </tr>
                <tr>
                  <td><b>Fax</b></td>
                  <td>1-888-525-5002</td>
                  <td>1-866-505-9377</td>
                  <td>1-866-505-9377</td>
                  <td>1-866-505-9377</td>
                </tr>
                <tr>
                  <td><b>Hours of Operation</b></td>
                  <td>M-Th 8:00am-5:30pm<br /> F 8:00am-4:00pm</td>
                  <td>M-Th 8:00am-5:30pm<br /> F 8:00am-4:00pm</td>
                  <td>M-Th 8:00am-5:30pm<br /> F 8:00am-4:00pm</td>
                  <td>M-Th 8:00am-5:30pm<br /> F 8:00am-4:00pm</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="tab-pane" id="MedicareSuppLBL">
    <div class="col-md-8 mx-auto">
      <div class="card card-primary">
        <div class="card-header">
          <h4 class="card-title w-100">
            <span>Marketing</span>
          </h4>
        </div>
        <div class="card-body">
          <table class="table table-hover text-nowrap table-striped">
            <tbody>
              <tr>
                <td><b>Phone</b></td>
                <td></td>
                <td><b>Email</b></td>
                <td></td>
              </tr>
              <tr>
                <td><b>Mailing Address</b></td>
                <td></td>
                <td><b>Fax</b></td>
                <td></td>
              </tr>
              <tr>
                <td><b>Hours of Operation</b></td>
                <td colspan="3"></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="col-md-8 mx-auto">
      <div class="card card-secondary">
        <div class="card-header">
          <h4 class="card-title w-100">
            <span>Additional Contact Information</span>
          </h4>
        </div>
        <div class="card-body">
          <div class="card-body table-responsive p-0">
            <table class="table table-hover text-nowrap table-striped">
              <tbody>
                <tr>
                  <th></th>
                  <th>New Business</th>
                  <th>Commissions</th>
                  <th>Policy Holder Service</th>
                  <th>Claims</th>
                </tr>
                <tr>
                  <td><b>Phone</b></td>
                  <td></td>
                  <td></td>
                  <td>844-770-2400</td>
                  <td></td>
                </tr>
                <tr>
                  <td><b>Email</b></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td><b>Mailing Address</b></td>
                  <td></td>
                  <td></td>
                  <td>PO Box 14707 <br />Clearwater, FL 33766-4707</td>
                  <td></td>
                </tr>
                <tr>
                  <td><b>Fax</b></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td><b>Hours of Operation</b></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="tab-pane" id="MedicareSuppCAP">
    <div class="col-md-9 mx-auto">
      <div class="card card-primary">
        <div class="card-header">
          <h4 class="card-title w-100">
            <span>Marketing</span>
          </h4>
        </div>
        <div class="card-body">
          <table class="table table-hover text-nowrap table-striped">
            <tbody>
              <tr>
                <td><b>Phone</b></td>
                <td>(866) 237-3010</td>
                <td><b>Email</b></td>
                <td></td>
              </tr>
              <tr>
                <td><b>Mailing Address</b></td>
                <td></td>
                <td><b>Fax</b></td>
                <td></td>
              </tr>
              <tr>
                <td><b>Hours of Operation</b></td>
                <td>Mon - Thu: 7:30a-4:00p CT <br />Fri: 7:30 - 11:00a CT</td>
                <td colspan="2"></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="col-md-9 mx-auto">
      <div class="card card-secondary">
        <div class="card-header">
          <h4 class="card-title w-100">
            <span>Additional Contact Information</span>
          </h4>
        </div>
        <div class="card-body">
          <div class="card-body table-responsive p-0">
            <table class="table table-hover text-nowrap table-striped">
              <tbody>
                <tr>
                  <th></th>
                  <th>New Business</th>
                  <th>Commissions</th>
                  <th>Policy Holder Service</th>
                  <th>Claims</th>
                </tr>
                <tr>
                  <td><b>Phone</b></td>
                  <td>(866) 237-3010</td>
                  <td>(866) 237-3010</td>
                  <td>(866) 237-3010</td>
                  <td>(866) 237-3010</td>
                </tr>
                <tr>
                  <td><b>Email</b></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td><b>Mailing Address</b></td>
                  <td>The Capitol Life Insurance Company <br />P.O. Box 14399 <br />Lexington, KY 40512-9700</td>
                  <td></td>
                  <td></td>
                  <td>The Capitol Life Insurance Company <br />P.O. Box 14399 <br />Lexington, KY 40512-9700</td>
                </tr>
                <tr>
                  <td><b>Fax</b></td>
                  <td>(859) 425-3368</td>
                  <td></td>
                  <td></td>
                  <td>(859) 425-3368</td>
                </tr>
                <tr>
                  <td><b>Hours of Operation</b></td>
                  <td>Mon - Fri 7:30a - 5:00p CT</td>
                  <td>Mon - Fri 8:00a -5:00p CT (agents) <br />Mon - Fri 7:00a -7:00p CT</td>
                  <td>Mon - Fri 8:00a -5:00p CT (agents) <br />Mon - Fri 7:00a -7:00p CT (policyholders)</td>
                  <td>Mon - Fri 7:00a-5:00p CT</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="tab-pane" id="MedicareSuppABL">
    <div class="col-md-9 mx-auto">
      <div class="card card-primary">
        <div class="card-header">
          <h4 class="card-title w-100">
            <span>Marketing</span>
          </h4>
        </div>
        <div class="card-body">
          <table class="table table-hover text-nowrap table-striped">
            <tbody>
              <tr>
                <td><b>Phone</b></td>
                <td>(833) 504-0331</td>
                <td><b>Email</b></td>
                <td></td>
              </tr>
              <tr>
                <td><b>Mailing Address</b></td>
                <td></td>
                <td><b>Fax</b></td>
                <td></td>
              </tr>
              <tr>
                <td><b>Hours of Operation</b></td>
                <td>Mon -Thu: 7:30 AM-4:00 PM CST <br />Fri: 7:30 AM - 11:00 AM CST</td>
                <td colspan="2"></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="col-md-9 mx-auto">
      <div class="card card-secondary">
        <div class="card-header">
          <h4 class="card-title w-100">
            <span>Additional Contact Information</span>
          </h4>
        </div>
        <div class="card-body">
          <div class="card-body table-responsive p-0">
            <table class="table table-hover text-nowrap table-striped">
              <tbody>
                <tr>
                  <th></th>
                  <th>New Business</th>
                  <th>Commissions</th>
                  <th>Policy Holder Service</th>
                  <th>Claims</th>
                </tr>
                <tr>
                  <td><b>Phone</b></td>
                  <td>(833) 504-0331</td>
                  <td>(833) 504-0331</td>
                  <td>(833) 504-0331</td>
                  <td>(833) 504-0331</td>
                </tr>
                <tr>
                  <td><b>Email</b></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td><b>Mailing Address</b></td>
                  <td>American Benefit Life Insurance Company American Benefit Life <br />P.O. Box 14399 <br />Insurance Company <br />Lexington, KY 40512-9700</td>
                  <td></td>
                  <td></td>
                  <td>American Benefit Life Insurance Company <br />PO Box 14865 <br />Lexington, KY 40512-4865</td>
                </tr>
                <tr>
                  <td><b>Fax</b></td>
                  <td>(833) 380-2777</td>
                  <td></td>
                  <td>(855) 291-0553</td>
                  <td>(859) 280-1264</td>
                </tr>
                <tr>
                  <td><b>Hours of Operation</b></td>
                  <td>M-F 7:30 AM-7:00 PM CST</td>
                  <td></td>
                  <td>M-F 8:00 AM-5:00 PM CST (policyholders) <br />(agents) M-F 7:00 AM-7:00 PM CST </td>
                  <td>M-F 7:00 AM-5:00 PM CST</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="tab-pane" id="Ordinary">
    <div class="col-md-8 mx-auto">
      <div class="card card-primary">
        <div class="card-header">
          <h4 class="card-title w-100">
            <span>Marketing</span>
          </h4>
        </div>
        <div class="card-body">
          <table class="table table-hover text-nowrap table-striped">
            <tbody>
              <tr>
                <td><b>Phone</b></td>
                <td>800-731-4300</td>
                <td><b>Email</b></td>
                <td>david.decker@lbig.com</td>
              </tr>
              <tr>
                <td><b>Mailing Address</b></td>
                <td>1605 LBJ FWY Suite 700 Dallas, TX 75234</td>
                <td><b>Fax</b></td>
                <td></td>
              </tr>
              <tr>
                <td><b>Hours of Operation</b></td>
                <td colspan="3">M-F 8:00am-5:00pm</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="col-md-8 mx-auto">
      <div class="card card-secondary">
        <div class="card-header">
          <h4 class="card-title w-100">
            <span>Additional Contact Information</span>
          </h4>
        </div>
        <div class="card-body">
          <div class="card-body table-responsive p-0">
            <table class="table table-hover text-nowrap table-striped">
              <tbody>
                <tr>
                  <th></th>
                  <th>New Business</th>
                  <th>Commissions</th>
                  <th>Policy Holder Service</th>
                  <th>Claims</th>
                </tr>
                <tr>
                  <td><b>Phone</b></td>
                  <td>888-525-4467 opt 1</td>
                  <td>214-245-5292</td>
                  <td>800-604-8002 opt 2</td>
                  <td>800-604-8002 opt 1</td>
                </tr>
                <tr>
                  <td><b>Email</b></td>
                  <td>agentNBinfo@life-insurers.com</td>
                  <td>agentsupport@lbig.com</td>
                  <td>csr@life-insurers.com</td>
                  <td>csr@life-insurers.com</td>
                </tr>
                <tr>
                  <td><b>Mailing Address</b></td>
                  <td>PO Box 224 <br /> Brownwood, TX 76804-0224</td>
                  <td>1605 LBJ FWY Suite 700 <br /> Dallas, TX 75234 <br /> ATTN: Lizzie Carmack</td>
                  <td>PO Box 224 <br /> Brownwood, TX 76804-0224</td>
                  <td>PO Box 224 <br /> Brownwood, TX 76804-0224</td>
                </tr>
                <tr>
                  <td><b>Fax</b></td>
                  <td>888-525-5002</td>
                  <td>972-247-6291</td>
                  <td>888-525-5002</td>
                  <td>888-525-5002</td>
                </tr>
                <tr>
                  <td><b>Hours of Operation</b></td>
                  <td>M-Th 8:00am-5:00pm <br /> F 8:00am-2:30pm</td>
                  <td>M-F 8:00am-5:00pm</td>
                  <td>M-Th 8:00am-5:00pm <br /> F 8:00am-2:30pm</td>
                  <td>M-Th 8:00am-5:00pm <br /> F 8:00am-2:30pm</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--<div class="tab-pane" id="PreneedABL">
    <div class="col-md-8 mx-auto">
      <div class="card card-primary">
        <div class="card-header">
          <h4 class="card-title w-100">
            <span>Marketing</span>
          </h4>
        </div>
        <div class="card-body">
          <table class="table table-hover text-nowrap table-striped">
            <tbody>
              <tr>
                <td><b>Phone</b></td>
                <td>844-847-7387</td>
                <td><b>Email</b></td>
                <td>PN.Development@lbig.com</td>
              </tr>
              <tr>
                <td><b>Mailing Address</b></td>
                <td>PO Box 309<br /> Noxapater, MS 39346</td>
                <td><b>Fax</b></td>
                <td>662-724-2958</td>
              </tr>
              <tr>
                <td><b>Hours of Operation</b></td>
                <td colspan="3">
                  M-Th 8:00am-5:00pm<br />F 8:00am-2:30pm
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="col-md-8 mx-auto">
      <div class="card card-secondary">
        <div class="card-header">
          <h4 class="card-title w-100">
            <span>Additional Contact Information</span>
          </h4>
        </div>
        <div class="card-body">
          <div class="card-body table-responsive p-0">
            <table class="table table-hover text-nowrap table-striped">
              <tbody>
                <tr>
                  <th></th>
                  <th>New Business</th>
                  <th>Commissions</th>
                  <th>Policy Holder Service</th>
                  <th>Claims</th>
                </tr>
                <tr>
                  <td><b>Phone</b></td>
                  <td>800-554-4956</td>
                  <td>800-554-4956</td>
                  <td>800-554-4956</td>
                  <td>800-554-4956</td>
                </tr>
                <tr>
                  <td><b>Email</b></td>
                  <td>new.business@lbig.com</td>
                  <td>commissions@lbig.com</td>
                  <td>customer.service@lbig.com</td>
                  <td>claims@lbig.com</td>
                </tr>
                <tr>
                  <td><b>Mailing Address</b></td>
                  <td>PO Box 341989 <br /> Austin, TX 78734</td>
                  <td>PO Box 341989 <br /> Austin, TX 78734</td>
                  <td>PO Box 341989 <br /> Austin, TX 78734</td>
                  <td>PO Box 341989 <br /> Austin, TX 78734</td>
                </tr>
                <tr>
                  <td><b>Fax</b></td>
                  <td>New Applications: 512-637-4199 <br /> Corrections only: 512-637-4166</td>
                  <td>512-263-7714</td>
                  <td>512-263-6981</td>
                  <td>512-637-4989</td>
                </tr>
                <tr>
                  <td><b>Hours of Operation</b></td>
                  <td>M-F 8:30am - 4:30pm</td>
                  <td>M-F 8:30am - 4:30pm</td>
                  <td>M-F 8:30am - 4:30pm</td>
                  <td>M-F 8:30am - 4:30pm</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="tab-pane" id="PreneedAML">
    <div class="col-md-8 mx-auto">
      <div class="card card-primary">
        <div class="card-header">
          <h4 class="card-title w-100">
            <span>Marketing</span>
          </h4>
        </div>
        <div class="card-body">
          <table class="table table-hover text-nowrap table-striped">
            <tbody>
              <tr>
                <td><b>Phone</b></td>
                <td>844-847-7387</td>
                <td><b>Email</b></td>
                <td>AMLAdvantage@lbig.com</td>
              </tr>
              <tr>
                <td><b>Mailing Address</b></td>
                <td>PO Box 309<br /> Noxapater, MS 39346</td>
                <td><b>Fax</b></td>
                <td>662-724-2958</td>
              </tr>
              <tr>
                <td><b>Hours of Operation</b></td>
                <td colspan="3">
                  M-Th 8:00am-5:00pm<br />F 8:00am-2:30pm
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="col-md-8 mx-auto">
      <div class="card card-secondary">
        <div class="card-header">
          <h4 class="card-title w-100">
            <span>Additional Contact Information</span>
          </h4>
        </div>
        <div class="card-body">
          <div class="card-body table-responsive p-0">
            <table class="table table-hover text-nowrap table-striped">
              <tbody>
                <tr>
                  <th></th>
                  <th>New Business</th>
                  <th>Commissions</th>
                  <th>Policy Holder Service</th>
                  <th>Claims</th>
                </tr>
                <tr>
                  <td><b>Phone</b></td>
                  <td>225-387-0171</td>
                  <td>800-972-6615</td>
                  <td>800-972-6615</td>
                  <td>225-387-0171</td>
                </tr>
                <tr>
                  <td><b>Email</b></td>
                  <td>aml@lbig.com</td>
                  <td>aml@lbig.com</td>
                  <td>aml@lbig.com</td>
                  <td>aml@lbig.com</td>
                </tr>
                <tr>
                  <td><b>Mailing Address</b></td>
                  <td>PO Box 224 <br /> Brownwood, TX 76804-0224</td>
                  <td>PO Box 224 <br /> Brownwood, TX 76804-0224</td>
                  <td>PO Box 224 <br /> Brownwood, TX 76804-0224</td>
                  <td>PO Box 224 <br /> Brownwood, TX 76804-0224</td>
                </tr>
                <tr>
                  <td><b>Fax</b></td>
                  <td>225-387-0183</td>
                  <td>225-387-0183</td>
                  <td>225-387-0183</td>
                  <td>225-387-0183</td>
                </tr>
                <tr>
                  <td><b>Hours of Operation</b></td>
                  <td>M-Th 8:00am-5:00pm <br /> F 8:00am-2:30pm</td>
                  <td>M-Th 8:00am-5:00pm <br /> F 8:00am-2:30pm</td>
                  <td>M-Th 8:00am-5:00pm <br /> F 8:00am-2:30pm</td>
                  <td>M-Th 8:00am-5:00pm <br /> F 8:00am-2:30pm</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>-->
  <div class="tab-pane" id="SuppHealth">
    <div class="col-md-8 mx-auto">
      <div class="card card-primary">
        <div class="card-header">
          <h4 class="card-title w-100">
            <span>Marketing</span>
          </h4>
        </div>
        <div class="card-body">
          <table class="table table-hover text-nowrap table-striped">
            <tbody>
              <tr>
                <td><b>Phone</b></td>
                <td>469-522-4624</td>
                <td><b>Email</b></td>
                <td>mjones@lbig.com</td>
              </tr>
              <tr>
                <td><b>Mailing Address</b></td>
                <td>1605 LBJ Freeway <br /> Suite 700 <br /> Dallas, TX 75234</td>
                <td><b>Fax</b></td>
                <td></td>
              </tr>
              <tr>
                <td><b>Hours of Operation</b></td>
                <td>M-F 8:00am-5:00pm</td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="col-md-8 mx-auto">
      <div class="card card-secondary">
        <div class="card-header">
          <h4 class="card-title w-100">
            <span>Additional Contact Information</span>
          </h4>
        </div>
        <div class="card-body">
          <div class="card-body table-responsive p-0">
            <table class="table table-hover text-nowrap table-striped">
              <tbody>
                <tr>
                  <th></th>
                  <th>New Business</th>
                  <th>Commissions</th>
                  <th>Policy Holder Service</th>
                  <th>Claims</th>
                </tr>
                <tr>
                  <td><b>Phone</b></td>
                  <td>888-525-4467</td>
                  <td>800-731-4300</td>
                  <td>800-604-8002</td>
                  <td>800-604-8002</td>
                </tr>
                <tr>
                  <td><b>Email</b></td>
                  <td>newbiz@life-insurers.com </td>
                  <td>agentsupport@lbig.com</td>
                  <td>csr@insadmin.com</td>
                  <td>supphealthclaims@lbig.com</td>
                </tr>
                <tr>
                  <td><b>Mailing Address</b></td>
                  <td>PO Box 224 <br />Brownwood TX 76804-0224</td>
                  <td>1605 LBJ Freeway, Suite 700<br />Dallas, TX  75234</td>
                  <td>PO Box 224 <br />Brownwood TX 76804-0224</td>
                  <td>PO Box 224 <br />Brownwood TX 76804-0224</td>
                </tr>
                <tr>
                  <td><b>Fax</b></td>
                  <td>888-525-5002</td>
                  <td>972-247-6291</td>
                  <td>325-643-4043</td>
                  <td>325-643-4043</td>
                </tr>
                <tr>
                  <td><b>Hours of Operation</b></td>
                  <td>M-F 8:00am-5:00pm</td>
                  <td>M-F 8:00am-5:00pm</td>
                  <td>M-F 8:00am-5:00pm</td>
                  <td>M-F 8:00am-5:00pm</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="tab-pane" id="SuppHealthHIP">
    <div class="col-md-8 mx-auto">
      <div class="card card-primary">
        <div class="card-header">
          <h4 class="card-title w-100">
            <span>Marketing</span>
          </h4>
        </div>
        <div class="card-body">
          <table class="table table-hover text-nowrap table-striped">
            <tbody>
              <tr>
                <td><b>Phone</b></td>
                <td>469-522-4624</td>
                <td><b>Email</b></td>
                <td>mjones@lbig.com</td>
              </tr>
              <tr>
                <td><b>Mailing Address</b></td>
                <td>1605 LBJ Freeway <br /> Suite 700 <br /> Dallas, TX 75234</td>
                <td><b>Fax</b></td>
                <td>972-247-6291</td>
              </tr>
              <tr>
                <td><b>Hours of Operation</b></td>
                <td>M-F 8:00am-5:00pm CST</td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="col-md-8 mx-auto">
      <div class="card card-secondary">
        <div class="card-header">
          <h4 class="card-title w-100">
            <span>Additional Contact Information</span>
          </h4>
        </div>
        <div class="card-body">
          <div class="card-body table-responsive p-0">
            <table class="table table-hover text-nowrap table-striped">
              <tbody>
                <tr>
                  <th></th>
                  <th>New Business</th>
                  <th>Commissions</th>
                  <th>Policy Holder Service</th>
                  <th>Claims</th>
                </tr>
                <tr>
                  <td><b>Phone</b></td>
                  <td>833-658-2844</td>
                  <td>833-658-2844</td>
                  <td>833-658-2844</td>
                  <td>833-658-2844</td>
                </tr>
                <tr>
                  <td><b>Email</b></td>
                  <td>supphealthNB@lbig.com</td>
                  <td>supphealthNB@lbig.com</td>
                  <td>supphealthService@lbig.com</td>
                  <td>supphealthclaims@lbig.com</td>
                </tr>
                <tr>
                  <td><b>Mailing Address</b></td>
                  <td>PO Box 17628<br />Winston-Salem NC 27116</td>
                  <td>PO Box 17628<br />Winston-Salem NC 27116</td>
                  <td>PO Box 17628<br />Winston-Salem NC 27116</td>
                  <td>PO Box 17628<br />Winston-Salem NC 27116</td>
                </tr>
                <tr>
                  <td><b>Fax</b></td>
                  <td>336-464-2684</td>
                  <td>336-464-2684</td>
                  <td>336-464-2664</td>
                  <td>336-464-2961</td>
                </tr>
                <tr>
                  <td><b>Hours of Operation</b></td>
                  <td>M-F 8:00am-5:00pm EST</td>
                  <td>M-F 8:00am-5:00pm EST</td>
                  <td>M-F 8:00am-5:00pm EST</td>
                  <td>M-F 8:00am-5:00pm EST</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


