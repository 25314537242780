<div class="nav-container mb-12" [hidden]="hideFromAnnuityBank">
  <nav class="navbar navbar-expand-md navbar-light bg-light">
    <div class="container-fluid justify-content-center">
      <ul class="nav navbar-nav" id="menu" style="border: 1px solid black; border-radius:10px;">
        <li class="nav-item">
          <a class="nav-link" id="overview-tab" data-toggle="tab" href="#Overview" (click)="updatePreference(true)">LBIG Overview</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="channel-tab" data-toggle="tab" href="#Channel" (click)="updatePreference(false)">{{profile ? profile.channelPreference : ""}}</a>
        </li>
      </ul>
    </div>
  </nav>
</div>

<div class="tab-content clearfix" id="tabPanes">
  <div class="tab-pane" id="Overview">
    <div class="row">
      <div class="col-md-4">
        <div class="card card-secondary h-100">
          <div class="card-body bg-light">
            <div class="col-md-12 text-center">
              <img src="../../../../assets/images/compass.png" height="80" />
            </div>
            <div class="col-md-12 text-center">
              <h1>{{sections[0].description}}</h1>
            </div>
            <hr />
            <div *ngFor="let product of sections[0].products;" class="col-md-12">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <h2>{{product.title}}</h2>
                  </div>
                  <div class="row">
                    <h3>{{product.description}}</h3>
                    <hr />
                  </div>
                  <div class="row">
                    <div class="col-md-12 text-center">
                      <a href="mailto:{{product.email}}?subject={{product.title}}-Get Contracted"><input type="button" value="Get Contracted" class="btn btn-secondary input-block-level" /></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card card-secondary h-100">
          <div class="card-body bg-light">
            <div class="col-md-12 text-center">
              <img src="../../../../assets/images/plant.png" height="80" />
            </div>
            <div class="col-md-12 text-center">
              <h1>{{sections[1].description}}</h1>
            </div>
            <hr />
            <div *ngFor="let product of sections[1].products;" class="col-md-12">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <h2>{{product.title}}</h2>
                  </div>
                  <div class="row">
                    <h3>{{product.description}}</h3>
                    <hr />
                  </div>
                  <div class="row">
                    <div class="col-md-12 text-center">
                      <a href="mailto:{{product.email}}?subject={{product.title}}-Get Contracted"><input type="button" value="Get Contracted" class="btn btn-secondary input-block-level" /></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card card-secondary h-100">
          <div class="card-body bg-light">
            <div class="col-md-12 text-center">
              <img src="../../../../assets/images/umbrella.png" height="80" />
            </div>
            <div class="col-md-12 text-center">
              <h1>{{sections[2].description}}</h1>
            </div>
            <hr />
            <div *ngFor="let product of sections[2].products;" class="col-md-12">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <h2>{{product.title}}</h2>
                  </div>
                  <div class="row">
                    <h3>{{product.description}}</h3>
                    <hr />
                  </div>
                  <div class="row">
                    <div class="col-md-12 text-center">
                      <a href="mailto:{{product.email}}?subject={{product.title}}-Get Contracted"><input type="button" value="Get Contracted" class="btn btn-secondary input-block-level" /></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="tab-pane" id="Channel" *ngIf="profile">
    <div class="card card-secondary h-100">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12 text-center">
            <h2 class="card-title w-100 text-center">
              <span>{{channelProduct.channelHeadline}}</span>
            </h2>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 text-center">
            <h4 innerHTML={{channelProduct.description}}></h4>
            <hr />
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 text-center">
            <a class="btn-tool text-md" target="_blank" href="{{channelProduct.statePDF}}"><i class="fad fa-map-marked-alt"></i>&nbsp;&nbsp;State Availability PDF</a>
          </div>
          <div class="col-md-6 text-center" style="color: #6564ad;" *ngIf="!showForAnnuity; else annuityProductPDF">
            <a class="btn-tool text-md" target="_blank" href="{{channelProduct.productPDF}}"><i class="fad fa-briefcase"></i>&nbsp;&nbsp;Product Portfolio PDF</a>
          </div>
          <ng-template #annuityProductPDF>
            <div class="col-md-6 text-center" style="color: #6564ad;">
              <a class="btn-tool text-md" target="_blank" href="{{channelProduct.productPDF}}"><i class="fad fa-briefcase"></i>&nbsp;&nbsp;Product Portfolio PDF</a>
              <br />
              <a class="btn-tool text-md" target="_blank" href="{{ channelProduct.productPDF.toString() | replace : '.pdf' : ' - CA.pdf' }}"><i class="fad fa-briefcase"></i>&nbsp;&nbsp;Product Portfolio PDF (CA Only)</a>
            </div>
          </ng-template>
        </div>
        <div *ngIf="slides.length > 0" class="row justify-content-center mt-2">
          <div class="col-md-3">
            <div class="form-group">
              <select id="selSlide" class="form-control" (change)="carouselRef.selectSlide($event.target['selectedIndex'])">
                <option *ngFor="let slide of slides; let index=index">
                  {{slide}}
                </option>
              </select>
            </div>
          </div>
        </div>
        <!--<hr/>-->
        <div class="container justify-content-center">
          <!--<mdb-carousel #carouselRef [isControls]="true" [animation]="'slide'" id="myCarousel" [interval]="80000">-->
          <mdb-carousel #carouselRef [isControls]="false" id="myCarousel" [interval]="0">
            <mdb-carousel-item *ngFor="let product of channelProduct.products" style="height:100%;">
              <div class="card text-white bg-secondary" style="width:100%">
                <div class="card-body h-100">
                  <h3 class="card-title"><b>{{product.title}}</b></h3>
                  <p class="card-text" innerHTML={{product.description}}></p>
                  <hr />
                  <h3>Product Details</h3>
                  <div class="table-responsive p-0">
                    <table class="table table-hover text-nowrap table-striped">
                      <tbody>
                        <tr *ngFor="let feature of product.features">
                          <td>{{feature.title}}</td>
                          <td innerHTML={{feature.description}}></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </mdb-carousel-item>
          </mdb-carousel>
        </div>
      </div>
    </div>
  </div>
</div>
