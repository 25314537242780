<div class="nav-container mb-1">
  <nav class="navbar navbar-expand-md navbar-light bg-light">
    <div class="container-fluid justify-content-center">
      <ul class="nav navbar-nav" id="menu">
        <li class="nav-item">
          <a class="nav-link router-link-exact-active" id="Association-tab" data-toggle="tab" href="#Association">Associations</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="LinkCode-tab" data-toggle="tab" href="#LinkCode">Linking Code</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="StatementSearch-tab" data-toggle="tab" href="#StatementSearch">Statements</a>
        </li>
      </ul>
    </div>
  </nav>
</div>
<br />
<!-- Tab panes -->
<div class="tab-content clearfix" id="tabPanes">
  <div class="tab-pane active" id="Association">
    <div *ngIf="showAssociation">
      <app-associations></app-associations>
    </div>
  </div>
  <div class="tab-pane" id="LinkCode">
    <div *ngIf="showLinkCode">
      <app-linkcode></app-linkcode>
    </div>
  </div>
  <div class="tab-pane" id="StatementSearch">
    <div *ngIf="showStatementSearch">
      <app-statementsearch></app-statementsearch>
    </div>
  </div>
</div>
