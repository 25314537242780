import { Component, AfterViewInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Profile } from '../../../models/profile';
import { SiteParameterService } from '../../../services/siteparameter.service';

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
  styles: [
  ]
})
export class ContactPageComponent implements AfterViewInit {
  profile!: Profile;
  private channelUpdate: Subscription;

  constructor(private siteParmService: SiteParameterService) {
    this.channelUpdate = this.siteParmService.getChannelUpdate().subscribe
      (message => {
        this.setTab();
      });
  }

  ngAfterViewInit(): void {
    this.profile = this.siteParmService.getStaticProfile();
    this.setTab();
    $('#menu li a').click(function () {
      if (this.parentElement?.className.indexOf('dropdown') == -1) {
        $('#menu').find("*").removeClass("router-link-exact-active");
        $(this).addClass('router-link-exact-active');
      }
    });
  }
  setTab(): void {
    $('#menu').find("*").removeClass("router-link-exact-active");
    $('#tabPanes').find("*").removeClass("active");

    let selectedChannel = this.profile ? this.profile.channelPreference : "";

    switch (selectedChannel) {
      case "Home Service": {
        $('#HomeService-tab').addClass('router-link-exact-active');
        $('#HomeService').addClass('tab-pane active');
        break;
      }
      case "Home Service [Career]": {
        $('#HomeService-tab').addClass('router-link-exact-active');
        $('#HomeService').addClass('tab-pane active');
        break;
      }
      case "Supplemental Health [Plus Series]": {
        $('#SuppHealthHIP-tab').addClass('router-link-exact-active');
        $('#SuppHealthHIP').addClass('tab-pane active');
        break;
      }
      case "Ordinary Life": {
        $('#Ordinary-tab').addClass('router-link-exact-active');
        $('#Ordinary').addClass('tab-pane active');
        break;
      }
      case "Pre-Need": {
        $('#HomeService-tab').addClass('router-link-exact-active');
        $('#HomeService').addClass('tab-pane active');
        break;
      }
      default: {
        $('#Annuity-tab').addClass('router-link-exact-active');
        $('#Annuity').addClass('tab-pane active');
        break;
      }
    }
  }
  ngOnDestroy() { // It's a good practice to unsubscribe to ensure no memory leaks
    this.channelUpdate.unsubscribe();
  }
}
