<div class="row">
  <div class="col-md-12">
    <div class="card card-secondary print-suppress">
      <div class="card-header">
        <h4 class="card-title w-100">
          <span>Policy Search</span>
        </h4>
      </div>
      <div class="card-body">
        <div class="nav-container mb-3">
          <nav class="navbar navbar-expand-md navbar-light bg-light">
            <div class="container-fluid justify-content-center">
              <ul class="nav navbar-nav" id="submenu">
                <li class="nav-item">
                  <a class="nav-link router-link-exact-active" id="QuickSearch-tab" data-toggle="tab" href="#QuickSearch" (click)="clearSearch('1')">Quick Search</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" id="AdvancedSearch-tab" data-toggle="tab" href="#AdvancedSearch" (click)="clearSearch('2')">Advanced Search</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" id="KPI-tab" data-toggle="tab" href="#KPI" (click)="clearSearch('3')">Key Performance Indicators</a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
        <div class="tab-content clearfix" id="tabPanes">
          <div class="tab-pane active" id="QuickSearch">
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label>Agents</label>
                  <select id="includedAgents" class="form-control" [(ngModel)]="includedAgents">
                    <option value="3">All</option>
                    <option value="1">My Agent ID's</option>
                    <option value="2">My Downline</option>
                  </select>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label>Status Category</label>
                  <select id="policyType" class="form-control" [(ngModel)]="policyStatus" (change)="checkDates()">
                    <option value="Inforce">Inforce</option>
                    <option value="Issued">Issued</option>
                    <option value="Submitted">Submitted</option>
                    <option value="Not Taken">Not&nbsp;Taken</option>
                    <option value="Lapsed">Lapsed</option>
                    <option value="Pending">Pending</option>
                    <option value="Past Due">Past Due</option>
                    <option value="Cancelled">Cancelled/Declined</option>
                    <option value="Death">Death</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>Time Frame</label>
                  <select id="timeFrame" class="form-control" [(ngModel)]="timeFrame" (change)="changeTimeFrame($event)" [disabled]="disableTimeFrame">
                    <option value="ALL">All Dates</option>
                    <option value="MTD">Current Month</option>
                    <option value="QTD">Current Quarter</option>
                    <option value="WTD">Current Week</option>
                    <option value="YTD">Current Year</option>
                    <option value="MTDP">Prior Month</option>
                    <option value="QTDP">Prior Quarter</option>
                    <option value="WTDP">Prior Week</option>
                    <option value="YTDP">Prior Year</option>
                  </select>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label>From</label>
                  <input type="text" readonly class="form-control" id="timeFrameStart" value="{{timeFrameStart | date:'MM/dd/yyyy'}}">
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label>To</label>
                  <input type="text" readonly class="form-control" id="timeFrameEnd" value="{{timeFrameEnd | date:'MM/dd/yyyy'}}">
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane" id="AdvancedSearch">
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label>Status Category</label>
                  <select id="policyStatus" class="form-control" [(ngModel)]="policyStatus" (change)="checkAdvancedDates()">
                    <option value="Any">Any</option>
                    <option value="Inforce">Inforce</option>
                    <option value="Issued">Issued</option>
                    <option value="Submitted">Submitted</option>
                    <option value="Not Taken">Not&nbsp;Taken</option>
                    <option value="Lapsed">Lapsed</option>
                    <option value="Pending">Pending</option>
                    <option value="Past Due">Past Due</option>
                    <option value="Cancelled">Cancelled/Declined</option>
                    <option value="Death">Death</option>
                  </select>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label>From</label>
                  <input type="date" class="form-control" id="startDate" (change)="onStartDateSelect($event)" [valueAsDate]="startDate" [disabled]="disableAdvancedDates">
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label>To</label>
                  <input type="date" class="form-control" id="endDate" (change)="onEndDateSelect($event)" [valueAsDate]="endDate" [disabled]="disableAdvancedDates">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>Agent</label>
                  <select id="agent" class="form-control" [(ngModel)]="agent" (change)="getDownlineAgentList($event.target.value)">
                    <option value="ANY">Any</option>
                    <option *ngFor="let agent of agents" value="{{agent.agentId}}">
                      {{agent.agentId}} - {{agent.agentName}}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Downline Agent</label>
                  <select id="downlineAgent" class="form-control" [(ngModel)]="downlineAgent">
                    <option value="ANY">Any</option>
                    <option *ngFor="let agent of downlineAgents" value="{{agent.agentId}}">
                      {{agent.agentId}} - {{agent.agentName}}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>Name</label>
                  <input type="text" class="form-control" id="insuredName" [(ngModel)]="insuredName">
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Address/City</label>
                  <input type="text" class="form-control" id="addressCity" [(ngModel)]="addressCity">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>Phone Number</label>
                  <input type="text" class="form-control" id="phone" [(ngModel)]="phone">
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Policy Number</label>
                  <input type="text" class="form-control" id="policy" [(ngModel)]="policy">
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane" id="KPI">
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label>Key Performance Indicators</label>
                  <select id="kpi" class="form-control" [(ngModel)]="kpi">
                    <option *ngFor="let kpi of kpis" value="{{kpi.kpiid}}">
                      {{kpi.description}}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="text-center">
          <input type="button" class="btn btn-secondary input-block-level" value="Search" (click)="searchPolicies(endOfPage)">&nbsp;<input type="button" class="btn btn-secondary input-block-level" value="Reset" (click)="resetAdvancedSearch()" *ngIf="showReset">&nbsp;
          <input type="button" class="btn btn-secondary input-block-level" value="Export to Excel" (click)="exportToExcel()" *ngIf="showExport">
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-12">
    <div class="card card-secondary" *ngIf="showSearch">
      <div class="card-header">
        <h4 class="card-title w-100">
          <span>Search Results – {{searchRowCount === 1 ? searchRowCount + " Policy" : searchRowCount + " Policies"}}&nbsp;&nbsp;{{asOfDateMessage}}</span>
        </h4>
      </div>
      <div class="card-body">
        <div *ngIf="!showSpinner" style="overflow-x:scroll;">
          <div class="text-center"><h3>{{limitMessage}}</h3></div>
          <ag-grid-angular style="height: 500px;"
                           class="ag-theme-alpine"
                           [rowData]="searchRowData"
                           [columnDefs]="searchColumnDefs"
                           [defaultColDef]="defaultColumnDef"
                           rowSelection='single'
                           [suppressRowClickSelection]="true"
                           (gridReady)="onGridReady($event)"
                           [overlayNoRowsTemplate]="noRowsTemplate"
                           (rowClicked)="onRowClicked($event)"
                           [ag-grid-resize]>
          </ag-grid-angular>
        </div>
        <div class="row justify-content-center" *ngIf="showSpinner">
          <component-loading></component-loading>
        </div>
      </div>
    </div>
    <div class="card card-primary search-box" *ngIf="showDetail">
      <div class="card-header">
        <div class="row">
          <div class="col-md-10">
            <h4 class="card-title w-100">
              <span class="print-header">
                Policy Detail -
                {{selectedPolicy.policyNumber ? selectedPolicy.policyNumber + " - " + detailTitleName + "&nbsp;&nbsp;&nbsp;[ values as of " + selectedPolicy.asOfDate + " ]": "Loading..."}}
              </span>
            </h4>
          </div>
          <div class="col-md-2 my-auto text-right print-suppress">
            <i class="fas fa-2x fa-thin fa-print" (click)="printPage()" style="cursor:pointer" ngbTooltip="Print Policy Info" tooltipClass="lbig-tooltip"></i>&nbsp;&nbsp;&nbsp;&nbsp;
            <i class="fas fa-2x fa-times-circle" (click)="closeDetail()" style="cursor:pointer" ngbTooltip="Close this Policy" tooltipClass="lbig-tooltip"></i>
          </div>
        </div>
      </div>
      <div class="nav-container mb-3">
        <nav class="navbar navbar-expand-md navbar-light bg-light">
          <div class="container-fluid justify-content-center">
            <ul class="nav navbar-nav" id="detailMenu">
              <li class="nav-item">
                <a class="nav-link router-link-exact-active" id="PolicyDetails-tab" data-toggle="tab" href="#PolicyDetails" (click)="detailTabView('#PolicyDetails-tab')">Policy Info</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" id="Coverages-tab" data-toggle="tab" href="#Coverages" (click)="detailTabView('#Coverages-tab')">Coverage Info</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" id="PremiumHistory-tab" data-toggle="tab" href="#PremiumHistory" (click)="detailTabView('#PremiumHistory-tab')">Payment History</a>
              </li>
              <li class="nav-item" *ngIf="displayList('Withdrawal')">
                <a class="nav-link" id="WithdrawalHistory-tab" data-toggle="tab" href="#WithdrawalHistory" (click)="detailTabView('#WithdrawalHistory-tab')">Withdrawal History</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" id="StatusHistory-tab" data-toggle="tab" href="#StatusHistory" (click)="detailTabView('#StatusHistory-tab')">Status History</a>
              </li>
              <li class="nav-item" *ngIf="displayList('Underwriting')">
                <a class="nav-link" id="Underwriting-tab" data-toggle="tab" href="#UnderwritingStatus" (click)="detailTabView('#Underwriting-tab')">App Processing</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" id="Hierarchy-tab" data-toggle="tab" href="#Hierarchy" (click)="detailTabView('#Hierarchy-tab')">Hierarchy</a>
              </li>
              <li class="nav-item" *ngIf="displayList('BeneficiaryInfo')">
                <a class="nav-link" id="Beneficiary-tab" data-toggle="tab" href="#Beneficiary" (click)="detailTabView('#Beneficiary-tab')">Beneficiary Info</a>
              </li>
              <li class="nav-item" *ngIf="displayList('Documents')">
                <a class="nav-link" id="Documents-tab" data-toggle="tab" href="#Documents" (click)="detailTabView('#Documents-tab')">Documents</a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
      <div class="tab-content clearfix" id="tabDetailPanes">
        <!--BEGIN DETAILS-->
        <div class="tab-pane active" id="PolicyDetails">
          <div *ngIf="displayDetail('Standard')">
            <div class="card-body">
              <div *ngIf="selectedPolicy.policyNumber === '**ERROR**'" class="alert alert-danger text-center" role="alert">Error Loading Policy Information</div>
              <div class="row">
                <div class="col-md-8">
                  <div class="form-group">
                    <label>Product Plan</label>
                    <input type="text" class="form-control" readonly value='{{selectedPolicy.productPlan}}'>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label>Estimated Death Benefit</label>
                    <input type="text" class="form-control" readonly value='{{selectedPolicy.deathBenefit}}'>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label>Policy Number / Current Status</label>
                    <input type="text" class="form-control" readonly value='{{selectedPolicy.policyNumber}} - {{selectedPolicy.statusDesc}}'>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label>Agent</label>
                    <input type="text" class="form-control" readonly value='{{selectedPolicy.agentID}}'>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label>Agent Name</label>
                    <input type="text" class="form-control" readonly value='{{selectedPolicy.agentName}}'>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label>Submit Date</label>
                    <input type="text" class="form-control" readonly value='{{selectedPolicy.submitDate}}'>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label>Issue Date</label>
                    <input type="text" class="form-control" readonly value='{{selectedPolicy.issueDate}}'>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label>Paid To Date</label>
                    <input type="text" class="form-control" readonly value='{{selectedPolicy.paidToDate}}'>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label>Billing Mode</label>
                    <input type="text" class="form-control" readonly value='{{selectedPolicy.billingMode}}'>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label>Face Amount</label>
                    <input type="text" class="form-control" readonly value='{{selectedPolicy.faceAmount}}'>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label>Premium</label>
                    <input type="text" class="form-control" readonly value='{{selectedPolicy.premium}}'>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="accordion" id="clientInfoAccordion">

                    <div class="card card-secondary">

                      <div class="card-header" id="clientInfoHeading">

                        <h4 class="card-title w-100" (click)="toggleClientInfoAccordion()">
                          <a class="d-block w-100 collapsed" data-toggle="collapse" href="#clientInfoCollapse" aria-expanded="false">
                            Client Details&nbsp;&nbsp;<span><i class="{{getClientInfoAccordionArrowStyle()}}"></i></span>
                          </a>
                        </h4>
                      </div>
                      <div id="clientInfoCollapse" class="collapse" aria-labelledby="clientInfoHeading" data-parent="#clientInfoAccordion">
                        <div class="card-body">
                          <div class="row">
                            <div class="col-md-4" *ngIf="selectedPolicy.insuredName">
                              <div class="card card-secondary">
                                <div class="card-body">
                                  <div class="row center">
                                    <div class="col-md-12">
                                      <div class="form-group">
                                        <strong>Insured</strong>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-12 ml-3">
                                      <div class="info-box-text">{{selectedPolicy.insuredName}}</div>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-12 ml-3">
                                      <div class="info-box-text">{{selectedPolicy.insuredAddress}}</div>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-12 ml-3">
                                      <div class="info-box-text">Phone:&nbsp;{{ selectedPolicy.insuredPhone | phone }}</div>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-12 ml-3">
                                      <div class="info-box-text">DOB:&nbsp;{{selectedPolicy.insuredDob}}</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-4" *ngIf="selectedPolicy.ownerName">
                              <div class="card card-secondary">
                                <div class="card-body">
                                  <div class="row">
                                    <div class="col-md-12">
                                      <div class="form-group">
                                        <strong>Owner</strong>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-12 ml-3">
                                      <div class="info-box-text">{{selectedPolicy.ownerName}}</div>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-12 ml-3">
                                      <div class="info-box-text">{{selectedPolicy.ownerAddress}}</div>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-12 ml-3">
                                      <div class="info-box-text">Phone:&nbsp;{{ selectedPolicy.ownerPhone | phone }}</div>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-12 ml-3">
                                      <div class="info-box-text">DOB:&nbsp;{{selectedPolicy.ownerDob}}</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-4" *ngIf="selectedPolicy.payorName">
                              <div class="card card-secondary">
                                <div class="card-body">
                                  <div class="row">
                                    <div class="col-md-12">
                                      <div class="form-group">
                                        <strong>Payor</strong>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-12 ml-3">
                                      <div class="info-box-text">{{selectedPolicy.payorName}}</div>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-12 ml-3">
                                      <div class="info-box-text">{{selectedPolicy.payorAddress}}</div>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-12 ml-3">
                                      <div class="info-box-text">Phone:&nbsp;{{ selectedPolicy.payorPhone | phone }}</div>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-12 ml-3">
                                      <div class="info-box-text">DOB:&nbsp;{{selectedPolicy.payorDob}}</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="displayDetail('Annuity')">
            <div class="card-body">
              <div *ngIf="selectedPolicy.policyNumber === '**ERROR**'" class="alert alert-danger text-center" role="alert">Error Loading Policy Information</div>
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label>Contract Number / Current Status</label>
                    <input type="text" class="form-control" readonly value='{{selectedPolicy.policyNumber}} - {{selectedPolicy.statusDesc}}'>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label>Product</label>
                    <input type="text" class="form-control" readonly value='{{selectedPolicy.productPlan}}'>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label>Tax Status</label>
                    <input type="text" class="form-control" readonly value='{{selectedPolicy.taxStatus}}'>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label>Original Issue Date</label>
                    <input type="text" class="form-control" readonly value='{{selectedPolicy.issueDate}}'>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label>Agent Name</label>
                    <input type="text" class="form-control" readonly value='{{selectedPolicy.agentName}}'>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label>Agent Number</label>
                    <input type="text" class="form-control" readonly value='{{selectedPolicy.agentID}}'>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label>Issue Age (Annuitant)</label>
                    <input type="text" class="form-control" readonly value='{{selectedPolicy.age}}'>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label>Current Guaranteed Period End</label>
                    <input type="text" class="form-control" readonly value='{{selectedPolicy.guaranteePeriodEnd}}'>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label>Renewal Date</label>
                    <input type="text" class="form-control" readonly value='{{selectedPolicy.renewalDate}}'>
                  </div>
                </div>
              </div>
              <div *ngIf="selectedPolicy.annuityType == 'MYGA'">
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Initial Premium</label>
                      <input type="text" class="form-control" readonly value='{{selectedPolicy.initialPremium}}'>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Current Interest Rate</label>
                      <input type="text" class="form-control" readonly value='{{selectedPolicy.currentInterestRate}}'>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Current Account Value</label>
                      <input type="text" class="form-control" readonly value='{{selectedPolicy.currentAccountValue}}'>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Surrender Charges</label>
                      <input type="text" class="form-control" readonly value='{{selectedPolicy.surrenderCharges}}'>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>MVA</label>
                      <input type="text" class="form-control" readonly value='{{selectedPolicy.mva}}'>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Current Surrender Value</label>
                      <input type="text" class="form-control" readonly value='{{selectedPolicy.currentSurrenderValue}}'>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Premiums Added</label>
                      <input type="text" class="form-control" readonly value='{{selectedPolicy.premiumAdded}}'>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Cumulative Interest Earnings</label>
                      <input type="text" class="form-control" readonly value='{{selectedPolicy.cumulativeInterestEarnings}}'>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Cumulative Withdrawals Taken</label>
                      <input type="text" class="form-control" readonly value='{{selectedPolicy.cumulativeWithdrawalsTaken}}'>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Penalty Free Withdrawal Amount Remaining</label>
                      <input type="text" class="form-control" readonly value='{{selectedPolicy.penaltyFreeWithdrawalAmountRemaining}}'>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>12/31 Prior Year Account Value</label>
                      <input type="text" class="form-control" readonly value='{{selectedPolicy.lastYearAccountValue}}'>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Guaranteed Minimum Surrender Value</label>
                      <input type="text" class="form-control" readonly value='{{selectedPolicy.guaranteeMinimumSurrenderValue}}'>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="selectedPolicy.annuityType == 'FIA'">
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Initial Premium</label>
                      <input type="text" class="form-control" readonly value='{{selectedPolicy.initialPremium}}'>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Fixed Account Interest Rate</label>
                      <input type="text" class="form-control" readonly value='{{selectedPolicy.fixedInterestRate}}'>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Current Account Value</label>
                      <input type="text" class="form-control" readonly value='{{selectedPolicy.currentAccountValue}}'>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Surrender Charges</label>
                      <input type="text" class="form-control" readonly value='{{selectedPolicy.surrenderCharges}}'>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>MVA</label>
                      <input type="text" class="form-control" readonly value='{{selectedPolicy.mva}}'>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Current Surrender Value</label>
                      <input type="text" class="form-control" readonly value='{{selectedPolicy.currentSurrenderValue}}'>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Fixed Account Value Last Anniversary</label>
                      <input type="text" class="form-control" readonly value='{{selectedPolicy.fixedLastAnniversaryAccountValue}}'>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Indexed Account Value Last Anniversary</label>
                      <input type="text" class="form-control" readonly value='{{selectedPolicy.indexedLastAnniversaryAccountValue}}'>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Total Account Value Last Anniversary</label>
                      <input type="text" class="form-control" readonly value='{{selectedPolicy.totalLastAnniversaryAccountValue}}'>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Premiums Added</label>
                      <input type="text" class="form-control" readonly value='{{selectedPolicy.fixedPremiumAdded}}'>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Premium Bonus Earned</label>
                      <input type="text" class="form-control" readonly value='{{selectedPolicy.premiumBonusAdded}}'>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Interest Earned - Fixed Account</label>
                      <input type="text" class="form-control" readonly value='{{selectedPolicy.fixedInterestAdded}}'>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Cummulative Withdrawals Taken</label>
                      <input type="text" class="form-control" readonly value='{{selectedPolicy.cumulativeWithdrawalsTaken}}'>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Penalty Free Withdrawal Amount Remaining</label>
                      <input type="text" class="form-control" readonly value='{{selectedPolicy.penaltyFreeWithdrawalAmountRemaining}}'>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Guaranteed Minimum Surrender Value</label>
                      <input type="text" class="form-control" readonly value='{{selectedPolicy.guaranteeMinimumSurrenderValue}}'>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="selectedPolicy.annuityType == 'SPIA'">
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Initial Premium</label>
                      <input type="text" class="form-control" readonly value='{{selectedPolicy.initialPremium}}'>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Plan Type</label>
                      <input type="text" class="form-control" readonly value='{{selectedPolicy.planType}}'>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Joint Life % Tied to Owner</label>
                      <input type="text" class="form-control" readonly value='{{selectedPolicy.jointLifeTiedToOwner}}'>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Cost Basis</label>
                      <input type="text" class="form-control" readonly value='{{selectedPolicy.costBasis}}'>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Income Start Date</label>
                      <input type="text" class="form-control" readonly value='{{selectedPolicy.incomeStartDate}}'>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Income End Date</label>
                      <input type="text" class="form-control" readonly value='{{selectedPolicy.incomeEndDate}}'>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Income Payment Amount</label>
                      <input type="text" class="form-control" readonly value='{{selectedPolicy.incomePayment}}'>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Income Payment Frequency</label>
                      <input type="text" class="form-control" readonly value='{{selectedPolicy.incomePaymentFrequency}}'>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Exclusion Ratio</label>
                      <input type="text" class="form-control" readonly value='{{selectedPolicy.exclusionRatio}}'>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Federal Tax Withheld</label>
                      <input type="text" class="form-control" readonly value='{{selectedPolicy.fedTaxWithheld}}'>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>State Taxes Withheld</label>
                      <input type="text" class="form-control" readonly value='{{selectedPolicy.stateTaxWithheld}}'>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Taxable Amount of Payment</label>
                      <input type="text" class="form-control" readonly value='{{selectedPolicy.taxablePaymentAmount}}'>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row" *ngIf="selectedPolicy.taxStatus != 'NQ' && (selectedPolicy.annuityType === 'MYGA' || selectedPolicy.annuityType === 'FIA')">
                <div class="col-md-12">
                  <div class="accordion" id="annuityRMDAccordion">
                    <div class="card card-secondary">

                      <div class="card-header" id="annuityRMDHeading">

                        <h4 class="card-title w-100" (click)="toggleRmaAccordion()">
                          <a class="d-block w-100 collapsed" data-toggle="collapse" href="#annuityRMDCollapse" aria-expanded="false">
                            Required Minimum Distribution&nbsp;&nbsp;<span><i class="{{getRmaAccordionArrowStyle()}}"></i></span>
                          </a>
                        </h4>
                      </div>
                      <div id="annuityRMDCollapse" class="collapse" aria-labelledby="annuityRMDHeading" data-parent="#annuityRMDAccordion">
                        <div class="card-body">
                          <div class="row">
                            <div class="col-md-4">
                              <div class="form-group">
                                <label>RMD Eligibility Date</label>
                                <input type="text" class="form-control" readonly value='{{selectedPolicy.rmdEligibilityDate}}'>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="form-group">
                                <label>Required Distributions this calendar year</label>
                                <input type="text" class="form-control" readonly value='{{selectedPolicy.rmdRequiredDistributions}}'>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="form-group">
                                <label>Distributions Taken this calendar year</label>
                                <input type="text" class="form-control" readonly value='{{selectedPolicy.rmdDistributionsTaken}}'>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-4">
                              <div class="form-group">
                                <label>Recurring RMD Last Distribution Date</label>
                                <input type="text" class="form-control" readonly value='{{selectedPolicy.rmdLastDistributionDate}}'>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="form-group">
                                <label>Payment Amount</label>
                                <input type="text" class="form-control" readonly value='{{selectedPolicy.rmdPaymentAmount}}'>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="form-group">
                                <label>Payment Frequency</label>
                                <input type="text" class="form-control" readonly value='{{selectedPolicy.rmdPaymentFrequency}}'>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-4">
                              <div class="form-group">
                                <label>Payment Type</label>
                                <input type="text" class="form-control" readonly value='{{selectedPolicy.rmdPaymentType}}'>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" *ngIf="selectedPolicy.annuityType == 'FIA' && allocationRowData.length > 0">
                <div class="col-md-12">
                  <div class="accordion" id="annuityAllocationAccordion">

                    <div class="card card-secondary">

                      <div class="card-header" id="annuityAllocationHeading">

                        <h4 class="card-title w-100" (click)="toggleAllocationAccordion()">
                          <a class="d-block w-100 collapsed" data-toggle="collapse" href="#annuityAllocationCollapse" aria-expanded="false">
                            Allocations&nbsp;&nbsp;<span><i class="{{getAllocationAccordionArrowStyle()}}"></i></span>
                          </a>
                        </h4>
                      </div>
                      <div id="annuityAllocationCollapse" class="collapse" aria-labelledby="annuityAllocationHeading" data-parent="#annuityAllocationAccordion">
                        <div class="card-body">
                          <div class="row justify-content-center">
                            <div class="col-9">
                              <ag-grid-angular style="height: 300px;"
                                               class="ag-theme-alpine"
                                               [rowData]="allocationRowData"
                                               [columnDefs]="allocationColumnDefs"
                                               [defaultColDef]="defaultColumnDef"
                                               rowSelection='none'
                                               (gridReady)="onGridReady7($event)"
                                               [suppressRowClickSelection]="true"
                                               [overlayNoRowsTemplate]="noRowsTemplate"
                                               [ag-grid-resize]>
                              </ag-grid-angular>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="accordion" id="annuityClientInfoAccordion">

                    <div class="card card-secondary">

                      <div class="card-header" id="annuityClientInfoHeading">

                        <h4 class="card-title w-100" (click)="toggleClientInfoAccordion()">
                          <a class="d-block w-100 collapsed" data-toggle="collapse" href="#annuityClientInfoCollapse" aria-expanded="false">
                            Client Details&nbsp;&nbsp;<span><i class="{{getClientInfoAccordionArrowStyle()}}"></i></span>
                          </a>
                        </h4>
                      </div>
                      <div id="annuityClientInfoCollapse" class="collapse" aria-labelledby="annuityClientInfoHeading" data-parent="#annuityClientInfoAccordion">
                        <div class="card-body">
                          <div class="row">
                            <div class="col-md-4" *ngIf="selectedPolicy.annuitantName">
                              <div class="card card-secondary">
                                <div class="card-body">
                                  <div class="row conter">
                                    <div class="col-md-12">
                                      <div class="form-group">
                                        <strong>Annuitant</strong>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-12 ml-3">
                                      <div class="info-box-text">{{selectedPolicy.annuitantName}}</div>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-12 ml-3">
                                      <div class="info-box-text">{{selectedPolicy.annuitantAddress}}</div>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-12 ml-3">
                                      <div class="info-box-text">Phone:&nbsp;{{ selectedPolicy.annuitantPhone | phone }}</div>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-12 ml-3">
                                      <div class="info-box-text">DOB:&nbsp;{{selectedPolicy.annuitantDob}}</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-4" *ngIf="selectedPolicy.ownerName">
                              <div class="card card-secondary">
                                <div class="card-body">
                                  <div class="row">
                                    <div class="col-md-12">
                                      <div class="form-group">
                                        <strong>Owner</strong>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-12 ml-3">
                                      <div class="info-box-text">{{selectedPolicy.ownerName}}</div>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-12 ml-3">
                                      <div class="info-box-text">{{selectedPolicy.ownerAddress}}</div>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-12 ml-3">
                                      <div class="info-box-text">Phone:&nbsp;{{ selectedPolicy.ownerPhone | phone }}</div>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-12 ml-3">
                                      <div class="info-box-text">DOB:&nbsp;{{selectedPolicy.ownerDob}}</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-4" *ngIf="selectedPolicy.jointOwnerName">
                              <div class="card card-secondary">
                                <div class="card-body">
                                  <div class="row">
                                    <div class="col-md-12">
                                      <div class="form-group">
                                        <strong>Joint Owner</strong>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-12 ml-3">
                                      <div class="info-box-text">{{selectedPolicy.jointOwnerName}}</div>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-12 ml-3">
                                      <div class="info-box-text">{{selectedPolicy.jointOwnerAddress}}</div>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-12 ml-3">
                                      <div class="info-box-text">Phone:&nbsp;{{ selectedPolicy.jointOwnerPhone | phone }}</div>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-12 ml-3">
                                      <div class="info-box-text">DOB:&nbsp;{{selectedPolicy.jointOwnerDob}}</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--END DETAILS-->
        <div class="tab-pane" id="Coverages">
          <div class="row justify-content-center">
            <div class="col-12">
              <ag-grid-angular style="height: 300px;"
                               class="ag-theme-alpine"
                               [rowData]="coverageRowData"
                               [columnDefs]="coverageColumnDefs"
                               [defaultColDef]="defaultColumnDef"
                               rowSelection='none'
                               (gridReady)="onGridReady1($event)"
                               [suppressRowClickSelection]="true"
                               [overlayNoRowsTemplate]="noRowsTemplate"
                               [ag-grid-resize]>
              </ag-grid-angular>
            </div>
          </div>
        </div>
        <div class="tab-pane justify-content-center" id="PremiumHistory">
          <div class="row justify-content-center">
            <div class="col-6">
              <ag-grid-angular style="height: 300px;"
                               class="ag-theme-alpine"
                               [rowData]="paymentRowData"
                               [columnDefs]="paymentColumnDefs"
                               [defaultColDef]="defaultColumnDef"
                               rowSelection='none'
                               (gridReady)="onGridReady2($event)"
                               [suppressRowClickSelection]="true"
                               [overlayNoRowsTemplate]="noRowsTemplate"
                               [ag-grid-resize]>
              </ag-grid-angular>
            </div>
          </div>
        </div>

        <div class="tab-pane justify-content-center" id="WithdrawalHistory">
          <div class="row justify-content-center">
            <div class="col-8">
              <ag-grid-angular style="height: 300px;"
                               class="ag-theme-alpine"
                               [rowData]="withdrawalRowData"
                               [columnDefs]="withdrawalColumnDefs"
                               [defaultColDef]="defaultColumnDef"
                               rowSelection='none'
                               (gridReady)="onGridReady9($event)"
                               [suppressRowClickSelection]="true"
                               [overlayNoRowsTemplate]="noRowsTemplate"
                               [ag-grid-resize]>
              </ag-grid-angular>
            </div>
          </div>
        </div>

        <div class="tab-pane justify-content-center" id="StatusHistory">
          <div class="row justify-content-center">
            <div class="col-4">
              <ag-grid-angular style="height: 300px;"
                               class="ag-theme-alpine"
                               [rowData]="statusHistoryRowData"
                               [columnDefs]="statusHistoryColumnDefs"
                               [defaultColDef]="defaultColumnDef"
                               rowSelection='none'
                               (gridReady)="onGridReady3($event)"
                               [suppressRowClickSelection]="true"
                               [overlayNoRowsTemplate]="noRowsTemplate"
                               [ag-grid-resize]>
              </ag-grid-angular>
            </div>
          </div>
        </div>
        <div class="tab-pane justify-content-center" id="UnderwritingStatus" *ngIf="displayList('Underwriting')">
          <div class="row justify-content-center">
            <div class="col-8">
              <ag-grid-angular style="height: 300px;"
                               class="ag-theme-alpine"
                               [rowData]="underwritingStatusRowData"
                               [columnDefs]="underwritingStatusColumnDefs"
                               [defaultColDef]="defaultColumnDef"
                               rowSelection='none'
                               (gridReady)="onGridReady4($event)"
                               [suppressRowClickSelection]="true"
                               [overlayNoRowsTemplate]="noRowsTemplate"
                               [ag-grid-resize]>
              </ag-grid-angular>
            </div>
          </div>
        </div>
        <div class="tab-pane justify-content-center" id="Hierarchy">
          <div class="row justify-content-center">
            <div class="col-8">
              <ag-grid-angular style="height: 300px;"
                               class="ag-theme-alpine"
                               [rowData]="hierarchyRowData"
                               [columnDefs]="hierarchyColumnDefs"
                               [defaultColDef]="defaultColumnDef"
                               rowSelection='none'
                               (gridReady)="onGridReady5($event)"
                               [suppressRowClickSelection]="true"
                               [overlayNoRowsTemplate]="noRowsTemplate"
                               [ag-grid-resize]>
              </ag-grid-angular>
            </div>
          </div>
        </div>
        <div class="tab-pane justify-content-center" id="Beneficiary" *ngIf="displayList('BeneficiaryInfo')">
          <div class="row justify-content-center">
            <div class="col-4">
              <div class="form-group">
                <label>Info</label>
                <input type="text" class="form-control" readonly value='{{beneficiaryInfoRowData[0].info}}'>
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-4">
              <div class="form-group">
                <label>Irrevocable Assignment</label>
                <input type="text" class="form-control" readonly value='{{beneficiaryInfoRowData[0].irAssign | yesNo}}'>
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-8">
              <ag-grid-angular style="height: 300px;"
                               class="ag-theme-alpine"
                               [rowData]="beneficiaryRowData"
                               [columnDefs]="beneficiaryColumnDefs"
                               [defaultColDef]="defaultColumnDef"
                               rowSelection='none'
                               (gridReady)="onGridReady6($event)"
                               [suppressRowClickSelection]="true"
                               [overlayNoRowsTemplate]="noRowsTemplate"
                               [ag-grid-resize]>
              </ag-grid-angular>
            </div>
          </div>
        </div>
        <div class="tab-pane justify-content-center" id="Documents" *ngIf="displayList('Documents')">
          <div class="row justify-content-center">
            <div class="col-8">
              <ag-grid-angular style="height: 300px;"
                               class="ag-theme-alpine"
                               [rowData]="documentsRowData"
                               [columnDefs]="documentsColumnDefs"
                               [defaultColDef]="defaultColumnDef"
                               rowSelection='none'
                               (gridReady)="onGridReady8($event)"
                               [suppressRowClickSelection]="true"
                               [overlayNoRowsTemplate]="noRowsTemplate"
                               [ag-grid-resize]>
              </ag-grid-angular>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div #endOfPage id="endOfPage">
</div>
