import { Component, HostListener, OnInit } from '@angular/core';
import { PolicyRoute } from '../../../models/policyRoute';
import { ColDef, GridApi } from 'ag-grid-enterprise';
import { OnPremStorageService } from '../../../services/onprem-storage';
import { SiteParameterService } from '../../../services/siteparameter.service';
import { DateComparator } from '../../../common/helpers';
import { Observable, Subscription } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { Profile } from '../../../models/profile';
import { AgentGrid } from '../../../models/agentGrid';
import { ColSpanParams, GetContextMenuItemsParams, Grid, GridOptions, MenuItemDef, RowSelectedEvent, SelectionChangedEvent } from 'ag-grid-community';
import '../../../../assets/css/routelist.css';
import { param, trim } from 'jquery';
import { Policy } from '../../../models/policy';
import { Agent } from '../../../models/agent';
import { AgentLink } from '../../../models/agentLink';
import { gridColumn } from '../../../models/gridColumn';
import { GridTooltipComponent } from '../../components/grid-tooltip/grid-tooltip.component';
import { currencyFormatter, formatNumber } from '../../../common/ag-grid-currencyFormatter';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-collectionsPage',
  templateUrl: './collections.component.html',
  styles: [
  ]
})
export class CollectionsPageComponent implements OnInit {
  public activeTab: string = 'RouteList';
  public searchTerm: string = '';
  //public routeListRowHeight = 40;
  public routeListRowHeight = 80;
  public enableRouteListSearch: boolean = true;
  public showCollectionParameters: boolean = true;
  public showSubmitBatchButton: boolean = false;
  public showRouteList: boolean = true;
  public showPendingCollectionsList: boolean = true;
  public showCollectingManagerList: boolean = false;
  public showCollectionForm: boolean = false;
  public showLapsedCollectionForm: boolean = false;
  public enablePolicyNumberSearch: boolean = true;
  public showPolicyFound: boolean = false;
  public showPolicyNotFound: boolean = false;
  public showLapseCollectionInfo: boolean = false;
  public showGroupCollectionForm: boolean = false;
  public showSequenceNumberForm: boolean = false;
  public showSequenceContactForm: boolean = false;
  public showLapseAlert: boolean = false;
  public lapseAlertMessage: string = '';
  public showNoPremiumCollected: boolean = false;
  public showNoGroupPremiumCollected: boolean = false;
  public showSuspenseAlert: boolean = false;
  public showLoanBalanceAlert: boolean = false;
  public disableSaveCollectionButton: boolean = true;
  public disableSaveLapsedCollectionButton: boolean = true;
  public disableSaveGroupCollectionButton: boolean = true;
  public disableSubmitBatchButton: boolean = true;
  //public filterBillingMethod

  private channelSubscription: Subscription;

  public routeList$?: Observable<PolicyRoute[]>
  public pendingCollectionsRowData: [] = [];
  public batchHistoryRowData: [] = [];

  public screenWidthForDesktops: number = 1920;
  public pendingCollectionsListHeaderHeight: number = 75;

  private selectedTab: string = "Route List";
  private topRowID: number = 0;

  public showBillFormListCheckboxes: boolean = true;

  public agentCollectCount: number = 0;
  private autoPremiumLoanCount: number = 0;
  private bankDraftCount: number = 0;
  private creditCardCount: number = 0;
  private directBillCount: number = 0;
  private groupListBillCount: number = 0;

  public agentCollectCheckValue: string = "X";
  private autoPremiumLoanCheckValue: string = "4";
  private bankDraftCheckValue: string = "2";
  private creditCardCheckValue: string = "C";
  private directBillCheckValue: string = "1";
  private groupListBillCheckValue: string = "3";

  public policyNumber: string = "";
  public policyAgentID: string = "";
  public sequenceNo: string = "";
  public routeSequenceId: string = "";
  public amountCollected: number = 0.00;
  public modalPremium: number = 0.00;
  public premiumDue: number = 0.00;
  public monthsPaid: number = 0;
  public monthlyPremiumPaid: number = 0.00;
  public minimumMonths: number = 0;
  public groupModalPremium: number = 0.00;
  public groupPremiumDue: number = 0.00;
  public premiumGroupCollected: number = 0.00;
  public monthsGroupPaid: number = 0;
  public currentGroupCommRate: number = 0.00;
  //public estimatedGroupCommission: number = 0.00;

  private collectionId!: string;
  private collectionTypeId: number = 1;
  private regionId: number = 0;
  private collectionAgentId: string = "";
  private paidToDate: string = "";
  private cashAmount: number = 0.00;
  private isUseSuspenseChecked: boolean = false;
  private isMakeLoanPaymentChecked: boolean = false;
  private isMakeOverPaymentChecked: boolean = false;
  private availableSuspenseAmount: number = 0.00;
  private suspenseAmount: number = 0.00;
  private overpaymentAmount: number = 0.00;
  private currentLoanBalance: number = 0.00;
  private loanPaymentAmount: number = 0.00;
  private insuredName: string = "";
  private payorName: string = "";
  public premiumCollected: number = 0.00;
  public currentCommRate: number = 0.00;
  //public estimatedCommission: number = 0.00;

  public contactName: string = "";
  public contactAddressLine1: string = "";
  public contactAddressLine2: string = "";
  public contactCity: string = "";
  public contactState: string = "";
  public contactZip: string = "";
  public contactEmailAddress: string = "";
  public contactPhone: string = "";
  public contactNotes: string = "";

  private gridApi1?: GridApi;
  private gridApi2?: GridApi;
  private gridApi3?: GridApi;
  private gridApi4?: GridApi;
  private gridApi5?: GridApi;
  private gridApi6?: GridApi;
  private gridApi7?: GridApi;
  private gridApi8?: GridApi;
  private gridApi9?: GridApi;
  private gridc: any;
  private gridColumnApi1: any;
  private gridColumnApi2: any;
  private gridColumnApi3: any;
  private gridColumnApi4: any;
  private gridColumnApi5: any;
  private gridColumnApi6: any;
  private gridColumnApi7: any;
  private gridColumnApi8: any;
  private gridColumnApi9: any;
  public noRowsTemplate: string = "";
  public groupDefaultExpanded = 1;
  public profile: Profile = <Profile>{};
  public collectingAgents: Agent[] = [];
  public agents: Agent[] = [];
  public selectedAgentId: string = "";
  public selectedCollectingAgentId: string = "";
  public showPolicyDetail: boolean = false;
  public selectedPolicy: Policy = <Policy>{};
  public coverageRowData: [] = [];
  public paymentRowData: [] = [];
  public statusHistoryRowData: [] = [];
  public underwritingStatusRowData: [] = [];
  public allocationRowData: [] = [];
  public hierarchyRowData: [] = [];
  public beneficiaryInfoRowData: [] = [];
  public beneficiaryRowData: [] = [];
  public clientInfoAccordionExpanded: boolean = false;

  public innerWidth: any;

  // Temporary Setup properties
  //public testText$?: Observable<number>;
  //public channelId: number = 3;

  columnRoutleListDefs!: ColDef[];
  columnPendingCollectionsDefs!: ColDef[];
  columnBatchHistoryDefs!: ColDef[];

  //public columnPendingCollectionsDefs = [
  //  { field: 'sequenceNo', headerName: 'Route Seq #', minWidth: 75 },
  //  {
  //    minWidth: 50, maxWidth: 50, cellRenderer: (params: any) => {
  //      return this.getPendingCollectionsListGridIcons(params.data);
  //    },
  //    tooltipValueGetter: (params: any) => {
  //      return this.getPendingCollectionsListGridTooltip(params.data);
  //    },
  //  },
  //  { field: 'insuredName', headerName: 'Insured', minWidth: 150 },
  //  { field: 'policyNum', headerName: 'Policy Number', minWidth: 150 },
  //  { field: 'agentID', headerName: 'Agent' },
  //  { field: 'premiumAmt', headerName: 'Premium', valueFormatter: currencyFormatter },
  //  { field: 'overpaymentAmt', headerName: 'Overpayment Amt', valueFormatter: currencyFormatter },
  //  { field: 'agentCollectAmt', headerName: 'Agent Collect/Due', valueFormatter: currencyFormatter },
  //  { field: 'appliedSuspenseAmt', headerName: 'Applied Suspense', valueFormatter: currencyFormatter },
  //  { field: 'loanPaymentAmt', headerName: 'Loan Payment', valueFormatter: currencyFormatter },
  //  { field: 'totalCollectionAmt', headerName: 'Total Collection', valueFormatter: currencyFormatter },
  //  { field: 'estCommAmt', headerName: 'Est. Commission Amt', valueFormatter: currencyFormatter },
  //  { field: 'estNetAmt', headerName: 'Est. Net Draft', valueFormatter: currencyFormatter }
  //];
  //public columnBatchHistoryDefs = [
  //  { field: 'batchNum', headerName: 'Batch No.' },
  //  { field: 'agentID', headerName: 'Agent' },
  //  { field: 'premiumAmt', headerName: 'Premium' },
  //  { field: 'overpaymentAmt', headerName: 'Overpayment' },
  //  { field: 'loanPaymentAmt', headerName: 'Loan Repayment' },
  //  { field: 'agentCollectAmt', headerName: 'Agent Collect/Due' },
  //  { field: 'appliedSuspenseAmt', headerName: 'Applied Suspense' },
  //  { field: 'totalCollectionAmt', headerName: 'Total Collection' },
  //  { field: 'estCommAmt', headerName: 'Est. Commission' },
  //  { field: 'estNetAmt', headerName: 'Est. Net Draft' },
  //  { field: 'createDTM', headerName: 'Batch Date', comparator: DateComparator },
  //  { field: 'collectionBatchStatus', headerName: 'Status' },
  //  { field: 'statusUpdateDTM', headerName: 'Status Date', comparator: DateComparator }
  //];

  coverageColumnDefs = [
    { field: 'coverageType', headerName: 'Coverage', minWidth: 125, maxWidth: 125 },
    { field: 'coveragePlanCode', headerName: 'Plan Code', minWidth: 125, maxWidth: 125 },
    { field: 'coveragePlanDesc', headerName: 'Plan Description', minWidth: 300 },
    { field: 'coveredPerson', headerName: 'Insured', minWidth: 100 },
    { field: 'ageAtIssue', headerName: 'Issue Age', minWidth: 125, maxWidth: 125 },
    { field: 'benefitAmt', headerName: 'Benefit Amount', minWidth: 125, maxWidth: 150 },
    { field: 'expiryDate', headerName: 'Expires', minWidth: 125, maxWidth: 125, comparator: DateComparator },
    { field: 'payUpDate', headerName: 'Paid To', minWidth: 125, maxWidth: 125, comparator: DateComparator }
  ];
  paymentColumnDefs = [
    { field: 'paymentDate', headerName: 'Payment Date', minWidth: 100, comparator: DateComparator },
    { field: 'postDate', headerName: 'Post Date', minWidth: 100, comparator: DateComparator },
    { field: 'premiumAmt', headerName: 'Premuim', minWidth: 100 },
    { field: 'source', headerName: 'Source', minWidth: 100 }
  ];
  statusHistoryColumnDefs = [
    { field: 'status', headerName: 'Status', minWidth: 100 },
    { field: 'date', headerName: 'Status Date', minWidth: 100, comparator: DateComparator },
  ];
  hierarchyColumnDefs = [
    { field: 'agentID', headerName: 'Agent ID', minWidth: 100, maxWidth: 125 },
    { field: 'agentName', headerName: 'Agent Name', minWidth: 275 },
    { field: 'level', headerName: 'Level', minWidth: 100, maxWidth: 125 },
    { field: 'commissionPct', headerName: 'Commission %', minWidth: 125, maxWidth: 150 },
    { field: 'agentType', headerName: 'Agent Type', minWidth: 100 }
  ];
  beneficiaryColumnDefs = [
    { field: 'name', headerName: 'Name', minWidth: 300 },
    { field: 'relationship', headerName: 'Relationship', minWidth: 175 },
    { field: 'split', headerName: 'Split %', minWidth: 125, maxWidth: 100 },
    { field: 'type', headerName: 'Type', minWidth: 175 }
  ];
  public defaultRoutleListColumnDef = {
    sortable: true,
    resizable: true,
  };
  public defaultColumnDef = {
    sortable: true,
    resizable: true,
  };

  public autoGroupColumnDef: ColDef = {
    headerName: '',
    cellRendererParams: {
      suppressCount: true,
    },
    maxWidth: 20,
    colSpan: (params: ColSpanParams) => {
      if (params.data) {
        return 1;
      } else {
        return 9;
      }
    },
    headerClass: (params: any) => {
      if (this.innerWidth >= this.screenWidthForDesktops) {
        return '';
      } else {
        return 'commission-header';
      }
    } 
  };

  public gridOptions: GridOptions = {
    context: this,
    tooltipShowDelay: 0
  }

  public gridOptions2: GridOptions = {
    context: this,
    tooltipShowDelay: 0
  }

  public gridOptions3: GridOptions = {
    context: this,
  }

  constructor(public onPremService: OnPremStorageService, private siteParmService: SiteParameterService) {
    this.noRowsTemplate = this.siteParmService.getGridNoResults();
    this.channelSubscription = this.siteParmService.getChannelUpdate().subscribe
      (message => {
        this.profile = siteParmService.getStaticProfile();
        this.selectedAgentId = "";

        //Old Method and Switch
        //this.getAgentLists();

        //switch (this.selectedTab) {
        //  case 'Pending Collections':
        //    this.getPendingCollections();
        //    break;

        //  case 'Batch History':
        //    this.getBatchHistory();
        //    break;

        //  default:
        //    this.refreshRouteList();
        //};

        //New Method
        this.getAgentList();

 
        this.lapseAlertMessage = this.profile.channelPreference === 'Home Service' ?
          "Months Paid must equal or exceed the Months Due" : "ALERT >> Must Collect or Lapse Will Occur << ALERT";
      });

    //TODO: TEST TO SEE IF TotalCollectedAmount CAN BE SAVED AND PRESENTED ELSEWHERE (AWAY FROM ROUTELIST TAB)
    //this.getTotalCollectedAmount();
  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.getRoutleListColumnDefs();
    this.getPendingCollectionsColumnDefs();
    this.getBatchHistoryColumnDefs();
    this.profile = this.siteParmService.getStaticProfile();

    //Old Method
    //this.getAgentLists();
    //New Method
    this.getAgentList();

    this.refreshRouteList();
    $('#menu li a').click(function () {
      $('#menu').find("*").removeClass("router-link-exact-active");
      $(this).addClass('router-link-exact-active');
    });
    this.lapseAlertMessage = this.profile.channelPreference === 'Home Service' ?
      "Months Paid must equal or exceed the Months Due" : "ALERT >> Must Collect or Lapse Will Occur << ALERT";
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = window.innerWidth;
    this.getRoutleListColumnDefs();
    this.getPendingCollectionsColumnDefs();
    this.getBatchHistoryColumnDefs(); 

    if (this.selectedTab === 'Pending Collections') {
      this.refreshPendingCollectionsList();
    }
  }

  getRoutleListColumnDefs(): void {
    if (this.innerWidth >= this.screenWidthForDesktops) {
      this.columnRoutleListDefs = [
        { field: 'sequenceNoGroupText', rowGroup: true, hide: true },
        //{
        //  headerName: "Menu",
        //  cellRenderer: (params: any) => {
        //    return '<button type="button" onclick="console.log(' + params.data + ')" > Menu </button>'
        //  }
        //},
        {
          minWidth: 50, maxWidth: 75, cellRenderer: (params: any) => {
            return this.getRoutleListGridIcons(params.data);
          },
          tooltipValueGetter: (params: any) => {
            return this.getRoutleListGridTooltip(params.data);
          },
        },
        { field: 'insuredName', headerName: 'Insured', minWidth: 100},
        { field: 'payorName', headerName: 'Payor', minWidth: 100, },
        { field: 'policyNum', headerName: 'Policy #', minWidth: 100, maxWidth: 125 },
        { field: 'agentID', headerName: 'Agent', minWidth: 110, maxWidth: 110 },
        { field: 'paidToDate', headerName: 'Paid-To Date', minWidth: 110, maxWidth: 110 },
        { field: 'modalPremium', headerName: 'Modal Prem', valueFormatter: currencyFormatter, minWidth: 120, maxWidth: 120 },
        { field: 'monthsDue', headerName: 'Months Due', minWidth: 100, maxWidth: 100 },
        { field: 'premiumDue', headerName: 'Prem Due', minWidth: 100, maxWidth: 100, valueFormatter: currencyFormatter },
        { field: 'availableSuspense', headerName: 'Avail Suspense', minWidth: 120, maxWidth: 120, valueFormatter: currencyFormatter },
        { field: 'currentLoanBalance', headerName: 'Loan Balance', minWidth: 120, maxWidth: 120, valueFormatter: currencyFormatter },
        { field: 'collectionAmt', headerName: 'Prem Collected', minWidth: 120, maxWidth: 120, valueFormatter: currencyFormatter }

        //{
        //  cellRenderer: insuredAndPayorCellRenderer, valueGetter: insuredAndPayorValueGetter, headerName: 'Insured\nPayor', minWidth: 120, autoHeight: true,
        //  getQuickFilterText: params => {
        //    return params.value;
        //  }
        //},
        //{
        //  cellRenderer: policyNumAndAgentIDCellRenderer, valueGetter: policyNumAndAgentIDValueGetter, field: 'policyNum', headerName: 'Policy #\nAgent',
        //  getQuickFilterText: params => {
        //    return params.value;
        //  }
        //},
        //{
        //  cellRenderer: paidToDateAndIssueDateCellRenderer, valueGetter: paidToDateAndIssueDateValueGetter, headerName: 'Paid-To Date\nIssue Date',
        //  getQuickFilterText: params => {
        //    return params.value;
        //  }
        //},
        //{ field: 'modalPremium', headerName: 'Modal Prem', valueFormatter: currencyFormatter },
        //{
        //  cellRenderer: premiumDueAndMonthsCellRenderer, valueGetter: premiumDueAndMonthsValueGetter, headerName: 'Prem Due\nMonths',
        //  getQuickFilterText: params => {
        //    return params.value;
        //  }
        //},
        //{
        //  cellRenderer: suspenseAndLoanBalanceCellRenderer, valueGetter: suspenseAndLoanBalanceValueGetter, headerName: 'Suspense\nLoan Balance',
        //  getQuickFilterText: params => {
        //    return params.value;
        //  }
        //},
        //{ field: 'collectionAmt', headerName: 'Prem Collected', valueFormatter: currencyFormatter }
      ];
    }
    else {
      this.columnRoutleListDefs = [
        { field: 'sequenceNoGroupText', rowGroup: true, hide: true },
        //{
        //  headerName: "Menu",
        //  cellRenderer: (params: any) => {
        //    return '<button type="button" onclick="console.log(' + params.data + ')" > Menu </button>'
        //  }
        //},
        {
          minWidth: 75, maxWidth: 75, cellRenderer: (params: any) => {
            return this.getRoutleListGridIcons(params.data);
          },
          tooltipValueGetter: (params: any) => {
            return this.getRoutleListGridTooltip(params.data);
          }, headerClass: 'commission-header'
        },
        { field: 'insuredName', headerName: 'Insured', minWidth: 100, headerClass: 'commission-header' },
        { field: 'policyNum', headerName: 'Policy #', minWidth: 100, maxWidth: 125, headerClass: 'commission-header' },
        { field: 'agentID', headerName: 'Agent', minWidth: 110, maxWidth: 110, headerClass: 'commission-header' },
        { field: 'paidToDate', headerName: 'Paid-To Date', minWidth: 110, maxWidth: 110, headerClass: 'commission-header' },
        { field: 'modalPremium', headerName: 'Modal Prem', valueFormatter: currencyFormatter, minWidth: 120, maxWidth: 120, headerClass: 'commission-header' },
        { field: 'monthsDue', headerName: 'Months Due', minWidth: 100, maxWidth: 100, headerClass: 'commission-header' },
        { field: 'premiumDue', headerName: 'Prem Due', minWidth: 100, maxWidth: 100, valueFormatter: currencyFormatter, headerClass: 'commission-header' },
        { field: 'availableSuspense', headerName: 'Avail Suspense', minWidth: 120, maxWidth: 120, valueFormatter: currencyFormatter, headerClass: 'commission-header' },
        { field: 'currentLoanBalance', headerName: 'Loan Balance', minWidth: 120, maxWidth: 120, valueFormatter: currencyFormatter, headerClass: 'commission-header' },
        { field: 'collectionAmt', headerName: 'Prem Collected', minWidth: 120, maxWidth: 120, valueFormatter: currencyFormatter, headerClass: 'commission-header' }

        //{
        //  cellRenderer: insuredAndPayorCellRenderer, valueGetter: insuredAndPayorValueGetter, headerName: 'Insured\nPayor', minWidth: 200, autoHeight: true, headerClass: 'commission-header',
        //  getQuickFilterText: params => {
        //    return params.value;
        //  }
        //},
        //{
        //  cellRenderer: policyNumAndAgentIDCellRenderer, valueGetter: policyNumAndAgentIDValueGetter, field: 'policyNum', headerName: 'Policy #\nAgent', minWidth: 120, headerClass: 'commission-header',
        //  getQuickFilterText: params => {
        //    return params.value;
        //  }
        //},
        //{
        //  cellRenderer: paidToDateAndIssueDateCellRenderer, valueGetter: paidToDateAndIssueDateValueGetter, headerName: 'Paid-To Date\nIssue Date', headerClass: 'commission-header',
        //  getQuickFilterText: params => {
        //    return params.value;
        //  }
        //},
        //{ field: 'modalPremium', headerName: 'Modal Prem', valueFormatter: currencyFormatter, headerClass: 'commission-header' },
        //{
        //  cellRenderer: premiumDueAndMonthsCellRenderer, valueGetter: premiumDueAndMonthsValueGetter, headerName: 'Prem Due\nMonths', headerClass: 'commission-header',
        //  getQuickFilterText: params => {
        //    return params.value;
        //  }
        //},
        //{
        //  cellRenderer: suspenseAndLoanBalanceCellRenderer, valueGetter: suspenseAndLoanBalanceValueGetter, headerName: 'Suspense\nLoan Balance', headerClass: 'commission-header',
        //  getQuickFilterText: params => {
        //    return params.value;
        //  }
        //},
        //{ field: 'collectionAmt', headerName: 'Prem Collected', valueFormatter: currencyFormatter, headerClass: 'commission-header' }
      ];
    }
  }

  getPendingCollectionsColumnDefs(): void {
    if (this.innerWidth >= this.screenWidthForDesktops) {
      this.columnPendingCollectionsDefs = [
        { field: 'sequenceNo', headerName: 'Route Seq #', minWidth: 75 },
        {
          minWidth: 50, maxWidth: 50, cellRenderer: (params: any) => {
            return this.getPendingCollectionsListGridIcons(params.data);
          },
          tooltipValueGetter: (params: any) => {
            return this.getPendingCollectionsListGridTooltip(params.data);
          },
        },
        { field: 'insuredName', headerName: 'Insured', minWidth: 150 },
        { field: 'policyNum', headerName: 'Policy Number', minWidth: 150 },
        { field: 'agentID', headerName: 'Agent' },
        { field: 'premiumAmt', headerName: 'Premium', valueFormatter: currencyFormatter },
        { field: 'overpaymentAmt', headerName: 'Overpayment Amt', valueFormatter: currencyFormatter },
        { field: 'agentCollectAmt', headerName: 'Agent Collect/Due', valueFormatter: currencyFormatter },
        { field: 'appliedSuspenseAmt', headerName: 'Applied Suspense', valueFormatter: currencyFormatter },
        { field: 'loanPaymentAmt', headerName: 'Loan Payment', valueFormatter: currencyFormatter },
        { field: 'totalCollectionAmt', headerName: 'Total Collection', valueFormatter: currencyFormatter },
        { field: 'estCommAmt', headerName: 'Est. Commission Amt', valueFormatter: currencyFormatter },
        { field: 'estNetAmt', headerName: 'Est. Net Draft', valueFormatter: currencyFormatter }
      ];
    }
    else {
      this.columnPendingCollectionsDefs = [
        { field: 'sequenceNo', headerName: 'Route Seq #', minWidth: 75, headerClass: 'commission-header' },
        {
          minWidth: 50, maxWidth: 50, cellRenderer: (params: any) => {
            return this.getPendingCollectionsListGridIcons(params.data);
          },
          tooltipValueGetter: (params: any) => {
            return this.getPendingCollectionsListGridTooltip(params.data);
          },
        },
        { field: 'insuredName', headerName: 'Insured', minWidth: 150, headerClass: 'commission-header' },
        { field: 'policyNum', headerName: 'Policy Number', minWidth: 150, headerClass: 'commission-header' },
        { field: 'agentID', headerName: 'Agent', headerClass: 'commission-header' },
        { field: 'premiumAmt', headerName: 'Premium', valueFormatter: currencyFormatter, headerClass: 'commission-header' },
        { field: 'overpaymentAmt', headerName: 'Overpayment Amt', valueFormatter: currencyFormatter, headerClass: 'commission-header' },
        { field: 'agentCollectAmt', headerName: 'Agent Collect/Due', valueFormatter: currencyFormatter, headerClass: 'commission-header' },
        { field: 'appliedSuspenseAmt', headerName: 'Applied Suspense', valueFormatter: currencyFormatter, headerClass: 'commission-header' },
        { field: 'loanPaymentAmt', headerName: 'Loan Payment', valueFormatter: currencyFormatter, headerClass: 'commission-header' },
        { field: 'totalCollectionAmt', headerName: 'Total Collection', valueFormatter: currencyFormatter, headerClass: 'commission-header' },
        { field: 'estCommAmt', headerName: 'Est. Commission Amt', valueFormatter: currencyFormatter, headerClass: 'commission-header' },
        { field: 'estNetAmt', headerName: 'Est. Net Draft', valueFormatter: currencyFormatter, headerClass: 'commission-header' }
      ];
    }
  }

  getBatchHistoryColumnDefs(): void {
    if (this.innerWidth >= this.screenWidthForDesktops) {
      this.columnBatchHistoryDefs = [
        { field: 'batchNum', headerName: 'Batch No.' },
        { field: 'agentID', headerName: 'Agent' },
        { field: 'premiumAmt', headerName: 'Premium' },
        { field: 'overpaymentAmt', headerName: 'Overpayment' },
        { field: 'loanPaymentAmt', headerName: 'Loan Repayment' },
        { field: 'agentCollectAmt', headerName: 'Agent Collect/Due' },
        { field: 'appliedSuspenseAmt', headerName: 'Applied Suspense' },
        { field: 'totalCollectionAmt', headerName: 'Total Collection' },
        { field: 'estCommAmt', headerName: 'Est. Commission' },
        { field: 'estNetAmt', headerName: 'Est. Net Draft' },
        { field: 'createDTM', headerName: 'Batch Date', comparator: DateComparator },
        { field: 'collectionBatchStatus', headerName: 'Status' },
        { field: 'statusUpdateDTM', headerName: 'Status Date', comparator: DateComparator }
      ];
    }
    else {
      this.columnBatchHistoryDefs = [
        { field: 'batchNum', headerName: 'Batch No.', headerClass: 'commission-header' },
        { field: 'agentID', headerName: 'Agent', headerClass: 'commission-header' },
        { field: 'premiumAmt', headerName: 'Premium', headerClass: 'commission-header' },
        { field: 'overpaymentAmt', headerName: 'Overpayment', headerClass: 'commission-header' },
        { field: 'loanPaymentAmt', headerName: 'Loan Repayment', headerClass: 'commission-header' },
        { field: 'agentCollectAmt', headerName: 'Agent Collect/Due', headerClass: 'commission-header' },
        { field: 'appliedSuspenseAmt', headerName: 'Applied Suspense', headerClass: 'commission-header' },
        { field: 'totalCollectionAmt', headerName: 'Total Collection', headerClass: 'commission-header' },
        { field: 'estCommAmt', headerName: 'Est. Commission', headerClass: 'commission-header' },
        { field: 'estNetAmt', headerName: 'Est. Net Draft', headerClass: 'commission-header' },
        { field: 'createDTM', headerName: 'Batch Date', comparator: DateComparator, headerClass: 'commission-header' },
        { field: 'collectionBatchStatus', headerName: 'Status', headerClass: 'commission-header' },
        { field: 'statusUpdateDTM', headerName: 'Status Date', comparator: DateComparator, headerClass: 'commission-header' }
      ];
    }
  }

  getRouteList() {
    var billFormCodeListValues = "";
    if (this.agentCollectCheckValue != "") {
      billFormCodeListValues = billFormCodeListValues === "" ? this.agentCollectCheckValue : billFormCodeListValues.concat(",").concat(this.agentCollectCheckValue);
    }
    if (this.autoPremiumLoanCheckValue != "") {
      billFormCodeListValues = billFormCodeListValues === "" ? this.autoPremiumLoanCheckValue : billFormCodeListValues.concat(",").concat(this.autoPremiumLoanCheckValue);
    }
    if (this.bankDraftCheckValue != "") {
      billFormCodeListValues = billFormCodeListValues === "" ? this.bankDraftCheckValue : billFormCodeListValues.concat(",").concat(this.bankDraftCheckValue);
    }
    if (this.creditCardCheckValue != "") {
      billFormCodeListValues = billFormCodeListValues === "" ? this.creditCardCheckValue : billFormCodeListValues.concat(",").concat(this.creditCardCheckValue);
    }
    if (this.directBillCheckValue != "") {
      billFormCodeListValues = billFormCodeListValues === "" ? this.directBillCheckValue : billFormCodeListValues.concat(",").concat(this.directBillCheckValue);
    }
    if (this.groupListBillCheckValue != "") {
      billFormCodeListValues = billFormCodeListValues === "" ? this.groupListBillCheckValue : billFormCodeListValues.concat(",").concat(this.groupListBillCheckValue);
    }

    // Return Route List after extracting billing form counts from first record then removing that record from result set
    this.routeList$ = this.onPremService
      .getRouteList(this.selectedAgentId, billFormCodeListValues)
      .pipe(map(response => {
        this.agentCollectCount = response.data[0].billFormCountAgentCollect;
        this.autoPremiumLoanCount = response.data[0].billFormCountAutoPremLoan;
        this.bankDraftCount = response.data[0].billFormCountBankDraft;
        this.creditCardCount = response.data[0].billFormCountCreditCard;
        this.directBillCount = response.data[0].billFormCountDirectBill;
        this.groupListBillCount = response.data[0].billFormCountGroupListBill;
        response.data.shift();
        return response.data;
      }));
  }

  resetDisplayBillingMethods() {
    this.agentCollectCheckValue = "X";
    this.autoPremiumLoanCheckValue = "4";
    this.bankDraftCheckValue = "2";
    this.creditCardCheckValue = "C";
    this.directBillCheckValue = "1";
    this.groupListBillCheckValue = "3";
    this.agentCollectCount = 0;
    this.autoPremiumLoanCount = 0;
    this.bankDraftCount = 0;
    this.creditCardCount = 0;
    this.directBillCount = 0;
    this.groupListBillCount = 0;
  }

  getPendingCollections() {
    //this.getPendingCollectionsColumnDefs();
    this.onPremService.getPendingCollections(this.selectedAgentId)
      .pipe(take(1))
      .subscribe(policyInfo => {
        this.pendingCollectionsRowData = policyInfo.data;
        this.disableSubmitBatchButton = policyInfo.data.length === 0;
        setTimeout(() => {
          this.gridApi2?.sizeColumnsToFit();
        }, 100);
      });
  }

  getBatchHistory() {
    //this.getBatchHistoryColumnDefs();
    this.onPremService.getBatchHistory(this.selectedAgentId)
      .pipe(take(1))
      .subscribe(policyInfo => {
        this.batchHistoryRowData = policyInfo.data;
      });
  }

  //TODO: TEST TO SEE IF TotalCollectedAmount CAN BE SAVED AND PRESENTED ELSEWHERE (AWAY FROM ROUTELIST TAB)
  //getTotalCollectedAmount() {
  //  this.testText$ = this.routeList$?.pipe(
  //    map(arr => arr.reduce((a, b) => a + b.collectionAmt, 0)));
  //}

  //Old Method
  getAgentLists(): void {
    this.onPremService.getAgentWithDownine(this.profile.agentLinkId, this.profile.channelPreferenceValue, true)
      .pipe(take(1))
      .subscribe(agents => {
        if (agents.length > 0) {
          this.collectingAgents = agents;
          this.selectedCollectingAgentId = agents[0].agentId;
          this.showCollectingManagerList = true;
          this.onPremService.getAgentActiveHierarchyListWithSelf(this.selectedCollectingAgentId, true)
            .pipe(take(1))
            .subscribe(apiReponse => {
              this.agents = apiReponse.data;
            });
        } else {
          this.showCollectingManagerList = false;
          this.onPremService.getLinkedAgentList(this.profile.auth0Id, this.profile.channelPreferenceValue, true)
            .pipe(take(1))
            .subscribe(apiReponse => {
              this.agents = apiReponse.data;
            });
        }
      });
  }

  //New Method
  getAgentList(): void {
    this.onPremService.getAgentWithDownineList(this.profile.selectedAgentNumber)
      .pipe(take(1))
      .subscribe(agents => {
        this.agents = agents;
        this.selectedAgentId = agents[0].agentId;

        switch (this.selectedTab) {
          case 'Pending Collections':
            this.getPendingCollections();
            break;

          case 'Batch History':
            this.getBatchHistory();
            break;

          default:
            this.refreshRouteList();
        };
      });
  }

  changeAgentId(e: any) {

    this.selectedAgentId = e.target.value;

    switch (this.selectedTab) {
      case 'Pending Collections':
        this.getPendingCollections();
        break;

      case 'Batch History':
        this.getBatchHistory();
        break;

      default:
        this.topRowID = 0;
        this.refreshRouteList();
    }
  }

  changeCollectingAgentId(e: any) {
    this.selectedCollectingAgentId = e.target.value;

    this.onPremService.getAgentActiveHierarchyListWithSelf(this.selectedCollectingAgentId, true)
      .pipe(take(1))
      .subscribe(apiReponse => {
        this.agents = apiReponse.data;
        this.selectedAgentId = apiReponse.data[0].agentId;

        switch (this.selectedTab) {
          case 'Pending Collections':
            this.getPendingCollections();
            break;

          case 'Batch History':
            this.getBatchHistory();
            break;

          default:
            this.topRowID = 0;
            this.getRouteList();
        }
      });
  }

  checkBillingMethod(e: any) {
    if (e.target.id == 'displayAgentCollect') {
      this.agentCollectCheckValue = e.target.checked ? "X" : "";
    }
    if (e.target.id == 'displayAutoPremiumLoan') {
      this.autoPremiumLoanCheckValue = e.target.checked ? "4" : "";
    }
    if (e.target.id == 'displayBankDraft') {
      this.bankDraftCheckValue = e.target.checked ? "2" : "";
    }
    if (e.target.id == 'displayCreditCard') {
      this.creditCardCheckValue = e.target.checked ? "C" : "";
    }
    if (e.target.id == 'displayDirectBill') {
      this.directBillCheckValue = e.target.checked ? "1" : "";
    }
    if (e.target.id == 'displayGroupListBill') {
      this.groupListBillCheckValue = e.target.checked ? "3" : "";
    }
    this.getRouteList();
  }

  clearForm() {
    this.policyNumber = "";
    this.sequenceNo = "";
    this.routeSequenceId = "";
    this.amountCollected = 0.00;
    this.modalPremium = 0.00;
    this.premiumDue = 0.00;
    this.monthsPaid = 0;
    this.monthlyPremiumPaid = 0;
    this.minimumMonths = 0;
    this.collectionId = "";
    this.regionId = 0;
    this.collectionAgentId = "";
    this.cashAmount = 0.00;
    this.loanPaymentAmount = 0.00;
    this.suspenseAmount = 0.00;
    this.overpaymentAmount = 0.00;
    this.premiumCollected = 0.00;
    this.insuredName = "";
    this.payorName = "";

    this.groupModalPremium = 0.00;
    this.groupPremiumDue = 0.00;
    this.premiumGroupCollected = 0.00;
    this.monthsGroupPaid = 0;
  }

  onRouteListRowClicked(event: RowSelectedEvent) {
    if (event.node.isSelected()) {
      if (!event.node.key) {
        if (event.data.lockPayment === 'YES') {
          this.showLockAlert();
        }
        else {
          this.getCollection(event.data);
          this.openCollectionForm();
        }
      }
    }
  }

  onBatchHistoryListRowClicked(event: RowSelectedEvent) {
    if (event.node.isSelected()) {
      if (!event.node.key) {
        this.getReport(75, "", event.node.data.collectionBatchID);
      }
    }
  }

  openCollectionForm() {
    switch (this.selectedTab) {
      case 'Route List':
        this.showRouteList = false;
        break;
      case 'Pending Collections':
        this.showSubmitBatchButton = false;
        this.showPendingCollectionsList = false;
        break;
      default:
    }
    this.disabledTabs();
    this.showCollectionParameters = false;
    this.showCollectionForm = true;
    this.disableSaveCollectionButton = true;
  }

  closeCollectionForm() {
    this.clearForm();
    this.enableTabs();
    this.showCollectionParameters = true;
    this.showCollectionForm = false;
    this.showLapseAlert = false;
    this.showNoPremiumCollected = false;
    this.showSuspenseAlert = false;
    this.showLoanBalanceAlert = false;

    switch (this.selectedTab) {
      case 'Route List':
        this.showRouteList = true;
        break;
      case 'Pending Collections':
        this.showSubmitBatchButton = true;
        this.showPendingCollectionsList = true;
        this.refreshPendingCollectionsList();
        break;
      default:
    }
  }

  openLapsedCollectionForm() {
    this.showSubmitBatchButton = false;
    this.showPendingCollectionsList = false;
    this.disabledTabs();
    this.showCollectionParameters = false;
    this.isMakeOverPaymentChecked = false;
    this.showLapsedCollectionForm = true;
    this.enablePolicyNumberSearch = true;
    this.disableSaveLapsedCollectionButton = true;
  }

  closeLapsedCollectionForm() {
    this.clearForm();
    this.enableTabs();
    this.showCollectionParameters = true;
    this.showLapsedCollectionForm = false;
    this.showLapseAlert = false;
    this.showLapseCollectionInfo = false;
    this.showNoPremiumCollected = false;
    this.showPolicyFound = false;
    this.showPolicyNotFound = false;
    this.showSubmitBatchButton = true;
    this.showPendingCollectionsList = true;
    this.refreshPendingCollectionsList();
  }

  openGroupCollectionForm() {
    this.showCollectionParameters = false;
    this.showRouteList = false;
    this.showGroupCollectionForm = true;
    this.disableSaveGroupCollectionButton = false;
    this.disabledTabs();
  }

  closeGroupCollectionForm() {
    this.clearForm();
    this.enableTabs();
    this.showCollectionParameters = true;
    this.showRouteList = true;
    this.showGroupCollectionForm = false;
    this.showNoGroupPremiumCollected = false;
  }

  getCollection(data: any): void {
    if (data.collectionID === null) {
      this.collectionTypeId = data.collectionTypeID;
      this.policyNumber = data.policyNum;
      this.sequenceNo = data.sequenceNo;
      this.routeSequenceId = data.routeSequenceID
      this.modalPremium = data.modalPremium;
      this.premiumDue = data.premiumDue;
      this.paidToDate = data.paidToDate;
      this.monthsPaid = data.requireFullPayment == 'YES' && this.profile.channelPreference === 'Home Service' ? data.monthsDue : 0;
      this.monthlyPremiumPaid = this.modalPremium * this.monthsPaid;
      this.showLapseAlert = data.requireFullPayment == 'YES';
      this.minimumMonths = data.requireFullPayment == 'YES' && this.profile.channelPreference === 'Home Service' ? this.monthsPaid : 0;
      this.premiumCollected = data.requireFullPayment == 'YES' && this.profile.channelPreference === 'Home Service' ? data.premiumDue : 0;
      this.currentCommRate = data.currentCommRate;
      // Commission Displayed may be implemented later
      //this.estimatedCommission = this.premiumCollected * this.currentCommRate;      
      this.regionId = data.regionID;
      this.collectionAgentId = this.profile.auth0Id;
      this.cashAmount = 0;// this.premiumDue;
      this.availableSuspenseAmount = data.availableSuspense;
      this.isUseSuspenseChecked = false;
      this.currentLoanBalance = data.currentLoanBalance;
      this.isMakeLoanPaymentChecked = false;
      this.isMakeOverPaymentChecked = false;
      this.insuredName = data.insuredName;
      this.payorName = data.payorName;
    } else {
      this.onPremService.getCollection(data.collectionID)
        .pipe(take(1))
        .subscribe(response => {
          this.collectionId = response.data[0].collectionID;
          this.collectionTypeId = response.data[0].collectionTypeID;
          this.policyNumber = response.data[0].policyNum;
          this.sequenceNo = response.data[0].sequenceNo;
          this.modalPremium = response.data[0].modalPremium;
          this.premiumDue = response.data[0].premiumDue;
          this.paidToDate = response.data[0].paidToDate;
          this.monthsPaid = response.data[0].monthsPaid;
          this.showLapseAlert = response.data[0].requireFullPayment == 'YES';
          this.minimumMonths = response.data[0].requireFullPayment == 'YES' && this.profile.channelPreference === 'Home Service' ? response.data[0].monthsDue : 0;
          this.premiumCollected = response.data[0].cashAmt + response.data[0].overpaymentAmt;
          this.currentCommRate = response.data[0].currentCommRate;
          // Commission Displayed may be implemented later
          //this.estimatedCommission = response.data[0].premiumDue * response.data[0].currentCommRate;
          this.regionId = response.data[0].regionID;
          this.collectionAgentId = response.data[0].collectionAgentID;
          let originalCashAmount = response.data[0].cashAmt + response.data[0].suspenseAmt;
          this.monthlyPremiumPaid = this.modalPremium * this.monthsPaid;
          this.cashAmount = originalCashAmount;
          this.availableSuspenseAmount = response.data[0].availableSuspense;
          this.suspenseAmount = response.data[0].suspenseAmt;
          this.isUseSuspenseChecked = response.data[0].suspenseAmt > 0;
          this.loanPaymentAmount = response.data[0].loanPaymentAmt;
          this.currentLoanBalance = response.data[0].loanBalanceAmt;
          this.isMakeLoanPaymentChecked = response.data[0].loanPaymentAmt > 0;
          this.overpaymentAmount = response.data[0].overpaymentAmt;
          this.isMakeOverPaymentChecked = response.data[0].overpaymentAmt > 0;
          this.amountCollected = (Math.round(((this.cashAmount - this.suspenseAmount + this.loanPaymentAmount + this.overpaymentAmount) + Number.EPSILON) * 100) / 100);
          this.insuredName = response.data[0].insuredName;
          this.payorName = response.data[0].payorName;
        });
    }
  }

  addCollection(formData: any): void {
    if (this.premiumCollected > 0) {
      if (this.currentLoanBalance >= this.loanPaymentAmount) {
        if (this.availableSuspenseAmount >= this.suspenseAmount) {
          let netCashAmount = this.cashAmount - this.suspenseAmount;
          this.disableSaveCollectionButton = true;
          this.onPremService.saveCollection(this.collectionId, this.collectionTypeId, this.regionId, this.policyNumber, this.selectedAgentId, this.collectionAgentId,
            this.monthsPaid, netCashAmount, this.suspenseAmount, this.overpaymentAmount, this.loanPaymentAmount, this.profile.auth0Id, this.modalPremium,
            this.currentCommRate, this.insuredName, this.payorName, this.sequenceNo)
            .pipe(take(1))
            .subscribe((response: any) => {
              if (response.data.responseCode.code === -1) {
                this.showCollectionErrorAlert(response.data.responseCode.message);
              } else {
                if (this.showCollectionForm) {
                  this.closeCollectionForm();

                  if (this.selectedTab == "Pending Collections") {
                    this.refreshPendingCollectionsList();
                  }
                };
                if (this.showLapsedCollectionForm) {
                  this.closeLapsedCollectionForm();
                  this.refreshPendingCollectionsList();
                };
                //}
              }
            });
        }
        else {
          this.showSuspenseAlert = true;
        }
      }
      else {
        this.showLoanBalanceAlert = true;
      }
    }
    else {
      this.showNoPremiumCollected = true;
    }
  }

  deleteCollection(collectionId: string): void {
    if (confirm("Are you sure you want to delete this payment?")) {
      this.onPremService.deleteCollection(collectionId)
        .pipe(take(1))
        .subscribe();
      if (this.showCollectionForm) {
        this.closeCollectionForm();
      } else if (this.showLapsedCollectionForm) {
        this.closeLapsedCollectionForm();
      } else {
        this.refreshPendingCollectionsList();
      };
    }
  }

  setGroupCollectionValues(listCollection: any) {
    var premiumSum = 0;
    var premiumDueSum = 0;
    this.collectionId = listCollection[0].data.collectionID;
    this.sequenceNo = listCollection[0].data.sequenceNo;
    this.routeSequenceId = listCollection[0].data.routeSequenceID;
    this.monthsGroupPaid = listCollection[0].data.requireFullPayment == 'YES' && this.profile.channelPreference === 'Home Service' ? listCollection[0].data.monthsDue : 0;
    this.showLapseAlert = listCollection[0].data.requireFullPayment == 'YES';
    this.minimumMonths = listCollection[0].data.requireFullPayment == 'YES' && this.profile.channelPreference === 'Home Service' ? this.monthsGroupPaid : 0;
    this.currentGroupCommRate = listCollection[0].data.currentCommRate;
    this.collectionAgentId = this.profile.auth0Id;

    let isLapsed: boolean = false;
    let monthsLapsed: number = 0;

    for (var i = 0; i < listCollection.length; i++) {
      premiumSum += listCollection[i].data.modalPremium;
      premiumDueSum += listCollection[i].data.premiumDue;

      if (listCollection[i].data.requireFullPayment == 'YES' && this.profile.channelPreference === 'Home Service') {
        isLapsed = true;
        if (listCollection[i].data.monthsDue > monthsLapsed)
          monthsLapsed = listCollection[i].data.monthsDue;
      }
    }

    if (isLapsed) {
      this.showLapseAlert = isLapsed;
      this.monthsGroupPaid = monthsLapsed;
      this.minimumMonths = monthsLapsed;
      this.premiumGroupCollected = premiumSum * monthsLapsed;
    }
    else {
      this.premiumGroupCollected = 0;
    }
    this.groupModalPremium = premiumSum;
    this.groupPremiumDue = premiumDueSum;
  }

  checkToDisableAddGroupCollection(listCollection: any): boolean {
    var disable = false;
    if (listCollection[0].data.sequenceNo == 'UNSEQUENCED') {
      disable = true;
    }
    return disable;
  }

  addGroupCollection(formData: any): void {
    let collectionError = false;
    if (this.premiumGroupCollected > 0) {
      this.disableSaveGroupCollectionButton = true;
      this.onPremService.saveGroupCollection(this.routeSequenceId, this.selectedAgentId, this.collectionAgentId, this.monthsGroupPaid,
        this.premiumGroupCollected, this.profile.auth0Id)
        .then((response: any) => {
          if (response.responseCode.code === -1) {
            collectionError = true;
            this.showCollectionErrorAlert(response.responseCode.message);
          };
        })
        .finally(() => {
          if (!collectionError) {
            this.closeGroupCollectionForm();
          }
        });
    }
    else {
      this.showNoGroupPremiumCollected = true;
    }
  }

  checkToDisableDeleteGroupCollection(listCollection: any): boolean {
    var collectedSum = 0;

    for (var i = 0; i < listCollection.length; i++) {
      collectedSum += listCollection[i].data.collectionAmt;
    }

    return collectedSum === 0;
  }

  checkForLockInGroupCollection(listCollection: any): boolean {
    var locked = false;

    for (var i = 0; i < listCollection.length; i++) {
      if (listCollection[i].data.lockPayment === 'YES') {
        locked = true;
      }
    }
    return locked;
  }

  deleteGroupCollection(routeSequenceId: string): void {
    if (confirm("Are you sure you want to delete this group payment?")) {
      this.onPremService.deleteGroupCollection(routeSequenceId)
        .finally(() => {
          //this.getTotalCollectedAmount();
          this.refreshRouteList();
        });
    }
  }

  searchPolicyNumber(formPolicyNumberCtrl: AbstractControl) {
    var policyNumber = formPolicyNumberCtrl.value;
    this.onPremService.getLapsedPolicy(this.selectedAgentId, policyNumber)
      .pipe(take(1))
      .subscribe(apiResponse => {
        if (apiResponse.data.length === 0) {
          this.showPolicyFound = false;
          this.showLapseAlert = false;
          this.showLapseCollectionInfo = false;
          this.showNoPremiumCollected = false;
          this.insuredName = "";
          this.showPolicyNotFound = true;
        }
        else {
          this.enablePolicyNumberSearch = false;
          this.showPolicyFound = true;
          this.showPolicyNotFound = false;
          this.showLapseCollectionInfo = true;
          this.disableSaveLapsedCollectionButton = true;
          this.collectionTypeId = apiResponse.data[0].collectionTypeID;
          this.policyNumber = apiResponse.data[0].policyNum;
          this.sequenceNo = apiResponse.data[0].sequenceNo;
          this.routeSequenceId = apiResponse.data[0].routeSequenceID
          this.modalPremium = apiResponse.data[0].modalPremium;
          this.premiumDue = apiResponse.data[0].premiumDue;
          this.paidToDate = apiResponse.data[0].paidToDate;
          this.monthsPaid = apiResponse.data[0].requireFullPayment == 'YES' ? apiResponse.data[0].monthsDue : 0;
          this.monthlyPremiumPaid = this.modalPremium * this.monthsPaid;
          this.showLapseAlert = apiResponse.data[0].requireFullPayment == 'YES';
          this.minimumMonths = apiResponse.data[0].requireFullPayment == 'YES' ? this.monthsPaid : 0;
          this.premiumCollected = apiResponse.data[0].requireFullPayment == 'YES' ? apiResponse.data[0].premiumDue : 0;
          this.currentCommRate = apiResponse.data[0].currentCommRate;
          this.regionId = apiResponse.data[0].regionID;
          this.collectionAgentId = this.profile.auth0Id;
          this.cashAmount = 0;// this.premiumDue;
          this.availableSuspenseAmount = apiResponse.data[0].availableSuspense;
          this.isMakeOverPaymentChecked = false;
          this.currentLoanBalance = apiResponse.data[0].currentLoanBalance;
          this.insuredName = apiResponse.data[0].insuredName;
          this.payorName = apiResponse.data[0].payorName;
        }
      });
  }

  resetPolicyNumberSearch() {
    this.enablePolicyNumberSearch = true;
    this.policyNumber = '';
    this.insuredName = '';
    this.amountCollected = 0;
    this.overpaymentAmount = 0;
    this.showPolicyFound = false;
    this.showPolicyNotFound = false;
    this.showLapseAlert = false;
    this.showLapseCollectionInfo = false;
  }

  openSequenceNumberForm() {
    this.showCollectionParameters = false;
    this.showRouteList = false;
    this.showSequenceNumberForm = true;
    this.disabledTabs();
  }

  closeSequenceNumberForm() {
    this.enableTabs();
    this.showCollectionParameters = true;
    this.showRouteList = true;
    this.showSequenceNumberForm = false;
  }

  getSequenceNumberInfo(data: any): void {
    this.regionId = data.regionID;
    this.policyNumber = data.policyNum;
    this.policyAgentID = data.agentID;
    this.sequenceNo = data.sequenceNo === 'UNSEQUENCED' ? '' : data.sequenceNo;
  }

  updateSequenceNumber(formData: any): void {
    this.onPremService.saveSequence(this.regionId, this.policyNumber, this.policyAgentID, trim(formData.sequenceNo), this.profile.auth0Id)
      .pipe(take(1))
      .subscribe();

    this.closeSequenceNumberForm();
  }

  openSequenceContactForm() {
    this.showCollectionParameters = false;
    this.showRouteList = false;
    this.showSequenceContactForm = true;
    this.disabledTabs();
  }

  closeSequenceContactForm() {
    this.enableTabs();
    this.showCollectionParameters = true;
    this.showRouteList = true;
    this.showSequenceContactForm = false;
  }

  getSequenceContactInfo(data: any): void {
    this.routeSequenceId = data.routeSequenceID;
    this.sequenceNo = data.sequenceNo;

    this.onPremService.getSequenceContact(this.routeSequenceId)
      .pipe(take(1))
      .subscribe(response => {
        this.contactName = response.data[0].name;
        this.contactAddressLine1 = response.data[0].addressLine1;
        this.contactAddressLine2 = response.data[0].addressLine2;
        this.contactCity = response.data[0].city;
        this.contactState = response.data[0].state;
        this.contactZip = response.data[0].zip;
        this.contactEmailAddress = response.data[0].emailAddress;
        this.contactPhone = response.data[0].phone;
        this.contactNotes = response.data[0].notes;
      });
  }

  updateSequenceContact(formData: any): void {
    this.onPremService.saveSequenceContact(this.routeSequenceId, this.sequenceNo, this.contactName, this.contactAddressLine1, this.contactAddressLine2,
      this.contactCity, this.contactState, this.contactZip, this.contactEmailAddress, this.contactPhone, this.contactNotes, this.profile.auth0Id)
      .pipe(take(1))
      .subscribe();

    this.closeSequenceContactForm();
  }

  submitBatch(collectionId: string): void {
    if (confirm("Are you sure you want to submit this collection batch?")) {
      this.onPremService.insertCollectionBatch(this.selectedAgentId)
        .finally(() => {
          this.refreshPendingCollectionsList();
        });
    }
  }

  displayPolicyDetail(agentId: string, policyNumber: string, regionID: number) {
    this.selectedPolicy = <Policy>{};
    this.onPremService.getPolicyDetail(agentId, policyNumber, regionID, this.profile.channelPreferenceValue)
      .pipe(take(1))
      .subscribe(policyInfo => {
        this.selectedPolicy = policyInfo.data[0];
        this.coverageRowData = policyInfo.data[0].policyCoverages;
        this.paymentRowData = policyInfo.data[0].policyPayments;
        this.statusHistoryRowData = policyInfo.data[0].policyStatusHistory;
        this.underwritingStatusRowData = policyInfo.data[0].policyUnderwritingStatus;
        this.hierarchyRowData = policyInfo.data[0].policyHierarchy;
        this.beneficiaryInfoRowData = policyInfo.data[0].policyBeneficiaryInfo;
        this.beneficiaryRowData = policyInfo.data[0].policyBeneficiary;
        this.allocationRowData = policyInfo.data[0].policyAllocation;
        if (policyInfo.data[0].policyCoverages == null) {
          $('#Coverages-tab').addClass('policymenudisabled');
        }
        if (policyInfo.data[0].policyPayments == null) {
          $('#PremiumHistory-tab').addClass('policymenudisabled');
        }
        if (policyInfo.data[0].policyStatusHistory == null) {
          $('#StatusHistory-tab').addClass('policymenudisabled');
        }
        if (policyInfo.data[0].policyUnderwritingStatus == null) {
          $('#Underwriting-tab').addClass('policymenudisabled');
        }
        if (policyInfo.data[0].policyHierarchy == null) {
          $('#Hierarchy-tab').addClass('policymenudisabled');
        }
      });
    this.showCollectionParameters = false;
    this.showRouteList = false;
    this.showPolicyDetail = true;
    this.disabledTabs();
  }

  displayList(list: string): boolean {
    var show: boolean = false;
    switch (this.profile.channelPreference) {
      case 'Annuity': {
        if (list === 'Underwriting' || list === 'BeneficiaryInfo') {
          show = true;
        }
        break;
      }
      case 'Annuity [Bank]': {
        if (list === 'Underwriting' || list === 'BeneficiaryInfo') {
          show = true;
        }
        break;
      }
      case 'Pre-Need': {
        if (list === 'BeneficiaryInfo') {
          show = true;
        }
        break;
      }
      case 'Home Service': {
        if (list === 'BeneficiaryInfo') {
          show = true;
        }
        break;
      }
      case 'Home Service [Career]': {
        if (list === 'BeneficiaryInfo') {
          show = true;
        }
        break;
      }
      default: {
        show = false;
      }
    };
    return show;
  }

  toggleClientInfoAccordion() {
    this.clientInfoAccordionExpanded = !this.clientInfoAccordionExpanded;
  }
  getClientInfoAccordionArrowStyle() {
    return this.clientInfoAccordionExpanded ? 'fas fa-angle-up' : 'fas fa-angle-down';
  }

  closePolicyDetail() {
    this.enableTabs();
    this.showPolicyDetail = false;
    this.showCollectionParameters = true;
    this.showRouteList = true;
  }
  printPolicyDetail() {
    window.print();
  }

  enableTabs() {
    $('#RouteList-tab').removeClass('policymenudisabled');
    $('#PendingCollections-tab').removeClass('policymenudisabled');
    $('#BatchHistory-tab').removeClass('policymenudisabled');
  }

  disabledTabs() {
    switch (this.selectedTab) {
      case 'Pending Collections':
        $('#RouteList-tab').addClass('policymenudisabled');
        $('#BatchHistory-tab').addClass('policymenudisabled');
        break;
      case 'Batch History':
        $('#RouteList-tab').addClass('policymenudisabled');
        $('#PendingCollections-tab').addClass('policymenudisabled');
        break;
      default:
        $('#PendingCollections-tab').addClass('policymenudisabled');
        $('#BatchHistory-tab').addClass('policymenudisabled');
    }
  }

  policyDetailTabView(link: string) {
    $('#policyDetailMenu').find("*").removeClass("router-link-exact-active");
    $(link).addClass('router-link-exact-active');

    switch (link) {
      case '#Coverages-tab':
        this.gridApi4?.sizeColumnsToFit();
        break;
      case '#PremiumHistory-tab':
        this.gridApi5?.sizeColumnsToFit();
        break;
      case '#StatusHistory - tab':
        this.gridApi6?.sizeColumnsToFit();
        break;
      case '#Underwriting-tab':
        this.gridApi7?.sizeColumnsToFit();
        break;
      case '#Hierarchy-tab':
        this.gridApi8?.sizeColumnsToFit();
        break;
      case '#Beneficiary-tab':
        this.gridApi9?.sizeColumnsToFit();
        break;
      default:
    }
  }

  onGridReady1(params: any) {
    this.gridApi1 = params.api;
    this.gridColumnApi1 = params.columnApi;
    this.gridApi1?.setQuickFilter(this.searchTerm);
    params.api.sizeColumnsToFit();
  }
  onGridReady2(params: any) {
    this.gridApi2 = params.api;
    this.gridColumnApi2 = params.columnApi;
  }
  onGridReady3(params: any) {
    this.gridApi3 = params.api;
    this.gridColumnApi3 = params.columnApi;
  }
  onGridReady4(params: any) {
    this.gridApi4 = params.api;
    this.gridColumnApi4 = params.columnApi;
  }
  onGridReady5(params: any) {
    this.gridApi5 = params.api;
    this.gridColumnApi5 = params.columnApi;
  }
  onGridReady6(params: any) {
    this.gridApi6 = params.api;
    this.gridColumnApi6 = params.columnApi;
  }
  onGridReady7(params: any) {
    this.gridApi7 = params.api;
    this.gridColumnApi7 = params.columnApi;
  }
  onGridReady8(params: any) {
    this.gridApi8 = params.api;
    this.gridColumnApi8 = params.columnApi;
  }
  onGridReady9(params: any) {
    this.gridApi9 = params.api;
    this.gridColumnApi9 = params.columnApi;
  }

  onRowDataRouteListUpdated(params: any) {
    if (params.api.rowModel.rowsToDisplay.length > 0) {
      this.gridApi1?.ensureIndexVisible(this.topRowID, 'top');
    }
  }

  onBodyScroll(params: any) {
    //console.log(params);
    let topIndex = params.top;
    let rows = params.api.rowModel.rowsToDisplay.map((a: { rowTop: number; }) => a.rowTop);

    const topIndexValue = rows.reduce((a: number, b: number) => {
      return (topIndex > a && topIndex < b) || topIndex == a ? a : b;
    });

    this.topRowID = params.api.rowModel.rowsToDisplay
      .filter((a: { rowTop: number; }) => a.rowTop === topIndexValue)
      .map((a: { rowIndex: number; }) => a.rowIndex)[0];
  }

  onRowDataCollectionsListUpdated(params: any) {
    var rows = params.columnApi.columnModel.rowModel.rowsToDisplay;

    var premiumColumn = this.gridColumnApi2.getColumn("premiumAmt");
    var premiumTotal = rows.reduce((a: any, b: any) => a + b.data.premiumAmt, 0);
    premiumColumn.colDef.headerName = `Premium \n (${formatNumber(premiumTotal)})`;

    var overpaymentAmtColumn = this.gridColumnApi2.getColumn("overpaymentAmt");
    var overpaymentAmtTotal = rows.reduce((a: any, b: any) => a + b.data.overpaymentAmt, 0);
    overpaymentAmtColumn.colDef.headerName = `Overpayment \n (${formatNumber(overpaymentAmtTotal)})`;

    var agentCollectAmtColumn = this.gridColumnApi2.getColumn("agentCollectAmt");
    var agentCollectAmtTotal = rows.reduce((a: any, b: any) => a + b.data.agentCollectAmt, 0);
    agentCollectAmtColumn.colDef.headerName = `Agent Collect/Due \n (${formatNumber(agentCollectAmtTotal)})`;

    var appliedSuspenseAmtColumn = this.gridColumnApi2.getColumn("appliedSuspenseAmt");
    var appliedSuspenseAmtTotal = rows.reduce((a: any, b: any) => a + b.data.appliedSuspenseAmt, 0);
    appliedSuspenseAmtColumn.colDef.headerName = `Applied Suspense \n (${formatNumber(appliedSuspenseAmtTotal)})`;

    // TODO: Enable this when loan data is available
    var loanPaymentAmtColumn = this.gridColumnApi2.getColumn("loanPaymentAmt");
    var loanPaymentAmtTotal = rows.reduce((a: any, b: any) => a + b.data.loanPaymentAmt, 0);
    loanPaymentAmtColumn.colDef.headerName = `Loan Payment \n (${formatNumber(loanPaymentAmtTotal)})`;

    var totalCollectionAmtColumn = this.gridColumnApi2.getColumn("totalCollectionAmt");
    var totalCollectionAmtTotal = rows.reduce((a: any, b: any) => a + b.data.totalCollectionAmt, 0);
    totalCollectionAmtColumn.colDef.headerName = `Total Collection \n (${formatNumber(totalCollectionAmtTotal)})`;

    var estCommAmtColumn = this.gridColumnApi2.getColumn("estCommAmt");
    var estCommAmtTotal = rows.reduce((a: any, b: any) => a + b.data.estCommAmt, 0);
    estCommAmtColumn.colDef.headerName = `Est. Commission \n (${formatNumber(estCommAmtTotal)})`;

    var estNetAmtColumn = this.gridColumnApi2.getColumn("estNetAmt");
    var estNetAmtTotal = rows.reduce((a: any, b: any) => a + b.data.estNetAmt, 0);
    estNetAmtColumn.colDef.headerName = `Est. Net Draft \n (${formatNumber(estNetAmtTotal)})`;

    this.gridApi2!.refreshHeader();
  }

  getRouteListContextMenuItems(
    params: GetContextMenuItemsParams
  ): (string | MenuItemDef)[] {
    var result: (string | MenuItemDef)[] = [
      {
        name: 'Collect/Update Payment',
        action: () => {
          if (params.node!.data.lockPayment === 'YES') {
            params.context.showLockAlert();
          }
          else {
            params.context.openCollectionForm();
            params.context.getCollection(params.node!.data);
          }
        }
      },
      {
        name: 'Delete Payment',
        action: () => {
          if (params.node!.data.lockPayment === 'YES') {
            params.context.showLockAlert();
          }
          else {
            params.context.deleteCollection(params.node!.data.collectionID);
            params.context.refreshRouteList();
          }
        },
        disabled: !params.node!.data.collectionID,
      },
      {
        name: 'Edit Sequence Number',
        action: () => {
          params.context.openSequenceNumberForm();
          params.context.getSequenceNumberInfo(params.node!.data);
        },
      },
      'separator',
      {
        name: 'Collect Entire Sequence',
        action: () => {
          params.context.setGroupCollectionValues(params.node!.parent!.allLeafChildren);
          params.context.openGroupCollectionForm();
        },
        disabled: (params.context.checkToDisableAddGroupCollection(params.node!.parent!.allLeafChildren) ||
          params.context.checkForLockInGroupCollection(params.node!.parent!.allLeafChildren))
      },
      {
        name: 'Delete For Entire Sequence',
        action: () => {
          if (params.context.checkForLockInGroupCollection(params.node!.parent!.allLeafChildren)) {
            params.context.showGroupLockAlert();
          }
          else {
            params.context.deleteGroupCollection(params.node!.data.routeSequenceID);
          }
        },
        disabled: params.context.checkToDisableDeleteGroupCollection(params.node!.parent!.allLeafChildren)
      },
      {
        name: 'Edit Sequence Payor',
        action: () => {
          params.context.openSequenceContactForm();
          params.context.getSequenceContactInfo(params.node!.data);
        },
        disabled: params.node!.data.sequenceNo == 'UNSEQUENCED'
      },
      'separator',
      {
        name: 'View Policy Detail',
        action: () => {
          params.context.displayPolicyDetail(params.node!.data.agentID, params.node!.data.policyNum, params.node!.data.regionID);
        }
      },
      'separator',
      {
        name: 'Print Arrears Letter',
        action: () => {
          let sequence = params.node!.data.sequenceNo;
          params.context.getReport(71, sequence);
        },
        disabled: params.node!.data.sequenceNo == 'UNSEQUENCED'
      },
      {
        name: 'Print Collection Sheet',
        action: () => {
          let sequence = params.node!.data.sequenceNo;
          params.context.getReport(70, sequence);
        },
        disabled: params.node!.data.sequenceNo == 'UNSEQUENCED'
      },
      {
        name: 'Print Policy Review Letter',
        action: () => {
          let sequence = params.node!.data.sequenceNo;
          params.context.getReport(91, sequence);
        },
        disabled: params.node!.data.sequenceNo == 'UNSEQUENCED'
      },
      {
        name: 'Print Premium Receipt Book (Back)',
        action: () => {
          params.context.getReport(73);
        },
        disabled: params.node!.data.sequenceNo == 'UNSEQUENCED'
      },
      {
        name: 'Print Premium Receipt Book (Front)',
        action: () => {
          let sequence = params.node!.data.sequenceNo;
          params.context.getReport(72, sequence);
        },
        disabled: params.node!.data.sequenceNo == 'UNSEQUENCED'
      },
      {
        name: 'Print Route List',
        action: () => {
          params.context.getReport(74);
        },
      },
    ];

    return result;
  }

  getPendingCollectionsListContextMenuItems(
    params: GetContextMenuItemsParams
  ): (string | MenuItemDef)[] {
    var result: (string | MenuItemDef)[] = [
      {
        name: 'Collect for Lapsed Policy',
        action: () => {
          params.context.openLapsedCollectionForm();
        }
      },
      'separator',
      {
        name: 'Update Payment',
        action: () => {
          params.context.openCollectionForm();
          params.context.getCollection(params.node!.data);
        }
      },
      {
        name: 'Delete Payment',
        action: () => {
          params.context.deleteCollection(params.node!.data.collectionID);
          params.context.refreshPendingCollectionsList();
        }
      },
    ];

    return result;
  }

  getBatchHistoryListContextMenuItems(
    params: GetContextMenuItemsParams
  ): (string | MenuItemDef)[] {
    var result: (string | MenuItemDef)[] = [
      {
        name: 'Print Batch Details',
        action: () => {
          params.context.getReport(75, "", params.node!.data.collectionBatchID);
        },
      },
    ];

    return result;
  }

  getRoutleListGridIcons(data: any): string {
    var iconString = '';

    switch (data.billFormCode) {
      case '1':
        iconString += '<i class="fad fa-file-invoice-dollar mr-2" style="--fa-primary-color: green; --fa-secondary-color: tan;" aria-hidden="true"></i>';
        break;
      case '2':
        iconString += '<i class="fad fa-landmark mr-2" style="--fa-primary-color: darkgray; --fa-secondary-color: gray;" aria-hidden="true"></i>';
        break;
      case '3':
        iconString += '<i class="fad fa-users mr-2" style="--fa-primary-color: darkblue; --fa-secondary-color: darkred;" aria-hidden="true"></i>';
        break;
      case '4':
        iconString += '<i class="fad fa-bolt mr-2" style="color: gold;" aria-hidden="true"></i>';
        break;
      case 'C':
        iconString += '<i class="fad fa-credit-card mr-2" style="--fa-primary-color: lightgray; --fa-secondary-color: darkred;" aria-hidden="true"></i>';
        break;
      case 'X':
        iconString += '<i class="fad fa-hand-holding-usd mr-2" style="--fa-primary-color: green; --fa-secondary-color: peru;" aria-hidden="true"></i>';
        break;
      default:
        iconString += '';
        break;
    }
    iconString = data.requireFullPayment ? iconString += '<i class="fa fa-exclamation mr-2" style="color: #ff0000;" aria-hidden="true"></i>' : iconString;
    iconString = data.lockPayment === 'YES' ? iconString +=
      '<i class="fad fa-lock" style= "--fa-primary-color: sienna; --fa-secondary-color: goldenrod;" aria-hidden="true"></i>' : iconString;
    return iconString;
  }

  getRoutleListGridTooltip(data: any): string {
    var tipString = data.billFormDesc + "\n";
    tipString = data.requireFullPayment ? tipString += 'Policy is near lapse.  Full payment is required for collection.' + "\n" : tipString;
    tipString = data.lockPayment === 'YES' ? tipString += 'Policy locked due to in-process payment.' : tipString;
    return tipString;
  }

  refreshRouteList() {
    //this.resetDisplayBillingMethods();
    this.getRouteList();
    //this.setBillingMethodCounts();
    //this.setDisplayBillingMethods();
  }

  getPendingCollectionsListGridIcons(data: any): string {
    var iconString = '';

    switch (data.collectionTypeID) {
      case 1:
        iconString = '<i class="fad fa-file-invoice" style="--fa-primary-color: green; --fa-secondary-color: tan;" aria-hidden="true"></i>';
        break;
      case 2:
        iconString = '<i class="fad fa-history" style="--fa-primary-color: darkgray; --fa-secondary-color: navy;" aria-hidden="true"></i>';
        break;
      default:
        iconString = '';
        break;
    }
    return iconString;
  }

  getPendingCollectionsListGridTooltip(data: any): string {
    return data.collectionType + ' Collection';
  }

  refreshPendingCollectionsList() {
    //this.gridApi2?.sizeColumnsToFit();
    this.getPendingCollections();
  }

  refreshBatchHistory() {
    this.gridApi3?.sizeColumnsToFit();
    this.getBatchHistory();
  }

  getReport(reportId: number, sequenceNum: string = "", collectionBatchId: string = ""): void {
    let url: string = `https://apreports.lbig.com/Reports?ReportID=${reportId}&agent=${this.selectedAgentId}&sequenceNo=${sequenceNum}&collectionBatchID=${collectionBatchId}&source=uap&tguid=`;

    this.onPremService.getAgentToken(this.selectedAgentId)
      .pipe(take(1))
      .subscribe(t => {
        let reportURL = url + t.data.token;
        window.open(reportURL, '_blank');
      });
  }

  onClickTabSelect(tab: string) {
    this.selectedTab = tab;
    switch (tab) {
      case 'Pending Collections':
        this.enableRouteListSearch = false;
        this.showBillFormListCheckboxes = false;
        this.showSubmitBatchButton = true;
        this.refreshPendingCollectionsList();
        break;

      case 'Batch History':
        this.enableRouteListSearch = false;
        this.showBillFormListCheckboxes = false;
        this.showSubmitBatchButton = false;
        this.refreshBatchHistory();
        break;

      default:
        this.topRowID = 0;
        this.showBillFormListCheckboxes = true;
        this.showSubmitBatchButton = false;
        this.enableRouteListSearch = true;
        this.refreshRouteList();
    }
  }

  onSearchChanged() {
    this.searchTerm = (document.getElementById('search') as HTMLInputElement).value;
    this.gridApi1?.setQuickFilter(this.searchTerm);
  }

  makeLoanPaymentChecked(e: any) {
    if (e.target.checked) {
      this.isMakeLoanPaymentChecked = true;
    }
    else {
      this.loanPaymentAmount = 0;
      this.isMakeLoanPaymentChecked = false;
    }
    this.calculatePremiumCollected();
  }

  useSuspenseChecked(e: any) {
    if (e.target.checked) {
      this.suspenseAmount = this.suspenseAmount > 0 ? this.suspenseAmount : this.availableSuspenseAmount;
      this.isUseSuspenseChecked = true;
    }
    else {
      this.suspenseAmount = 0;
      this.isUseSuspenseChecked = false;
    }
    this.calculatePremiumCollected();
  }

  onSuspenseAmtChanged(e: any) {
    this.suspenseAmount = +e.target.value;
    this.calculatePremiumCollected();
  }

  makeOverPaymentChecked(e: any) {
    if (e.target.checked) {
      this.isMakeOverPaymentChecked = true;
    }
    else {
      this.overpaymentAmount = 0;
      this.isMakeOverPaymentChecked = false;
    }
    this.calculatePremiumCollected();
  }

  onAmountCollectedChanged(e: any) {
    this.amountCollected = +e.target.value;
    this.calculatePremiumCollected();
  }

  onOverpaymentAmtChanged(e: any) {
    this.overpaymentAmount = +e.target.value;
    this.calculatePremiumCollected();
  }

  onLoanPaymentAmtChanged(e: any) {
    this.loanPaymentAmount = +e.target.value;
    this.calculatePremiumCollected();
  }

  onPolicyNumberChanged(e: any) {
    this.policyNumber = e.target.value;
  }

  calculatePremiumCollected() {
    this.cashAmount = this.modalPremium * this.monthsPaid;
    var totalCollected = this.cashAmount - this.suspenseAmount + this.overpaymentAmount + this.loanPaymentAmount;
    this.premiumCollected = Math.round((totalCollected + Number.EPSILON) * 100) / 100

    var disableSave = this.amountCollected === this.premiumCollected ? false : true;

    this.disableSaveLapsedCollectionButton = disableSave;
    this.disableSaveCollectionButton = disableSave;
  }

  calculatePremiumGroupCollected() {
    this.premiumGroupCollected = this.groupModalPremium * this.monthsGroupPaid;
  }

  autoApply() {
    if ((this.amountCollected >= this.premiumDue && this.showLapseAlert) || (this.amountCollected >= this.modalPremium && !this.showLapseAlert)) {
      let appliedAmount = Math.floor(this.amountCollected / this.modalPremium);
      let appliedOverpayment = Math.round((this.amountCollected - (appliedAmount * this.modalPremium) + Number.EPSILON) * 100) / 100;
      if (appliedAmount >= 1) {
        this.monthsPaid = appliedAmount;
        this.monthlyPremiumPaid = Math.round(((this.monthsPaid * this.modalPremium) + Number.EPSILON) * 100) / 100;
        this.suspenseAmount = 0;
        this.isUseSuspenseChecked = false;
        this.isMakeOverPaymentChecked = appliedOverpayment > 0;
        this.overpaymentAmount = appliedOverpayment > 0 ? appliedOverpayment : 0;
        this.calculatePremiumCollected();
      }
    }
  }

  showLockAlert() {
    alert("The selected policy is locked due to in-process payment");
  }

  showGroupLockAlert() {
    alert("At least one policy in group is locked due to in-process payment");
  }

  showCollectionErrorAlert(message: string) {
    alert(message);
  }

  increaseMonthsPaid() {
    var value = this.monthsPaid;
    value = isNaN(value) ? 0 : value;
    value++;
    this.monthsPaid = value;
    this.monthlyPremiumPaid = Math.round(((this.monthsPaid * this.modalPremium) + Number.EPSILON) * 100) / 100;
    this.calculatePremiumCollected()
  }

  decreaseMonthsPaid() {
    let value = this.monthsPaid;
    let minValue = this.collectionTypeId === 2 ? this.minimumMonths - 1 : this.minimumMonths;
    value = isNaN(value) ? 0 : value;
    value < 1 ? value = 1 : '';
    if (minValue < value) {
      value--;
      this.monthsPaid = value;
      this.monthlyPremiumPaid = Math.round(((this.monthsPaid * this.modalPremium) + Number.EPSILON) * 100) / 100;
      this.calculatePremiumCollected()
    }
  }

  increaseGroupMonthsPaid() {
    var value = this.monthsGroupPaid;
    value = isNaN(value) ? 0 : value;
    value++;
    this.monthsGroupPaid = value;
    this.calculatePremiumGroupCollected();
  }

  decreaseGroupMonthsPaid() {
    var value = this.monthsGroupPaid;
    var minValue = this.minimumMonths;
    value = isNaN(value) ? 0 : value;
    value < 1 ? value = 1 : '';
    if (minValue < value) {
      value--;
      this.monthsGroupPaid = value;
      this.calculatePremiumGroupCollected();
    }
  }

  decimalFilter(event: any) {
    const reg = /^-?\d*(\.\d{0,2})?$/;
    let input = event.target.value + String.fromCharCode(event.charCode);

    if (!reg.test(input)) {
      event.preventDefault();
    }
  }

  decimalCheck(event: any) {
    {
      var charCode = (event.which) ? event.which : event.keyCode;
      if (charCode != 46 && charCode > 31
        && (charCode < 48 || charCode > 57))
        return false;

      return true;
    }
  }

  numberWithDecimal(event: any) {
    var charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57))
      return false;

    const reg = /^-?\d*(\.\d{0,2})?$/;
    let input = event.target.value + String.fromCharCode(charCode);

    if (!reg.test(input))
      return false;

    return true;
  }

  ngOnDestroy() { // It's a good practice to unsubscribe to ensure no memory leaks
    this.channelSubscription.unsubscribe();
  }
}

function insuredAndPayorCellRenderer(params: any) {
  return params.data.insuredName + '<br/>' + params.data.payorName;
}
function insuredAndPayorValueGetter(params: any) {
  return params.data.insuredName + ' ' + params.data.payorName;
}
function policyNumAndAgentIDCellRenderer(params: any) {
  return params.data.policyNum + '<br/>' + params.data.agentID;
}
function policyNumAndAgentIDValueGetter(params: any) {
  return params.data.policyNum + ' ' + params.data.agentID;
}
function paidToDateAndIssueDateCellRenderer(params: any) {
  return params.data.paidToDate + '<br/>' + params.data.issueDate;
}
function paidToDateAndIssueDateValueGetter(params: any) {
  return params.data.paidToDate + ' ' + params.data.issueDate;
}
function premiumDueAndMonthsCellRenderer(params: any) {
  return '$' + params.data.premiumDue.toFixed(2) + '<br/>' + params.data.monthsDue;
}
function premiumDueAndMonthsValueGetter(params: any) {
  return '$' + params.data.premiumDue.toFixed(2) + ' ' + params.data.monthsDue;
}
function suspenseAndLoanBalanceCellRenderer(params: any) {
  return '$' + params.data.availableSuspense.toFixed(2) + '<br/>' + '$' + params.data.currentLoanBalance.toFixed(2);
}
function suspenseAndLoanBalanceValueGetter(params: any) {
  return '$' + params.data.availableSuspense.toFixed(2) + ' ' + params.data.currentLoanBalance.toFixed(2);
}
