import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { DOCUMENT } from '@angular/common';
import { Subscription } from 'rxjs';
import { SiteParameterService } from '../../../services/siteparameter.service'
import { SelectListItem } from '../../../models/selectlistitem';
import { Profile } from '../../../models/profile';
import { APIResponse } from '../../../models/apiresponse';
import { OnPremStorageService } from '../../../services/onprem-storage';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css'],
})
export class NavBarComponent implements OnInit {
  public profile!: Profile;
  public messageReceived: any;
  public showImpersonation = false;
  public showAdmin = false;
  public showEAppLink = false;
  public showContactUsLink = false;
  public showDocumentLink = false;
  public showCollectionsLink = false;
  public showZoomControls = false;
  public showRatesLink = false;
  public systemStatus = '';
  public systemStatusBadgeText = "";
  public systemStatusColor = "";
  public notices: any[] = [];
  public profileComplete = false;
  public initialChannelValue: number = 0;
  public apiResponse!: APIResponse;
  getImpersonationUpdate!: Subscription;
  getParmServiceInit!: Subscription;
  getUnlinkedAgentUpdate!: Subscription;
  currChromeZoom: number = 100;
  currFireFoxZoom: number = 1;
  currZoomValue: string = '100%';
  // Generic Annuity Login with NO access to Document Upload
  private genericAnnuityLogin: string = 'annuity.advisor@lbig.com';

  constructor(private onPremService: OnPremStorageService, public auth: AuthService, private siteParmService: SiteParameterService, @Inject(DOCUMENT) private doc: Document) {
    this.getImpersonationUpdate = this.siteParmService.getImpersonationUpdate().subscribe
      (message => {
        this.profile = this.siteParmService.getStaticProfile();
        this.siteParmService.sendChannelUpdate(message.channelPreferenceValue);
      });
    this.getParmServiceInit = this.siteParmService.getParmServiceInit().subscribe(message => {
      this.profile = siteParmService.getStaticProfile();
      if (this.profile.agents.length > 0) {
        if (this.profile.permissions && this.profile.permissions.find(x => x === "read:impersonation")) {
          this.showImpersonation = true;
        }
        if (this.profile.permissions && this.profile.permissions.find(x => x === "edit:associations" || x === "read:link-code" || x === "read:statements")) {
          this.showAdmin = true;
        }
        this.profileComplete = true;
        this.siteParmService.sendAgentUpdate(this.profile.selectedAgentNumber);
      } else {
        this.auth.user$.subscribe(profile => {
          if (profile.sub) {
            this.profile = <Profile>{};
            this.profile.displayName = profile.nickname;
          }
        });
      }
      this.setShowEAppLink(this.profile.channelPreferenceValue);
      this.setShowContactUsLink(this.profile.channelPreferenceValue);
      this.setShowDocumentsLink(this.profile.channelPreferenceValue);
      this.setShowCollectionsLink(this.profile.channelPreferenceValue);
      this.setShowRatesLink(this.profile.channelPreferenceValue);
    });
    this.getUnlinkedAgentUpdate = this.siteParmService.getunlinkedAgentUpdate().subscribe(message => {      
      this.profileComplete = false;
      this.auth.user$.subscribe(profile => {
        if (profile.sub) {
          this.profile = <Profile>{};
          this.profile.displayName = profile.nickname;
        }
      });
    });
  }
  ngOnInit(): void {
    this.profile = <Profile>{};
     setInterval(() => {
      this.onPremService.systemStatus()
        .subscribe({
          next: (apiResponse) => {
            this.apiResponse = apiResponse;
            this.notices = apiResponse.data.notices;
            this.systemStatus = apiResponse.data.status.message;
            if (this.notices.length > 0) {
              this.systemStatusColor = apiResponse.data.status.color;
              this.systemStatusBadgeText = this.notices.length.toString();
            } else { this.systemStatusBadgeText = ""; }
          },
          error: (err: any) => {
            this.systemStatusBadgeText = "1";
            this.systemStatusColor = "warning";
            let errorNotice = { description: 'API Issue', message: 'Limited Functionality due to connectivity issues' }
            this.notices[0] = errorNotice;
          }
      });
    },
       5000);
  }
  ngOnDestroy() { // It's a good practice to unsubscribe to ensure no memory leaks
    this.getImpersonationUpdate.unsubscribe()
    this.getParmServiceInit.unsubscribe();
    this.getUnlinkedAgentUpdate.unsubscribe();
  }
  logout(): void {
    this.auth.logout({ returnTo: this.doc.location.origin });
  }
  loginWithRedirect(): void {
    this.auth.loginWithRedirect({ appState: { target: '/home' } });
  }
  changeChannel(selectedchannel: number, selectedDescription: string): void {
    this.profile.channelPreference = selectedDescription;
    this.profile.channelPreferenceValue = selectedchannel;
    if (this.profile.agents) {
      var agent = this.profile.agents.find(x => x.channel == selectedchannel);
      this.profile.selectedAgentNumber = agent?.agentNumber || "";
    }
    this.siteParmService.setProfile(this.profile);
    this.siteParmService.sendChannelUpdate(selectedchannel);
    //this.setShowDocumentsLink(this.profile.channelPreferenceValue);
  }
  setShowEAppLink(selectedchannel: number): void {
    if (selectedchannel) {
      this.showEAppLink = selectedchannel === 2 ? false : true;
    }
  }
  setShowContactUsLink(selectedchannel: number): void {
    if (selectedchannel) {
      this.showContactUsLink = selectedchannel === 2 ? false : true;
    }
  }
  setShowDocumentsLink(selectedchannel: number): void {
    //  DISABLED UNTIL Landmark is Ready
    if (selectedchannel) {
      this.onPremService.getFileTargetList(selectedchannel).subscribe(resp => {
        if (resp.count.valueOf() == "0" || this.profile.emailAddress === this.genericAnnuityLogin) {
          this.showDocumentLink = false;
        }
        else
          this.showDocumentLink = true;
      });
    }
  }
  setShowCollectionsLink(selectedchannel: number): void {
    if (selectedchannel) {
      if (selectedchannel === 3 || selectedchannel === 4) {
        this.showCollectionsLink = true;
        this.showZoomControls = true;
      }
      else {
        this.showCollectionsLink = false;
        this.showZoomControls = false;
      }
    }
  }
  setShowRatesLink(selectedchannel: number): void {
    if (selectedchannel) {
      if (selectedchannel === 1 || selectedchannel === 2) {
        this.showRatesLink = true;
      }
      else {
        this.showRatesLink = false;
      }
    }
  }
  zoomOut(): void {
    if (navigator.userAgent.indexOf('Firefox') != -1 && parseFloat(navigator.userAgent.substring(navigator.userAgent.indexOf('Firefox') + 8)) >= 3.6) { //Firefox
      var step = 0.10
      this.currFireFoxZoom -= step;
      $('body').css('MozTransform', 'scale(' + this.currFireFoxZoom + ')');
      this.currZoomValue = this.currFireFoxZoom * 100 + '%';

    } else {
      var step = 10;
      this.currChromeZoom -= step;
      $('body').css('zoom', ' ' + this.currChromeZoom + '%');
      this.currZoomValue = this.currChromeZoom + '%';
    }
  }
  zoomIn(): void {
    if (navigator.userAgent.indexOf('Firefox') != -1 && parseFloat(navigator.userAgent.substring(navigator.userAgent.indexOf('Firefox') + 8)) >= 3.6) { //Firefox
      var step = 0.10;
      this.currFireFoxZoom += step;
      $('body').css('MozTransform', 'scale(' + this.currFireFoxZoom + ')');
      this.currZoomValue = this.currFireFoxZoom * 100 + '%';
    } else {
      var step = 10;
      this.currChromeZoom += step;
      $('body').css('zoom', ' ' + this.currChromeZoom + '%');
      this.currZoomValue = this.currChromeZoom + '%';
    }
  }
}
