import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { AgentDetail } from '../../../models/agentDetail';
import { Profile } from '../../../models/profile';
import { OnPremStorageService } from '../../../services/onprem-storage';
import { SiteParameterService } from '../../../services/siteparameter.service';

@Component({
    selector: 'app-agent-detail',
    templateUrl: './agentdetail.component.html',
    styleUrls: ['./agentdetail.component.css']
})

//@AutoUnsub()
export class AgentDetailComponent implements OnInit {
  private selectedAgentID: string = "";
  profile!: Profile;
  private agentDetailUpdate!: Subscription; //important to create a subscription
  private agentUpdate!: Subscription; //important to create a subscription  
  public agentDetail: AgentDetail = <AgentDetail>{};
  
  constructor(private onPremService: OnPremStorageService, private siteParmService: SiteParameterService) {
     this.agentDetailUpdate = this.siteParmService.getAgentDetailUpdate().subscribe
      (message => {
        this.selectedAgentID = message.value;
        this.getAgentDetail();
      });
    try {
      this.agentUpdate = this.siteParmService.getAgentUpdate().subscribe
        (message => {
          this.selectedAgentID = message.value;
          this.getAgentDetail();
        });
    } catch (error) {}
  }

  ngOnInit(): void {
    this.profile = this.siteParmService.getStaticProfile();
    this.selectedAgentID = this.profile.selectedAgentNumber;
    this.getAgentDetail();
  }

  getAgentDetail(): void {
    this.onPremService.getAgentDetail(this.selectedAgentID)
      .pipe(take(1))
      .subscribe(apiResponse => {
        this.agentDetail = apiResponse.data[0];       
      });
  }
  ngOnDestroy() { // It's a good practice to unsubscribe to ensure no memory leaks
    this.agentUpdate.unsubscribe();
    this.agentDetailUpdate.unsubscribe();
  }
}
