import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment as env } from '../../environments/environment';
import { AuthService } from '@auth0/auth0-angular';
import { Report } from '../models/report';
import { AgentLinkResponse } from '../models/agentLinkResponse';
import { APIResponse } from '../models/apiresponse';
import { map, share } from 'rxjs/operators';
import { AgentLink } from '../models/agentLink';
import { KPI } from '../models/kpi';
import { Policy } from '../models/policy';
import { AgentDownline } from '../models/downline';
import { policySearchResponse } from '../models/policysearchresponse';
import { PolicyRoute } from '../models/policyRoute';
import { EAppConfig } from '../models/eappconfig';
import { AgentGrid } from '../models/agentGrid';
import { Agent } from '../models/agent';

@Injectable({
  providedIn: 'root'
})
export class OnPremStorageService {
  private httpOptions = {};

  constructor(
    private http: HttpClient, public auth: AuthService) { }
  public getReports(agentID: string): Observable<APIResponse> {
    return this.http.get<APIResponse>(`${env.dev.onPremServerUrl + 'reports?agentid=' + agentID}`, this.httpOptions);
  }
  public getAgentToken(agentID: string): Observable<any> {
    return this.http.get<any>(`${env.dev.onPremServerUrl + 'reports/token?agentid=' + agentID}`);
  }
  public linkAgent(email: string, agentId: string, ssn: string, dob: string, linkCode: string): Observable<APIResponse> {
    return this.http.post<APIResponse>(`${env.dev.onPremServerUrl + 'agentLink/'}`, { emailAddress: email, InputAgentID: agentId, InputLast4SSN: ssn, InputDOB: dob, LinkCode: linkCode });
  }
  public unLinkAgent(): Observable<APIResponse> {
    return this.http.get<APIResponse>(`${env.dev.onPremServerUrl + 'agentLink/delete'}`);
  }
  public deleteAgentLink(): Observable<void> {
    return this.http.get<void>(`${env.dev.onPremServerUrl + 'agentLink/Delete'}`);
  }
  public systemStatus(): Observable<APIResponse> {
    return this.http.get<APIResponse>(`${env.dev.onPremServerUrl + 'systemStatus'}`);
  }
  public getAgentList(): Observable<AgentLink[]> {
    return this.http.get<AgentLink[]>(`${env.dev.onPremServerUrl + 'agentlink'}`)
      .pipe(map((res: any) => res.data));
  }
  public getAgentDetail(agentId: string): Observable<APIResponse> {
    const params = `agentid=${agentId}`;
    return this.http.get<APIResponse>(`${env.dev.onPremServerUrl + 'agent/info?' + params}`);
  }
  public getAgentHierarchyList(agentId: string): Observable<APIResponse> {
    const params = `agentid=${agentId}`;
    return this.http.get<APIResponse>(`${env.dev.onPremServerUrl + 'agent/hierarchylist?' + params}`);
  }
  public getLinkedAgentDetail(agentId: string): Observable<APIResponse> {
    const params = `agentid=${agentId}`;
    return this.http.get<APIResponse>(`${env.dev.onPremServerUrl + 'agentlink/info?' + params}`);
  }
  public getLinkedAgentList(auth0Id: string, channelId: number, hideFireAgents: boolean): Observable<APIResponse> {
    const params = `authuserid=${auth0Id}&ChannelId=${channelId}&hideFireAgents=${hideFireAgents}`;
    return this.http.get<APIResponse>(`${env.dev.onPremServerUrl + 'agentlink/List?' + params}`);
  }
  public getAgentDownlineList(agentId: string): Observable<AgentDownline[]> {
    const params = `agentid=${agentId}`;
    return this.http.get<AgentDownline[]>(`${env.dev.onPremServerUrl + 'agent/downlinelist?' + params}`).pipe(map((res: any) => res.data));
  }
  public getAgentWithDownine(agentLinkId: number, channelId: number, hideFireAgents: boolean): Observable<Agent[]> {
    const params = `agentlinkid=${agentLinkId}&ChannelId=${channelId}&hidefireagents=${hideFireAgents}`;
    return this.http.get<Agent[]>(`${env.dev.onPremServerUrl + 'agentlink/WithDownline?' + params}`)
      .pipe(map((res: any) => res.data));
  }
  public getAgentWithDownineList(agentId: string): Observable<Agent[]> {
    const params = `agentid=${agentId}`;
    return this.http.get<Agent[]>(`${env.dev.onPremServerUrl + 'agentlink/WithDownlineList?' + params}`)
      .pipe(map((res: any) => res.data));
  }
  public getUnassociatedLinkedAgentList(): Observable<APIResponse> {
    return this.http.get<APIResponse>(`${env.dev.onPremServerUrl + 'agent/unassociatedList?'}`);
  }
  public getAgentActiveHierarchyListWithSelf(agentId: string, hideFireAgents: boolean): Observable<APIResponse> {
    const params = `agentid=${agentId}&hidefireagents=${hideFireAgents}`;
    return this.http.get<APIResponse>(`${env.dev.onPremServerUrl + 'agent/ActiveHierarchyListIncSelf?' + params}`);
  }
  public getPolicies(agentId: string): Observable<APIResponse> {
    const params = `agentid=${agentId}`;
    return this.http.get<APIResponse>(`${env.dev.onPremServerUrl + 'policies?' + params}`);
  }
  public getPolicyDetail(agentId: string, policyNumber: string, regionId: number, channelId: number): Observable<APIResponse> {
    return this.http.get<APIResponse>(`${env.dev.onPremServerUrl + 'policies?agentid=' + agentId + '&policyNumber=' + policyNumber + '&regionId=' + regionId + '&channelId=' + channelId}`);
  }
  public searchPolicies(agentId: string, status: string, startDate: string, endDate: string, included: number, channel: string): Observable<policySearchResponse> {
    var endpoint = channel == 'Annuity' || channel == 'Annuity [Bank]' ? 'annuityquicksearch?' : 'quicksearch?';
    const params = `agentid=${agentId}&status=${status}&startdate=${startDate}&enddate=${endDate}&includedagents=${included}`;
    return this.http.get<APIResponse>(`${env.dev.onPremServerUrl + 'policies/' + endpoint + params}`).pipe(map((res: any) => res.data));
  }
  public searchAdvancedPolicies(agentId: string, selectedAgentID: string, downlineAgentId: string, status: string, startDate: string, endDate: string, insuredName: string,
    addressOrCity: string, phoneNumber: string, policyNumber: string, channel: string): Observable<policySearchResponse> {
    var endpoint = channel == 'Annuity' || channel == 'Annuity [Bank]' ? 'annuityadvancedsearch?' : 'advancedsearch?';
    const params = `agentid=${agentId}&selectedagentid=${selectedAgentID}&downlineagentid=${downlineAgentId}&status=${status}&startdate=${startDate}&enddate=${endDate}&insuredname=${insuredName}&addressorcity=${addressOrCity}&phone=${phoneNumber}&policynumber=${policyNumber}`;
    return this.http.get<APIResponse>(`${env.dev.onPremServerUrl + 'policies/' + endpoint + params}`).pipe(map((res: any) => res.data));
  }
  public searchKpiPolicies(agentId: string, kpiId: number, included: number, channel: string): Observable<policySearchResponse> {
    var endpoint = channel == 'Annuity' || channel == 'Annuity [Bank]' ? 'annuitykpi?' : 'kpi?';
    const params = `agentid=${agentId}&kpiid=${kpiId}&includedagents=${included}`;
    return this.http.get<APIResponse>(`${env.dev.onPremServerUrl + 'policies/' + endpoint + params}`).pipe(map((res: any) => res.data));
  }
  public getGridColumnList(channelId: number, name: string): Observable<APIResponse> {
    const params = `channelid=${channelId}&name=${name}`;
    return this.http.get<APIResponse>(`${env.dev.onPremServerUrl + 'grid/columnlist?' + params}`);
  }
  public downloadStatement(statementId: number) {
    const params = `${statementId}`;
    return this.http.get(`${env.dev.onPremServerUrl + 'statements/' + params}`, { responseType: 'blob' });
  }
  public searchStatements(agentId: string, type: number, startDate: string, endDate: string, included: number): Observable<APIResponse> {
    const params = `agentid=${agentId}&type=${type}&startdate=${startDate}&enddate=${endDate}&includedagents=${included}`;
    var x = env.dev.onPremServerUrl + 'statements/search?' + params;
    return this.http.get<APIResponse>(x);
  }
  public getKpis(agentId: string): Observable<APIResponse> {
    const params = `agentid=${agentId}`;
    return this.http.get<APIResponse>(`${env.dev.onPremServerUrl + 'kpi?' + params}`, this.httpOptions);
  }
  public getKpiList(agentId: string, policySearch: boolean): Observable<KPI[]> {
    const params = `agentid=${agentId}&policysearchonly=${policySearch}`;
    return this.http.get<KPI[]>(`${env.dev.onPremServerUrl + 'kpi/list?' + params}`, this.httpOptions)
      .pipe(map((res: any) => res.data));
  }
  public getEAppInfo(agentId: string): Observable<APIResponse> {
    const params = `agentid=${agentId}`;
    return this.http.get<APIResponse>(`${env.dev.onPremServerUrl + 'agent/EAppUser?' + params}`, this.httpOptions)
      .pipe(map((res: APIResponse) => res.data));
  }
  public getAssociations(): Observable<APIResponse> {
    return this.http.get<APIResponse>(`${env.dev.onPremServerUrl + 'agentassociation'}`);
  }
  public getAssociation(agentLinkAssociationID: number): Observable<APIResponse> {
    return this.http.get<APIResponse>(`${env.dev.onPremServerUrl + 'agentassociation/' + agentLinkAssociationID}`);
  }
  public addAssociation(agentID: string, channelID: number, regionID: number, associatedAgentID: string, associatedRegionID: number): Observable<any> {
    return this.http.post<any>(`${env.dev.onPremServerUrl + 'agentassociation'}`,
      { agentId: agentID, channelId: channelID, regionId: regionID, associatedAgentId: associatedAgentID, associatedRegionId: associatedRegionID });
  }
  public updateAssociation(agentLinkAssociationID: number, associatedAgentID: string, associatedRegionID: number): Observable<any> {
    return this.http.put<any>(`${env.dev.onPremServerUrl + 'agentassociation/' + agentLinkAssociationID}`, { associatedAgentId: associatedAgentID, associatedRegionId: associatedRegionID });
  }
  public deleteAssociation(agentLinkAssociationID: number): Observable<any> {
    return this.http.delete<any>(`${env.dev.onPremServerUrl + 'agentassociation/delete/' + agentLinkAssociationID}`);
  }
  public getExternalLink(linkUri: string): Observable<any> {
    return this.http.get<any>(linkUri, this.httpOptions);
  }
  public getAgentLinkCodeRecords(): Observable<APIResponse> {
    return this.http.get<APIResponse>(`${env.dev.onPremServerUrl + 'agentlink/code'}`);
  }
  public getAgentLinkCodeRecord(agentLinkCodeId: number): Observable<APIResponse> {
    return this.http.get<APIResponse>(`${env.dev.onPremServerUrl + 'agentlink/code/' + agentLinkCodeId}`);
  }
  public addAgentLinkCodeRecord(emailAddress: string): Observable<any> {
    return this.http.post<any>(`${env.dev.onPremServerUrl + 'agentlink/code'}`,
      { emailAddress: emailAddress });
  }
  public updateAgentLinkCodeRecord(agentLinkCodeId: number, agentId: string): Observable<any> {
    return this.http.put<any>(`${env.dev.onPremServerUrl + 'agentlink/code/' + agentLinkCodeId}`, { agentID: agentId });
  }
  public getFileTargetList(channelId: number): Observable<APIResponse> {
    const params = `channelid=${channelId}`;
    return this.http.get<APIResponse>(`${env.dev.onPremServerUrl + 'file/targets?' + params}`);
  }
  public getFileUploadList(auth0Id: string): Observable<APIResponse> {
    const params = `authuserid=${auth0Id}`;
    return this.http.get<APIResponse>(`${env.dev.onPremServerUrl + 'file/uploadlist?' + params}`);
  }
  public getRouteList(agentId: string, billFormCodeList: string): Observable<APIResponse> {
    const params = `agentid=${agentId}&billformcodelist=${billFormCodeList}`;
    return this.http.get<APIResponse>(`${env.dev.onPremServerUrl + 'collections/routelist?' + params}`);
  }
  public getCollection(collectionId: string): Observable<APIResponse> {
    const params = `collectionid=${collectionId}`;
    return this.http.get<APIResponse>(`${env.dev.onPremServerUrl + 'collections?' + params}`);
  }
  public saveCollection(collectionId: string, collectionTypeId: number, regionId: number, policyNum: string, agentId: string, collectionAgentId: string, monthsPaid: number,
    cashAmt: number, suspenseAmt: number, overpaymentAmt: number, loanPaymentAmt: number, userId: string, modalPremium: number, currentCommRate: number, insuredName: string,
    payorName: string, sequenceNo: string): Observable<APIResponse> {
    return this.http.post<APIResponse>(`${env.dev.onPremServerUrl + 'collections'}`, {
      collectionId: collectionId ? collectionId : null, collectionTypeId: collectionTypeId, regionId: regionId, policyNum: policyNum,
      agentID: agentId, collectionAgentId: collectionAgentId, monthsPaid: monthsPaid, cashAmt: cashAmt, suspenseAmt: suspenseAmt, overpaymentAmt: overpaymentAmt,
      loanPaymentAmt: loanPaymentAmt, userId: userId, modalPremium: modalPremium, currentCommRate: currentCommRate, insuredName: insuredName, payorName: payorName,
      sequenceNo: sequenceNo
    });
  }
  public deleteCollection(collectionId: string): Observable<APIResponse> {
    return this.http.delete<APIResponse>(`${env.dev.onPremServerUrl + 'collections/delete/' + collectionId}`);
  }
  public async saveGroupCollection(routeSequenceId: string, agentId: string, collectionAgentId: string, monthsPaid: number, cashAmt: number, userId: string): Promise<Observable<APIResponse>> {
    return (await this.http.post<APIResponse>(`${env.dev.onPremServerUrl + 'collections/group'}`, {
      RouteSequenceId: routeSequenceId, AgentID: agentId, CollectionAgentId: collectionAgentId, MonthsPaid: monthsPaid, CashAmt: cashAmt, UserId: userId
    }).toPromise()).data;
  }
  public async deleteGroupCollection(routeSequenceId: string): Promise<Observable<APIResponse>> {
    return (await this.http.delete<APIResponse>(`${env.dev.onPremServerUrl + 'collections/group/delete/' + routeSequenceId}`).toPromise()).data;
  }
  public saveSequence(regionId: number, policyNum: string, agentId: string, sequenceNum: string, userId: string): Observable<APIResponse> {
    return this.http.post<APIResponse>(`${env.dev.onPremServerUrl + 'collections/sequence'}`, {
      RegionId: regionId, PolicyNum: policyNum, AgentID: agentId, SequenceNum: sequenceNum, UserId: userId });
  }
  public getSequenceContact(routeSequenceId: string): Observable<APIResponse> {
    const params = `routesequenceId=${routeSequenceId}`;
    return this.http.get<APIResponse>(`${env.dev.onPremServerUrl + 'collections/sequencecontact?' + params}`);
  }
  public saveSequenceContact(routeSequenceId: string, sequenceNum: string, name: string, addressLine1: string, addressLine2: string, city: string,
    state: string, zip: string, emailAddress: string, phone: string, notes: string, userId: string): Observable<APIResponse> {
    return this.http.post<APIResponse>(`${env.dev.onPremServerUrl + 'collections/sequencecontact'}`, {
      RouteSequenceId: routeSequenceId, SequenceNum: sequenceNum, Name: name, AddressLine1: addressLine1, AddressLine2: addressLine2, City: city,
      State: state, Zip: zip, EmailAddress: emailAddress, Phone: phone, Notes: notes, UserId: userId });
  }
  public getPendingCollections(agentId: string): Observable<APIResponse> {
    const params = `agentid=${agentId}`;
    return this.http.get<APIResponse>(`${env.dev.onPremServerUrl + 'collections/pendingList?' + params}`);
  }
  public getBatchHistory(agentId: string): Observable<APIResponse> {
    const params = `agentid=${agentId}`;
    return this.http.get<APIResponse>(`${env.dev.onPremServerUrl + 'collections/batchhistory?' + params}`);
  }
  public async insertCollectionBatch(agentId: string): Promise<Observable<APIResponse>> {
    return (await this.http.post<APIResponse>(`${env.dev.onPremServerUrl + 'collections/batch'}`, {
      agentID: agentId}).toPromise()).data;
  }
  public getLapsedPolicy(agentId: string, policyNumber: string): Observable<APIResponse> {
    const params = `agentid=${agentId}&policynumber=${policyNumber}`;
    return this.http.get<APIResponse>(`${env.dev.onPremServerUrl + 'collections/lapsedpolicysearch?' + params}`);
  }
  public downloadDocument(documentId: number) {
    const params = `${documentId}`;
    return this.http.get(`${env.dev.onPremServerUrl + 'policies/documents/' + params}`, { responseType: 'blob' });
  }
  public getAnnuityDeferredRates(channelId: number, rateType: string, caOnly: boolean): Observable<APIResponse> {
    const params = `channelid=${channelId}&ratetype=${rateType}&caonly=${caOnly}`;
    return this.http.get<APIResponse>(`${env.dev.onPremServerUrl + 'annuityrates/deferred?' + params}`, this.httpOptions);
  }
  public getAnnuityImmediateRates(channelId: number, rateType: string, caOnly: boolean): Observable<APIResponse> {
    const params = `channelid=${channelId}&ratetype=${rateType}&caonly=${caOnly}`;
    return this.http.get<APIResponse>(`${env.dev.onPremServerUrl + 'annuityrates/immediate?' + params}`, this.httpOptions);
  }
  public getAnnuityFixedIndexRates(channelId: number, rateType: string, caOnly: boolean): Observable<APIResponse> {
    const params = `channelid=${channelId}&ratetype=${rateType}&caonly=${caOnly}`;
    return this.http.get<APIResponse>(`${env.dev.onPremServerUrl + 'annuityrates/fixedindex?' + params}`, this.httpOptions);
  }
  public trackEvent(eventId: number, userId: string, eventData: string, agentId: string, channelId: number): Observable<any> {
    return this.http.post<any>(`${env.dev.onPremServerUrl + 'siteanalytics/event'}`,
      { eventId: eventId, userId: userId, eventData: eventData, agentId: agentId, channelId: channelId });
  }
}
