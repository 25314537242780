<div class="nav-container mb-8">
  <nav class="navbar navbar-expand-md navbar-light bg-light">
    <div class="container-fluid justify-content-center">
      <ul class="nav navbar-nav" id="menu" style="border: 1px solid black; border-radius:10px;">
        <li class="nav-item">
          <a class="nav-link" id="toverview-tab" data-toggle="tab" href="#tOverview" (click)="updatePreference(true)">LBIG Overview</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="tchannel-tab" data-toggle="tab" href="#tChannel" (click)="updatePreference(false)">{{profile ? profile.channelPreference : ""}}</a>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link" data-toggle="dropdown" href="#" aria-expanded="false">
            <span><b>View By:&nbsp;&nbsp;{{activeFilter}}</b></span>&nbsp;&nbsp;<span><i class="fas fa-angle-down"></i></span>
          </a>
          <div class="dropdown-menu dropdown-menu-sm dropdown-menu-right">
            <span class="dropdown-item">
              <a class="nav-link" (click)="toggleView('Category');"><small>Category</small></a>
            </span>
            <span class="dropdown-item">
              <a class="nav-link" (click)="toggleView('Type');"><small>Type</small></a>
            </span>
            <span class="dropdown-item">
              <a class="nav-link" (click)="toggleView('Name');"><small>Name</small></a>
            </span>
            <span class="dropdown-item">
              <a class="nav-link" (click)="toggleView('Latest');"><small>Latest</small></a>
            </span>
          </div>
        </li>
      </ul>
    </div>
  </nav>
</div>
<div class="tab-content clearfix" id="tabPanes">
  <div class="tab-pane" id="tOverview">
    <div *ngIf="showGroup">
      <div class="row" *ngFor="let group of overviewGroupList">
        <div class="col-md-12">
          <div class="card card-secondary">
            <div class="card-header">
              <div class="card-title">
                <h3><b>{{group.title}}</b></h3>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div *ngFor="let item of group.content" class="col-md-3">
                  <div class="info-box grow" style="cursor: pointer;">
                    <span class="info-box-icon bg-gradient-light">
                      <i class="{{item.icon}}"></i>
                    </span>
                    <div class="info-box-content"  (click)="getItem(item.linkUrl)">
                      <div class="info-box-number" style="white-space: normal !important;">{{item.title}}</div>
                      <div class="info-box-text text-wrap"><small>{{item.description}}&nbsp;</small></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="showList" class="row">
      <div class="col-md-12">
        <ag-grid-angular style="height: 500px;"
                         class="ag-theme-alpine"
                         [rowData]="overViewList"
                         [columnDefs]="columnDefs"
                         rowSelection='single'
                         [suppressRowClickSelection]="true"
                         (gridReady)="onGrid1Ready($event)"
                         [overlayNoRowsTemplate]="noRowsTemplate"
                         (rowClicked)="onRowClicked($event)">
        </ag-grid-angular>
      </div>
    </div>
    <div *ngIf="showNew">
      <div class="row" *ngFor="let group of overviewNewList">
        <div class="col-md-12">
          <div class="card card-secondary">
            <div class="card-header">
              <div class="card-title">
                <h3><b>{{group.title}}</b></h3>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div *ngFor="let item of group.content" class="col-md-3">
                  <div class="info-box grow" style="cursor: pointer;">
                    <span class="info-box-icon bg-gradient-light" (click)="getItem(item.linkUrl)">
                      <i class="{{item.icon}}"></i>
                    </span>
                    <div class="info-box-content" (click)="getItem(item.linkUrl)">
                      <div class="info-box-number" style="white-space: normal !important;">{{item.title}}</div>
                      <div class="info-box-text  text-wrap"><small>{{item.description}}&nbsp;</small></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="tab-pane" id="tChannel">
    <div *ngIf="showGroup">
      <div class="row" *ngFor="let group of channelGroupList">
        <div class="col-md-12">
          <div class="card card-secondary">
            <div class="card-header">
              <div class="card-title">
                <h3><b>{{group.title}}</b></h3>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div *ngFor="let item of group.content" class="col-md-3">
                  <div class="info-box grow" style="cursor: pointer;">
                    <span class="info-box-icon bg-gradient-light" (click)="getItem(item.linkUrl)">
                      <i class="{{item.icon}}"></i>
                    </span>
                    <div class="info-box-content" (click)="getItem(item.linkUrl)">
                      <div class="info-box-number" style="white-space: normal !important;">{{item.title}}</div>
                      <div class="info-box-text text-wrap"><small>{{item.description}}&nbsp;</small></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="showList" class="row">
      <div class="col-md-12">
        <ag-grid-angular style="height: 600px;"
                         class="ag-theme-alpine"
                         [rowData]="channelList"
                         [columnDefs]="columnDefs"
                         rowSelection='single'
                         [suppressRowClickSelection]="true"
                         (gridReady)="onGrid2Ready($event)"
                         [overlayNoRowsTemplate]="noRowsTemplate"
                         (rowClicked)="onRowClicked($event)">
        </ag-grid-angular>
      </div>
    </div>
    <div *ngIf="showNew">
      <div class="row" *ngFor="let group of channelNewList">
        <div class="col-md-12">
          <div class="card card-secondary">
            <div class="card-header">
              <div class="card-title">
                <h3><b>{{group.title}}</b></h3>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div *ngFor="let item of group.content" class="col-md-3">
                  <div class="info-box grow" style="cursor: pointer;">
                    <span class="info-box-icon bg-gradient-light" (click)="getItem(item.linkUrl)">
                      <i class="{{item.icon}}"></i>
                    </span>
                    <div class="info-box-content" (click)="getItem(item.linkUrl)">
                      <div class="info-box-number" style="white-space: normal !important;">{{item.title}}</div>
                      <div class="info-box-text text-wrap"><small>{{item.description}}&nbsp;</small></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



