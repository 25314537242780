import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeroComponent } from './presentation/components/hero/hero.component';
import { AgentGridComponent } from './presentation/components/agentgrid/agentgrid.component';
import { ChannelNewsComponent } from './presentation/components/channelnews/channelnews.component';
import { LoadingComponent } from './presentation/components/loading/loading.component';
import { ComponentLoadingComponent } from './presentation/components/componentloading/componentloading.component';
import { NavBarComponent } from './presentation/shared/nav-bar/nav-bar.component';
import { LoginPageComponent } from './presentation/pages/login/login.component';
import { AnnuityBankRepLoginPageComponent } from './presentation/pages/annuitybankreplogin/annuitybankreplogin.component';
import { AnnuityLoginPageComponent } from './presentation/pages/annuitylogin/annuitylogin.component';
import { RatesPageComponent } from './presentation/pages/rates/rates.component';
import { AnnuityRatesComponent } from './presentation/components/annuityrates/annuityrates.component';
import { AnnuityBankRatesComponent } from './presentation/components/annuitybankrates/annuitybankrates.component';
import { HomeComponent } from './presentation/pages/home/home.component';
import { BriefcaseComponent } from './presentation/pages/briefcase/briefcase.component';
import { UserListPageComponent } from './presentation/pages/userlist/userslist.component';
import { ProfileComponent } from './presentation/pages/profile/profile.component';
import { CompleteProfileComponent } from './presentation/pages/completeprofile/completeprofile.component';
import { TermsPageComponent } from './presentation/pages/terms/terms.component';
import { ProductsComponent } from './presentation/pages/products/products.component';
import { TrainingComponent } from './presentation/pages/training/training.component';
import { EAppComponent } from './presentation/pages/eapp/eapp.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HttpClientModule } from '@angular/common/http';
import { AuthModule } from '@auth0/auth0-angular';
import { environment as env } from '../environments/environment';
import { NO_ERRORS_SCHEMA } from '@angular/core';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { ToastrModule } from 'ngx-toastr';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { KpisComponent } from './presentation/components/kpis/kpis.component';
import { AgentPickerComponent } from './presentation/components/agentpicker/agentpicker.component';
import { AgentDetailComponent } from './presentation/components/agentdetail/agentdetail.component';
import { AgentInfoMessageComponent } from './presentation/components/agentinfomessage/agentinfomessage.component';
import { CommissionsComponent } from './presentation/components/commissions/commissions.component';
import { FormsPageComponent } from './presentation/pages/forms/forms.component';
import { PolicyInfoComponent } from './presentation/components/policyinfo/policyinfo.component';
import { ReportsComponent } from './presentation/components/reports/reports.component';
import { QuickLinksInfoComponent } from './presentation/components/quicklinks/quicklinks.component';
import { TokenInterceptor } from './authentication/tokeninterceptor';
import { AgentHierarchyComponent } from './presentation/components/agent-hierarchy/agent-hierarchy.component';
import { YesNoPipe } from './common/yes-no.pipe';
import { DatePipe } from '@angular/common';
import { PhonePipe } from './common/phone.pipe';
import { ReplacePipe } from './common/replace';
//import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { RollbarErrorHandler, rollbarFactory, RollbarService } from './core/rollbar';
import { AgGridModule } from 'ag-grid-angular';
import 'ag-grid-enterprise';
import { ContactPageComponent } from './presentation/pages/contact/contact.component';
import { AdminPageComponent } from './presentation/pages/admin/admin.component';
import { AssociationsComponent } from './presentation/components/associations/associations.component';
import { LinkCodeComponent } from './presentation/components/link-code/link-code.component';
import { StatementSearchComponent } from './presentation/components/statement-search/statement-search.component';
import { AgGridResizeDirective } from './common/ag-grid-resize.directive';
import { DocumentsPageComponent } from './presentation/pages/documents/documents.component';
import { FileUploadComponent } from './presentation/components/fileupload/fileupload.component';
import { EAppRedirectComponent } from './presentation/pages/eappredirect/eappredirect.component';
import { RedirectingComponent } from './presentation/pages/redirecting/redirecting.component';
import { CollectionsPageComponent } from './presentation/pages/collections/collections.component';
import { RouteListComponent } from './presentation/components/routelist/routelist.component';
import { BatchHistoryComponent } from './presentation/components/batchhistory/batchhistory.component';
import { PendingCollectionsComponent } from './presentation/components/pendingcollections/pendingcollections.component';
import { GridTooltipComponent } from './presentation/components/grid-tooltip/grid-tooltip.component';

@NgModule({

  declarations: [
    AppComponent,
    HeroComponent,
    LoadingComponent,
    ComponentLoadingComponent,
    NavBarComponent,
    HomeComponent,
    ProfileComponent,
    KpisComponent,
    BriefcaseComponent,
    AgentPickerComponent,
    AgentDetailComponent,
    AgentInfoMessageComponent,
    CommissionsComponent,
    FormsPageComponent,
    PolicyInfoComponent,
    LoginPageComponent,
    AnnuityLoginPageComponent,
    AnnuityBankRepLoginPageComponent,
    RatesPageComponent,
    AnnuityBankRatesComponent,
    AnnuityRatesComponent,
    ReportsComponent,
    TrainingComponent,
    EAppComponent,
    ProductsComponent,
    QuickLinksInfoComponent,
    TermsPageComponent,
    ChannelNewsComponent,
    CompleteProfileComponent,
    AgentGridComponent,
    UserListPageComponent,
    AgentHierarchyComponent,
    ContactPageComponent,
    AdminPageComponent,
    AssociationsComponent,
    LinkCodeComponent,
    StatementSearchComponent,
    AgGridResizeDirective,
    DocumentsPageComponent,
    FileUploadComponent,
    YesNoPipe,
    PhonePipe,
    ReplacePipe,
    EAppRedirectComponent,
    RedirectingComponent,
    CollectionsPageComponent,
    RouteListComponent,
    BatchHistoryComponent,
    PendingCollectionsComponent,
    GridTooltipComponent
  ],
  exports: [    
  ],
  imports: [
    MDBBootstrapModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FontAwesomeModule,
    MDBBootstrapModule.forRoot(),    
    ToastrModule.forRoot(),
    BrowserAnimationsModule,
    FormsModule,
    CommonModule,
    AgGridModule.withComponents([]),    
    //NgbDatepickerModule,
    NgbTooltipModule,
    AuthModule.forRoot({
      ...env.auth,
      httpInterceptor: {
        allowedList: [`${env.dev.serverUrl}/datawidget/*`],
      },
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    DatePipe,
    { provide: ErrorHandler, useClass: RollbarErrorHandler },
    { provide: RollbarService, useFactory: rollbarFactory }
  ],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA]
})
export class AppModule {}
