import { Component, AfterViewInit, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { SiteParameterService } from '../../../services/siteparameter.service';
import { Profile } from '../../../models/profile';

@Component({
  selector: 'app-briefcase',
  templateUrl: './briefcase.component.html',
  styles: [
  ]
})
export class BriefcaseComponent implements OnInit, AfterViewInit {
  profile!: Profile;
  private channelUpdate: Subscription;
  public selectedBusinessChannel: number = 0;
  private kpiSearchSubscription: Subscription;


  constructor(private siteParmService: SiteParameterService) {
    this.kpiSearchSubscription = this.siteParmService.getkpiSearchUpdate().subscribe
      (message => {
        $('#Performance-tab').removeClass("router-link-exact-active");
        $('#Performance-tab').removeClass("active");
        $('#Policy-tab').addClass('router-link-exact-active');
        $('#Performance').removeClass("tab-pane active");
        $('#Performance').addClass("tab-pane");
        $('#Policy').addClass('tab-pane active');
      });
    this.channelUpdate = this.siteParmService.getChannelUpdate().subscribe
      (message => {
        this.profile = this.siteParmService.getStaticProfile();
        this.getChannel();
      });
  }

  ngAfterViewInit(): void {
    $('#menu li a').click(function () {
      if (this.parentElement?.className.indexOf('dropdown') == -1) {
        $('#menu').find("*").removeClass("router-link-exact-active");
        $(this).addClass('router-link-exact-active');
      }
    });
  }

  ngOnInit(): void {
    this.profile = this.siteParmService.getStaticProfile();
    this.getChannel();
  }

  getChannel(): void {
    this.selectedBusinessChannel = this.profile ? this.profile.channelPreferenceValue : 0;
  }

  ngOnDestroy() { // It's a good practice to unsubscribe to ensure no memory leaks
    this.kpiSearchSubscription.unsubscribe();
    this.channelUpdate.unsubscribe();
  }
}
