import { Component, OnInit } from '@angular/core';
import { RowSelectedEvent } from 'ag-grid-community';
import { DatePipe } from '@angular/common';
import { Statement } from '../../../models/statement';
import { ReturnDateRangeFromType } from '../../../common/helpers';
import { OnPremStorageService } from '../../../services/onprem-storage';
import { SiteParameterService } from '../../../services/siteparameter.service';
import { Profile } from '../../../models/profile';
import { map, take } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-statementsearch',
  templateUrl: './statement-search.component.html',
  styles: [
  ]
})
export class StatementSearchComponent implements OnInit {
  public showSearch: boolean = false;
  public agentNumber: string = ""; 
  policyType: number = 0;
  timeFrame: string = "ALL";
  timeFrameEnable: boolean = true;
  startDate: Date = new Date("");
  endDate: Date = new Date("");
  includedAgents: number = 0;
  public statements$?: Observable<Statement[]>;
  private gridApi: any;
  private gridColumnApi: any;
  public noRowsTemplate: string;

  columnDefs = [
    { field: 'date' },
    { field: 'agentID' },
    { field: 'agentName' },
    { field: 'type' },
    { headerName: 'Download', cellRenderer: (params: any) => { return '<i class="fa fa-download"></i>'; } },
  ];
  defaultColumnDef = {
    sortable: true,
    resizable: true,
  };

  constructor(private onPremService: OnPremStorageService, private siteParmService: SiteParameterService, private datePipe: DatePipe) {
    this.noRowsTemplate = this.siteParmService.getGridNoResults();
  }

  ngOnInit(): void {
  }

  changeTimeFrame(e: any) {
    this.timeFrame = e.target.value;
    let [start, end] = ReturnDateRangeFromType(this.timeFrame);
    this.startDate = start!;
    this.endDate = end!;
  }

  searchStatements(): void {
    this.showSearch = true;
    let [start, end] = ReturnDateRangeFromType(this.timeFrame);
    this.startDate = start!;
    this.endDate = end!;
    const startDate = (this.startDate != null ? this.datePipe.transform(this.startDate, 'MM/dd/yyyy') : "") as string;
    const endDate = (this.endDate != null ? this.datePipe.transform(this.endDate, 'MM/dd/yyyy') : "") as string;

    this.statements$ = this.onPremService.searchStatements(this.agentNumber, this.policyType, startDate, endDate,
      this.includedAgents).pipe(map(response => response.data));
  }

  resetSearch(): void {
    this.agentNumber = "";
    this.timeFrame = "ALL";
    this.showSearch = false;
  }

  onRowDoubleClicked(event: RowSelectedEvent) {
    const statement: Statement = event.data;
    this.onPremService.downloadStatement(statement.statementID)
      .pipe(take(1))
      .subscribe(data => {
        const binaryData = [];
        binaryData.push(data);
        const downloadedFile = new Blob(binaryData, { type: data.type });
        const a = document.createElement('a');
        a.setAttribute('style', 'display:none;');
        document.body.appendChild(a);
        a.download = event.data.date.replace('/', '_').trim() + "_" + event.data.agentID.trim() + "_" + event.data.type.trim() + "_stmt.pdf";
        a.href = URL.createObjectURL(downloadedFile);
        a.target = '_blank';
        a.click();
        document.body.removeChild(a);
      });
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
  }
}
