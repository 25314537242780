import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { ColSpanParams, ColDef } from 'ag-grid-community';
import { take } from 'rxjs/operators';
import { channelProduct } from '../../../models/channelProduct';
import { CloudStorageService } from '../../../services/cloud-storage';
import { OnPremStorageService } from '../../../services/onprem-storage';
import { SiteParameterService } from '../../../services/siteparameter.service';

@Component({
  selector: 'app-annuitybankreplogin',
  templateUrl: './annuitybankreplogin.component.html',
})
export class AnnuityBankRepLoginPageComponent implements OnInit {
  public isLoggedIn = false;
  //public currentDate = new Date();
  public loginRedirect = false;
  //public channelId = 2;
  //public screenWidthForLargeDevice: number = 992;
  //public screenWidthForSmallDevice: number = 576;
  //public channelProduct!: channelProduct;
  //public newBusinessDeferredRatesRowData: [] = [];
  //public newBusinessImmediateRatesRowData: [] = [];
  //public newBusinessFixedIndexRatesRowData: [] = [];
  //public renewalDeferredRatesRowData: [] = [];
  //public renewalImmediateRatesRowData: [] = [];
  //private gridApi: any;
  //private gridApi1: any;
  //private gridApi2: any;
  //private gridApi3: any;
  //private gridApi4: any;
  //private gridColumnApi: any;
  //private gridColumnApi1: any;
  //private gridColumnApi2: any;
  //private gridColumnApi3: any;
  //private gridColumnApi4: any;
  //public noRowsTemplate: string = "";
  //public groupDefaultExpanded = 1;
  //public domLayout = "autoHeight";
  //public innerWidth: any;

  //ratesDeferredNBRatesColumnDefs!: ColDef[];
  //ratesDeferredRenewalRatesColumnDefs!: ColDef[];
  //ratesImmediateNBRatesColumnDefs!: ColDef[];
  //ratesImmediateRenewalRatesColumnDefs!: ColDef[];
  //fixedIndexRatesColumnDefs!: ColDef[];

  //defaultColumnDef = {
  //  sortable: true,
  //  suppressMenu: true,
  //  suppressMovable: true
  //};

  //public autoGroupColumnDef: ColDef = {
  //  headerName: '',
  //  cellRendererParams: {
  //    suppressCount: true
  //  },
  //  maxWidth: 5,
  //  colSpan: (params: ColSpanParams) => {
  //    if (params.data) {
  //      return 1;
  //    } else {
  //      return 5;
  //    }
  //  }
  //};

  constructor(public auth: AuthService, private onPremService: OnPremStorageService, private cloudStorageService: CloudStorageService,
    private siteParmService: SiteParameterService) {
    //this.noRowsTemplate = this.siteParmService.getGridNoResults();
    this.auth.isAuthenticated$.subscribe((hasToken: boolean) => {
      if (hasToken) {
        this.isLoggedIn = true;
      }
      else {
        this.isLoggedIn = false;
      }
    });
    //this.getChannelProductLinks();
  }

  ngOnInit(): void {
    //this.innerWidth = window.innerWidth;
    //this.getListColumnDefs();
    //this.getRateLists();
    //$('#NewBusinessList').addClass('tab-pane active');
  }

//  @HostListener('window:resize', ['$event'])
//  onResize(event: any) {
//    this.innerWidth = window.innerWidth;
//    this.getListColumnDefs();
//  }

//  getListColumnDefs(): void {
//    if (this.innerWidth >= this.screenWidthForLargeDevice) {
//      this.ratesDeferredNBRatesColumnDefs = [
//        { field: 'series', rowGroup: true, hide: true },
//        { field: 'product', headerName: 'Product', minWidth: 375, tooltipValueGetter: (params: any) => { return this.getRateTooltip(params.data); }, resizable: true },
//        { field: 'guaranteePeriod', headerName: 'Guarantee Period', maxWidth: 175, tooltipValueGetter: (params: any) => { return this.getRateTooltip(params.data); }, resizable: true },
//        { valueGetter: rateGetter, headerName: 'NB Interest Rate', tooltipValueGetter: (params: any) => { return this.getRateTooltip(params.data); }, resizable: true }
//      ];
//      this.ratesImmediateNBRatesColumnDefs = [
//        { field: 'series', rowGroup: true, hide: true },
//        { field: 'product', headerName: 'Income Duration', minWidth: 375, tooltipValueGetter: (params: any) => { return this.getRateTooltip(params.data); }, resizable: true },
//        { field: 'monthlyIncomeFactor', headerName: 'Monthly Income*', maxWidth: 175, tooltipValueGetter: (params: any) => { return this.getRateTooltip(params.data); }, resizable: true },
//        { field: '', headerName: '', resizable: true }
//      ];
//      this.ratesDeferredRenewalRatesColumnDefs = [
//        { field: 'series', headerName: 'Series', rowGroup: true, hide: true },
//        { field: 'product', headerName: 'Product', minWidth: 375, tooltipValueGetter: (params: any) => { return this.getRateTooltip(params.data); }, resizable: true },
//        { field: 'guaranteePeriod', headerName: 'Guarantee Period', maxWidth: 175, tooltipValueGetter: (params: any) => { return this.getRateTooltip(params.data); }, resizable: true },
//        { valueGetter: rateGetter, headerName: 'Renewal Interest Rate', tooltipValueGetter: (params: any) => { return this.getRateTooltip(params.data); }, resizable: true }
//      ];
//      this.ratesImmediateRenewalRatesColumnDefs = [
//        { field: 'series', headerName: 'Series', rowGroup: true, hide: true },
//        { field: 'product', headerName: 'Income Duration', minWidth: 375, tooltipValueGetter: (params: any) => { return this.getRateTooltip(params.data); }, resizable: true },
//        { field: 'monthlyIncomeFactor', headerName: 'Monthly Income*', maxWidth: 175, tooltipValueGetter: (params: any) => { return this.getRateTooltip(params.data); }, resizable: true },
//        { field: '', headerName: '', resizable: true }
//      ];
//      this.fixedIndexRatesColumnDefs = [
//        { field: 'product', rowGroup: true, hide: true },
//        { field: 'rateTypeDesc', headerName: 'Product', minWidth: 375, tooltipValueGetter: (params: any) => { return this.getRateTooltip(params.data); }, resizable: true },
//        { field: 'guaranteePeriod', headerName: 'Guarantee Period', maxWidth: 175, tooltipValueGetter: (params: any) => { return this.getRateTooltip(params.data); }, resizable: true },
//        { field: 'rate', headerName: 'NB Interest Rate', tooltipValueGetter: (params: any) => { return this.getRateTooltip(params.data); }, resizable: true }
//      ];
//    }
//    else if (this.innerWidth < this.screenWidthForLargeDevice && this.innerWidth > this.screenWidthForSmallDevice) {
//      this.ratesDeferredNBRatesColumnDefs = [
//        { field: 'series', headerName: 'Series', rowGroup: true, hide: true },
//        { field: 'product', headerName: 'Product', minWidth: 325, tooltipValueGetter: (params: any) => { return this.getRateTooltip(params.data); }, resizable: true },
//        { cellRenderer: periodAndRateGetter, headerName: 'Guar Per /\nNB Int Rate', autoHeight: true, tooltipValueGetter: (params: any) => { return this.getRateTooltip(params.data); }, resizable: true }
//      ];
//      this.ratesImmediateNBRatesColumnDefs = [
//        { field: 'series', headerName: 'Series', rowGroup: true, hide: true },
//        { field: 'product', headerName: 'Income Duration', minWidth: 325, tooltipValueGetter: (params: any) => { return this.getRateTooltip(params.data); }, resizable: true },
//        { field: 'monthlyIncomeFactor', headerName: 'Monthly Income*', autoHeight: true, tooltipValueGetter: (params: any) => { return this.getRateTooltip(params.data); }, resizable: true }
//      ];
//      this.ratesDeferredRenewalRatesColumnDefs = [
//        { field: 'series', headerName: 'Series', rowGroup: true, hide: true },
//        { field: 'product', headerName: 'Product', minWidth: 325, tooltipValueGetter: (params: any) => { return this.getRateTooltip(params.data); }, resizable: true },
//        { cellRenderer: periodAndRateGetter, headerName: 'Guar Per /\nRen. Int Rate', autoHeight: true, tooltipValueGetter: (params: any) => { return this.getRateTooltip(params.data); }, resizable: true }
//      ];
//      this.ratesImmediateRenewalRatesColumnDefs = [
//        { field: 'series', headerName: 'Series', rowGroup: true, hide: true },
//        { field: 'product', headerName: 'Income Duration', minWidth: 325, tooltipValueGetter: (params: any) => { return this.getRateTooltip(params.data); }, resizable: true },
//        { field: 'monthlyIncomeFactor', headerName: 'Monthly Income*', autoHeight: true, tooltipValueGetter: (params: any) => { return this.getRateTooltip(params.data); }, resizable: true }
//      ];
//      this.fixedIndexRatesColumnDefs = [
//        { field: 'product', rowGroup: true, hide: true },
//        { field: 'rateTypeDesc', headerName: 'Product', minWidth: 325, tooltipValueGetter: (params: any) => { return this.getRateTooltip(params.data); }, resizable: true },
//        { cellRenderer: periodAndRateGetter, headerName: 'Guar Period /\nNB Int Rate', autoHeight: true, tooltipValueGetter: (params: any) => { return this.getRateTooltip(params.data); }, resizable: true }
//      ];
//    }
//    else {
//      this.ratesDeferredNBRatesColumnDefs = [
//        { field: 'series', headerName: 'Series', rowGroup: true, hide: true },
//        { field: 'product', headerName: 'Product', minWidth: 225, tooltipValueGetter: (params: any) => { return this.getRateTooltip(params.data); }, resizable: true },
//        { cellRenderer: periodAndRateGetter, headerName: 'Guar Per /\nNB Int Rate', autoHeight: true, tooltipValueGetter: (params: any) => { return this.getRateTooltip(params.data); }, resizable: true }
//      ];
//      this.ratesImmediateNBRatesColumnDefs = [
//        { field: 'series', headerName: 'Series', rowGroup: true, hide: true },
//        { field: 'product', headerName: 'Income Duration', minWidth: 225, tooltipValueGetter: (params: any) => { return this.getRateTooltip(params.data); }, resizable: true },
//        { field: 'monthlyIncomeFactor', headerName: 'Monthly Income*', autoHeight: true, tooltipValueGetter: (params: any) => { return this.getRateTooltip(params.data); }, resizable: true }
//      ];
//      this.ratesDeferredRenewalRatesColumnDefs = [
//        { field: 'series', headerName: 'Series', rowGroup: true, hide: true },
//        { field: 'product', headerName: 'Product', minWidth: 225, tooltipValueGetter: (params: any) => { return this.getRateTooltip(params.data); }, resizable: true },
//        { cellRenderer: periodAndRateGetter, headerName: 'Guar Per /\nRen. Int Rate', autoHeight: true, tooltipValueGetter: (params: any) => { return this.getRateTooltip(params.data); }, resizable: true }
//      ];
//      this.ratesImmediateRenewalRatesColumnDefs = [
//        { field: 'series', headerName: 'Series', rowGroup: true, hide: true },
//        { field: 'product', headerName: 'Income Duration', minWidth: 225, tooltipValueGetter: (params: any) => { return this.getRateTooltip(params.data); }, resizable: true },
//        { field: 'monthlyIncomeFactor', headerName: 'Monthly Income*', autoHeight: true, tooltipValueGetter: (params: any) => { return this.getRateTooltip(params.data); }, resizable: true }
//      ];
//      this.fixedIndexRatesColumnDefs = [
//        { field: 'product', rowGroup: true, hide: true },
//        { field: 'rateTypeDesc', headerName: 'Product', minWidth: 225, tooltipValueGetter: (params: any) => { return this.getRateTooltip(params.data); }, resizable: true },
//        { cellRenderer: periodAndRateGetter, headerName: 'Guar Period /\nNB Int Rate', autoHeight: true, tooltipValueGetter: (params: any) => { return this.getRateTooltip(params.data); }, resizable: true }
//      ];
//    }
//  }

  login(): void {
    this.auth.loginWithRedirect({ appState: { target: '/home' }, registration_code: '123456789' });

  }

  signup(): void {
    this.auth.loginWithRedirect({ appState: { target: '/home' }, screen_hint: 'signup' });

  }

//  getRateLists(): void {
//    let rateType = 'NB';
//    this.onPremService.getAnnuityDeferredRates(this.channelId, rateType, false)
//      .pipe(take(1))
//      .subscribe(rates => {
//        this.newBusinessDeferredRatesRowData = rates.data;
//      });
//    this.onPremService.getAnnuityImmediateRates(this.channelId, rateType, false)
//      .pipe(take(1))
//      .subscribe(rates => {
//        this.newBusinessImmediateRatesRowData = rates.data;
//      });
//    this.onPremService.getAnnuityFixedIndexRates(this.channelId, rateType, false)
//      .pipe(take(1))
//      .subscribe(rates => {
//        this.newBusinessFixedIndexRatesRowData = rates.data;
//      });
//    rateType = 'RENEW';
//    this.onPremService.getAnnuityDeferredRates(this.channelId, rateType, false)
//      .pipe(take(1))
//      .subscribe(rates => {
//        this.renewalDeferredRatesRowData = rates.data;
//      });
//    this.onPremService.getAnnuityImmediateRates(this.channelId, rateType, false)
//      .pipe(take(1))
//      .subscribe(rates => {
//        this.renewalImmediateRatesRowData = rates.data;
//      });
//  }

//  getChannelProductLinks(): void {
//    this.cloudStorageService.getAnonymousProductsByChannel(this.channelId)
//      .pipe(take(1))
//      .subscribe(productList => {
//        this.channelProduct = productList;
//      });
//  }

//  getRateTooltip(data: any): string {
//    return 'Effective as of ' + data.rateStartDate;
//  }

//  onClickTabSelect(link: string) {
//    $('#listMenu').find("*").removeClass("router-link-exact-active");
//    $(link).addClass('router-link-exact-active');
//    this.gridApi.sizeColumnsToFit();
//    this.gridApi1.sizeColumnsToFit();
//    this.gridApi2.sizeColumnsToFit();
//    this.gridApi3.sizeColumnsToFit();
//    this.gridApi4.sizeColumnsToFit();
//  }

//  onGridReady(params: any) {
//    this.gridApi = params.api;
//    this.gridColumnApi = params.columnApi;
//  }
//  onGridReady1(params: any) {
//    this.gridApi1 = params.api;
//    this.gridColumnApi1 = params.columnApi;
//  }
//  onGridReady2(params: any) {
//    this.gridApi2 = params.api;
//    this.gridColumnApi2 = params.columnApi;
//  }
//  onGridReady3(params: any) {
//    this.gridApi3 = params.api;
//    this.gridColumnApi3 = params.columnApi;
//  }
//  onGridReady4(params: any) {
//    this.gridApi4 = params.api;
//    this.gridColumnApi4 = params.columnApi;
//  }
//}

//function seriesAndProductGetter(params: any) {
//  return params.data.product + ' - ' + params.data.series;
//}

//function rateGetter(params: any) {
//  let rateValue = "";
//  if (params.data.rate !== params.data.rateFirstYear) {
//    rateValue = params.data.rateFirstYear + ' (FY), ' + params.data.rate + ' (Base) ';
//  }
//  else {
//    rateValue = params.data.rate;
//  }

//  return rateValue;
//}

//function periodAndRateGetter(params: any) {
//  let returnValue = ""
//  let rateValue = "";
//  if (params.data.rateFirstYear !== "" && params.data.rate !== params.data.rateFirstYear) {
//    rateValue = params.data.rateFirstYear + ' (FY),<br/>' + params.data.rate + ' (Base) ';
//  }
//  else {
//    rateValue = params.data.rate;
//  }

//  if (params.data.guaranteePeriod === "") {
//    returnValue = rateValue;
//  }
//  else {
//    returnValue = params.data.guaranteePeriod + '<br/>' + rateValue;
//  }

//  return returnValue;
}
