<div style="padding-top:200px;">
  <section class="section page-header">
    <section class="container grid-lg">
      <div class="columns">
        <h2>Terms of Use</h2>
        <div class="column col-12 header-content">
          <p>
            Please read these Terms of Use carefully before using this site. By using this site, you signify your agreement with these Terms of Use. If you do not agree with any of the Terms of Use listed below, do not use this site. LBL reserves the right, at its sole discretion, to modify,
            alter or otherwise update these Terms of Use at any time and you agree to be bound by such modifications, alterations or updates.
          </p>

          <p>
            This site is controlled and operated by Liberty Bankers Life Insurance Company (LBL), 1605 LBJ Freeway, Suite 710, Dallas, Texas 75234. Our telephone number is (800) 745-4927. All material on this site, including but not limited to images, illustrations, audio clips, and video clips,
            is protected by copyrights which are owned and controlled by LBL or by other parties that have licensed their material to us (“Copyrights”). Material from LibertyBankers.com, LibertyBankersAdvisors.com or any web site owned, operated, licensed or controlled by LBL may not be copied, reproduced,
            republished, uploaded, posted, transmitted, or distributed in any way without our written consent. Modification of the materials or use of the materials for any other purpose is a violation of the copyrights and other proprietary rights. Persons or entities that maintain their own web site(s) may not link to any web site operated by LBL without our written permission,
            signed by an authorized officer of the company.
          </p>
        </div>
      </div>
    </section>
  </section>
  <section class="section text-module ">
    <section class="container grid-lg ">
      <div class="columns">
        <div class="column col-12">
          <div class="mod-text">
            <h2>Disclosure</h2>
            <p>
              The information contained herein is provided solely for general informational purposes and is not intended to be a solicitation or an offer to sell in connection with any product or service, nor is the information a complete description of all terms,
              conditions and exclusions applicable to the products and services described. For complete descriptions of the terms, conditions and exclusions of insurance coverages or other products or services, please contact your LBL agent or the LBL Home Office. Products and services referenced here in may not be available in all states.
            </p>

            <p>
              We may provide links to other Internet sites that are not maintained by LBL. Such external Internet addresses contain information created, published, maintained or otherwise posted by organizations and entities independent of LBL. LBL is not responsible for the content of those sites nor does it approve, endorse or certify
              information available at any external site or linked addresses contained herein. The links are for your convenience only and you use them at your own risk.
            </p>

            <p>© Copyright 2021 Liberty Bankers Life Insurance Company, Dallas, Texas. All rights reserved. No part of this site may be reproduced without written permission from LBL.</p>
          </div>
        </div>
      </div>
    </section>
  </section>
</div>
