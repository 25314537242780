<li class="nav-item dropdown">
  <a class="nav-link" data-toggle="dropdown" href="#" aria-expanded="false" ngbTooltip="Filtered by Business Channel selection above" tooltipClass="lbig-tooltip">
    <b><span class="select-input">Agent: {{selectedAgentId}}</span>&nbsp;&nbsp;<span class="select-dropdown"><i class="fas fa-angle-down"></i></span></b>
  </a>
  <div class="dropdown-menu dropdown-menu-sm dropdown-menu" style="left: inherit; right: 0px;">
    <span class="dropdown-item" *ngFor="let agent of agents">
      <a class="dropdown-item" [ngClass]="{'active': agentid === selectedAgentId}" (click)="changeAgent(agent.agentNumber, agent.channel);"><small>{{agent.agentNumber}}</small></a>
    </span>
  </div>
</li>

