<div class="container w-100 justify-content-center">
  <mdb-carousel [isControls]="true" [animation]="'slide'">
    <mdb-carousel-item *ngFor="let carouselImage of carouselImages">
      <a href="{{carouselImage.linkUrl}}" target="_blank" *ngIf="carouselImage.imagePath">
        <img class="d-block w-100" src="{{carouselImage.imagePath}}" alt="{{carouselImage.description}}" *ngIf="carouselImage.linkUrl">
      </a>
      <img class="d-block w-100" src="{{carouselImage.imagePath}}" alt="{{carouselImage.description}}" *ngIf="!carouselImage.linkUrl">
    </mdb-carousel-item>
  </mdb-carousel>
</div>
