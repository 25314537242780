import { Component, OnInit } from '@angular/core';
import { UserGrid } from '../../../models/userGrid';
import { GridApi } from 'ag-grid-enterprise';
import { CloudStorageService } from '../../../services/cloud-storage';
import { SiteParameterService } from '../../../services/siteparameter.service';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-userlist',
  templateUrl: './userslist.component.html',
  styles: [
  ]
})
export class UserListPageComponent implements OnInit {
  public users$?: Observable<UserGrid[]>
  private gridApi!: GridApi;

  public columnDefs = [
    {
      headerName: 'Select',
      width: 30,
      checkboxSelection: true,
      cellStyle: { 'pointer-events': 'none' },
      field: 'selected'
    },
    { headerName: 'Email', width: 100, field: 'email' },
    { headerName: 'Name', width: 100, field: 'name' },
    { headerName: 'E-App UserName', width: 100, field: 'eAppUserName' },
    { headerName: `Agent ID's`, field: 'lnkAgentIds' },
    {
      headerName: 'User Id', field: 'userId', hide: true,
      // this is needed to hide field from Quick Filter
      getQuickFilterText: (params: any) => {
        return null;
      }
    }
  ];
  public defaultColumnDef = {
    sortable: true,
    resizable: true,
  };

  constructor(public cloudStorageService: CloudStorageService, private siteParmService: SiteParameterService) { }

  ngOnInit(): void {
    this.users$ = this.cloudStorageService.getUserList();
  }

  onSearchChanged() {
    this.gridApi.setQuickFilter(
      (document.getElementById('search') as HTMLInputElement).value
    );
  }

  onRowSelected(event: any) {
    if (event.node.selected) {
      this.cloudStorageService.getImpersonationProfile(event.data.userId)
        .pipe(take(1))
        .subscribe((resp: any) => {
          this.siteParmService.setProfile(resp);
          this.siteParmService.sendImpersonationUpdate(resp);
        });
    }
  }
  onGridReady(params: any) {
    this.gridApi = params.api;
    params.api.sizeColumnsToFit();
  }
}
