import { Component, OnInit } from '@angular/core';
import { HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { finalize, map, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment as env } from '../../../../environments/environment';
import { fileTarget } from '../../../models/fileTarget';
import { Profile } from '../../../models/profile';
import { CloudStorageService } from '../../../services/cloud-storage';
import { FileUploadService } from '../../../services/fileupload.service';
import { OnPremStorageService } from '../../../services/onprem-storage';
import { SiteParameterService } from '../../../services/siteparameter.service';
import { DateComparator } from '../../../common/helpers';

@Component({
  selector: 'app-fileupload',
  templateUrl: './fileupload.component.html',
  styles: [
  ]
})
export class FileUploadComponent implements OnInit {
  public profile!: Profile;
  private channelUpdate: Subscription;
  public showHeaderText = false;
  public headerText = "";
  public fileTargets$?: Observable<fileTarget[]>;
  public fileTargetId: number = 0;
  public fileExtensionsAllowed: Array<string> = ['.doc', '.docx', '.txt', '.pdf', '.tif', '.tiff', '.xls', '.xlsx'];
  public policyNumber: string = '';
  public clientName: string = '';
  public disableUpload: boolean = true;
  public hideUploadButtons: boolean = false;
  public currentFile?: File;
  public currentFileName: string = '';
  public progress: number = 0;
  public showUploadComplete: boolean = false;
  public message: string = '';
  public rowData: [] = [];
  public uploadRowCount = 0;
  public noRowsTemplate: string = '';
  public showSpinner = false;
  private gridApi: any;
  private gridColumnApi: any;
  columnDefs = [
    { field: 'uploadDtm', headerName: 'Upload Date', minWidth: 100, comparator: DateComparator },
    { field: 'fileIdentifier', headerName: 'Identifier', minWidth: 100 },
    { field: 'uploadCategory', headerName: 'Category', minWidth: 100 },
    { field: 'fileName', headerName: 'File Name', minWidth: 200 },
    { field: 'policyNumber', headerName: 'Policy #', minWidth: 100 },
    { field: 'clientName', headerName: 'Owner Name', minWidth: 100 }
  ];
  defaultColumnDef = {
    sortable: true,
    resizable: true,
  };

  constructor(private router: Router, private cloudStorageService: CloudStorageService, private onPremService: OnPremStorageService, private fileUploadService: FileUploadService, private siteParmService: SiteParameterService) {
    this.noRowsTemplate = this.siteParmService.getGridNoResults();
    this.channelUpdate = this.siteParmService.getChannelUpdate().subscribe
      (message => {
        this.profile = this.siteParmService.getStaticProfile();      
        this.getFileTargetListAndMessage();
      });
  }

  ngOnInit(): void {
    this.profile = this.siteParmService.getStaticProfile();
    this.getFileTargetListAndMessage();
    this.getFileUploadList();
    this.hideUploadFunctions();
  }

  getFileTargetListAndMessage(): void {
    const selectedBusinessChannel = this.profile ? this.profile.channelPreferenceValue : 0;
    this.fileTargets$ = this.onPremService.getFileTargetList(selectedBusinessChannel).pipe(
      map(apiReponse => {
        return apiReponse.data;
      }));
    this.cloudStorageService.getChannel(selectedBusinessChannel)
      .pipe(take(1))
      .subscribe(channel => {
        this.headerText = channel.documentUploadPageMessage;
        this.showHeaderText = channel.documentUploadPageMessage == null ? false : true;
      });
  }

  getFileUploadList(): void {
    this.onPremService.getFileUploadList(this.profile.auth0Id)
      .pipe(take(1))
      .subscribe(resp => {
        this.rowData = resp.data;
        this.uploadRowCount = resp.data.length;
      });
  }

  hideUploadFunctions(): void {
    this.hideUploadButtons = env.dev.disableFileUpload === 'yes';
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  onSelectFile(event: any): void {
    this.currentFile = event.target.files[0];
    this.currentFileName = event.target.files[0].name;
    this.validateFields();
    //if (this.currentFile !== undefined && this.fileTargetId > 0) {
    //  this.disableUpload = false;
    //}
    //else {
    //  this.disableUpload = true;
    //}
  }

  onFileTargetChange(event: any): void {
    this.validateFields();
    //if (this.currentFile !== undefined && this.fileTargetId > 0) {
    //  this.disableUpload = false;
    //}
    //else {
    //  this.disableUpload = true;
    //}
  }

  onPolicyNumberChange(event: any): void {
    this.policyNumber = event.target.value;
  }

  onClientNameChange(event: any): void {
    this.clientName = event.target.value;
    this.validateFields();
    //if (this.clientName.length > 0) {
    //  this.disableUpload = false;
    //}
    //else {
    //  this.disableUpload = true;
    //}
  }

  validateFields(): void {
    if (this.currentFile !== undefined && this.fileTargetId > 0 && this.clientName.length > 0) {
      this.disableUpload = false;
    }
    else {
      this.disableUpload = true;
    }
  }

  uploadFile(): void {
    var ext = this.currentFile?.name.substr(this.currentFile?.name.lastIndexOf('.')).toLowerCase();

    if (!this.fileExtensionsAllowed.find(x => x === ext) ) {
      this.message = 'File type is not allowed';
    } else if (this.currentFile!.size > env.dev.maxFileSize * 1024) {
      this.message = 'File is too large';
    } else if (this.currentFile!.size === 0) {
      this.message = 'File contains no data';
    }
    else {
      this.fileUploadService.upload(this.fileTargetId, this.profile.channelPreferenceValue, this.profile.emailAddress, this.profile.auth0Id,
        this.profile.selectedAgentNumber, this.policyNumber, this.clientName, this.currentFileName, this.currentFile!)
        .pipe(finalize(() => {
          this.getFileUploadList();
        }))
        .subscribe({
          next: (event: any) => {
            if (event.type === HttpEventType.UploadProgress) {
              this.progress = Math.round(100 * event.loaded / event.total);
            } else if (event instanceof HttpResponse) {
              this.message = event.body.message;
            };
            if (event.loaded === event.total) {
              setTimeout(() => {
                this.showUploadComplete = true;
              }, 1000);
            }
          },
          error: (err: any) => {
            this.progress = 0;

            if (err.error && err.error.message) {
              this.message = err.error.message;
              this.showUploadComplete = false;
            } else {
              this.message = 'Error uploading the file';
              this.showUploadComplete = false;
            }

            this.currentFile = undefined;
          }
        });
      this.disableUpload = true;
    }
  }

  resetUpload(): void {
    this.currentFile = undefined;
    this.fileTargetId = 0;
    this.policyNumber = '';
    this.clientName = '';
    this.progress = 0
    this.showUploadComplete = false;
    this.message = '';
    this.disableUpload = true;
  }

  ngOnDestroy() { // It's a good practice to unsubscribe to ensure no memory leaks
    this.channelUpdate.unsubscribe();
  }
}
