import { Component, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Profile } from '../../../models/profile';
import { CloudStorageService } from '../../../services/cloud-storage';
import { SiteParameterService } from '../../../services/siteparameter.service';
import { take } from 'rxjs/operators';


@Component({
  selector: 'app-agent-info-message',
  templateUrl: './agentinfomessage.component.html',
  styles: [
  ]
})

export class AgentInfoMessageComponent implements OnInit {
  public showHeaderText = false;
  public headerText = "";
  public profile!: Profile;
  private channelUpdate: Subscription;

  constructor(private cloudStorageService: CloudStorageService, private siteParmService: SiteParameterService) {
    this.channelUpdate = this.siteParmService.getChannelUpdate().subscribe
      (message => {
        this.profile = this.siteParmService.getStaticProfile();
        this.getMessage();
      });
  }
  ngOnInit(): void {
    this.profile = this.siteParmService.getStaticProfile();
    this.getMessage();
  }
  getMessage(): void {
    const selectedBusinessChannel = this.profile ? this.profile.channelPreferenceValue : 0;
    this.cloudStorageService.getChannel(selectedBusinessChannel)
      .pipe(take(1))
      .subscribe(channel => {
        this.headerText = channel.agentInfoMessage;
        this.showHeaderText = channel.agentInfoMessage == null ? false : true;
      });
  }
}
