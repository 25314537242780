// src/environments/environment.ts

//import { domain, clientId, serverUrl } from '../../auth_config.json';

//export const environment = {
//  production: false,
//  auth: {
//    domain,
//    clientId,
//    redirectUri: window.location.origin,    
//  },
//  dev: {
//    serverUrl,
//  },
//};

import * as ConfigInfo from '../../config.json';

export const environment = {
    production: true,
    auth: {
        domain: ConfigInfo.domain,
        clientId: ConfigInfo.clientId,
        redirectUri: window.location.origin,
        audience: ConfigInfo.audience
    },
    dev: {
        serverUrl: ConfigInfo.serverUrl,
        tappUrl: ConfigInfo.tappUrl,
        mrsSuppHealthUrl: ConfigInfo.mrsSuppHealthUrl,
        mrsHomeServiceUrl: ConfigInfo.mrsHomeServiceUrl,
        onPremServerUrl: ConfigInfo.onPremServerUrl,
        annuityEAppURL: ConfigInfo.annuityEAppURL,
        anonymousAllowedList: ConfigInfo.anonymousAllowedList,
        eAppRedirectUrlPart1: ConfigInfo.eAppRedirectUrlPart1,
        eAppRedirectUrlPart2: ConfigInfo.eAppRedirectUrlPart2,
        disableFileUpload: ConfigInfo.disableFileUpload,
        maxFileSize: ConfigInfo.maxFileSize
    },
};
