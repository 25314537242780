import { Component, OnInit } from '@angular/core';
import { RowSelectedEvent } from 'ag-grid';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { Profile } from '../../../models/profile';
import { searchParams } from '../../../models/searchParams';
import { CloudStorageService } from '../../../services/cloud-storage';
import { SiteParameterService } from '../../../services/siteparameter.service';
import { DatePipe } from '@angular/common';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-formPage',
  templateUrl: './forms.component.html',
  styles: [
  ]
})
export class FormsPageComponent implements OnInit {
  public showSpinner = false;
  public combineForms = false;
  public showSearch = false;
  public showHeaderText = false;
  public profile!: Profile;
  public parms!: searchParams;
  public headerText = "";
  public description = "";
  public state = "0";
  public company = "0";
  public product = "0";
  public formClass = "0";
  public type = "0";
  public forms$?: Observable<any>;
  public combineDisabled = true;
  public individualDisabled = true;
  public sendEmailDisabled = false;
  private nodeCount: number = 0;
  private gridApi: any;
  private gridColumnApi: any;
  private channelUpdate: Subscription;
  columnDefs = [
    {
      checkboxSelection: true, headerCheckboxSelection: true, cellRenderer: (params: any) => {
        params.node.setSelected(params.node!.data.preselected)
      }, maxWidth: 50
    },
    { field: 'description', headerName: 'Form Name' },
    { field: 'number', headerName: 'Number', filter: 'agTextColumnFilter' },
    { field: 'lastUpdate', headerName: 'Last Updated' },
    { field: 'company', headerName: 'Company', filter: 'agTextColumnFilter' },
    { field: 'class', headerName: 'Form Class', filter: 'agTextColumnFilter' },
    { field: 'type', headerName: 'Form Type', filter: 'agTextColumnFilter' },
    { field: 'product', headerName: 'Product', filter: 'agTextColumnFilter' }
  ];
  defaultColumnDef = {
    sortable: true,
    resizable: true,
  };

  // Generic Annuity Login with NO access to Send Email button
  private genericAnnuityLogin: string = 'annuity.advisor@lbig.com';

  constructor(private cloudStorageService: CloudStorageService, private siteParmService: SiteParameterService, private toastr: ToastrService, public datepipe: DatePipe) {
    this.channelUpdate = this.siteParmService.getChannelUpdate().subscribe
      (message => {
        this.profile = this.siteParmService.getStaticProfile();
        this.getSearchParmsAndMessage();
      });
  }
  ngOnInit(): void {
    this.profile = this.siteParmService.getStaticProfile();
    this.getSearchParmsAndMessage();
  }
  getSearchParmsAndMessage(): void {
    const selectedBusinessChannel = this.profile ? this.profile.channelPreferenceValue : 0;
    this.cloudStorageService.getSearchParms(selectedBusinessChannel)
      .pipe(take(1))
      .subscribe(parms => {
        this.parms = parms;
      });
    this.cloudStorageService.getChannel(selectedBusinessChannel)
      .pipe(take(1))
      .subscribe(channel => {
        this.headerText = channel.formsPageMessage;
        this.showHeaderText = channel.formsPageMessage == null ? false : true;
      });
  }
  searchForms(): void {
    this.showSearch = true;
    this.showSpinner = false;
    const selectedBusinessChannel = this.profile ? this.profile.channelPreferenceValue : 0;
    let stateSearch = "0";
    this.company = "0";

    if (this.state.indexOf(":") > -1) {
      const stateCompanyArray = this.state.split(":");
      stateSearch = stateCompanyArray[0];
      this.company = stateCompanyArray[1];
    }

    this.forms$ = this.cloudStorageService.searchForms(selectedBusinessChannel, this.description, this.formClass, stateSearch, this.type, this.product, this.company);
  }
  resetSearch(): void {
    this.description = "";
    this.state = "0";
    this.company = "0";
    this.product = "0";
    this.formClass = "0";
    this.type = "0";
    this.showSearch = false;
  }
  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
  }
  downloadForms() {
    this.showSpinner = true;
    const selectedNodes = this.gridApi.getSelectedNodes();
    var selectedFiles: string[] = [];
    var company = '';
    var formNumber = '';
    var container = '';
    const date = new Date();
    const currentDateTime = this.datepipe.transform(date, 'yyyyMMddhhmm');
    this.nodeCount = selectedNodes.length;
    if (this.combineForms) {
      selectedNodes.forEach((node: any) => { selectedFiles.push(node.data.fileName), company = node.data.company, formNumber = node.data.number, container = node.data.containerName });
      this.cloudStorageService.downloadCombinedForms(selectedFiles, container)
        .pipe(take(this.nodeCount))
        .subscribe(data => {
          this.showSpinner = false;
          const binaryData = [];
          binaryData.push(data);
          const downloadedFile = new Blob(binaryData, { type: 'application/pdf' });
          const a = document.createElement('a');
          a.setAttribute('style', 'display:none;');
          document.body.appendChild(a);
          if (company != null) { a.download = 'FormsPackage' + "_" + company + "_" + currentDateTime + ".pdf"; }
          else { a.download = 'FormsPackage' + "_" + currentDateTime + ".pdf"; }
          a.href = URL.createObjectURL(downloadedFile);
          a.target = '_blank';
          a.click();
          document.body.removeChild(a);
        });
    } else {
      selectedNodes.forEach((node: any) => {
        selectedFiles = [];
        selectedFiles.push(node.data.fileName);
        this.cloudStorageService.downloadCombinedForms(selectedFiles, node.data.containerName)
          .pipe(take(this.nodeCount))
          .subscribe(data => {
            this.showSpinner = false;
            const binaryData = [];
            binaryData.push(data);
            const downloadedFile = new Blob(binaryData, { type: 'application/pdf' });
            const a = document.createElement('a');
            a.setAttribute('style', 'display:none;');
            document.body.appendChild(a);
            a.download = node.data.description + "_" + node.data.number + "_" + node.data.company + "_" + currentDateTime + ".pdf";
            a.href = URL.createObjectURL(downloadedFile);
            a.target = '_blank';
            a.click();
            document.body.removeChild(a);
          });
      });
    }
  }
  emailForms() {
    this.showSpinner = true;
    const selectedNodes = this.gridApi.getSelectedNodes();
    const selectedFiles: string[] = [];
    let container = '';
    selectedNodes.forEach((node: any) => { selectedFiles.push(node.data.fileName), container = node.data.containerName });
    this.nodeCount = selectedNodes.length;
    this.cloudStorageService.emailForms(selectedFiles, container, this.profile.emailAddress, this.combineForms)
      .pipe(take(this.nodeCount))
      .subscribe(data => {
        this.toastr.success('The selected files were sent to your email address', 'Forms Sent', {
          positionClass: 'toast-email-info', tapToDismiss: true, timeOut: 0
        });
        this.showSpinner = false;
      });
  }
  onRowSelected(event: any) {
    const nodeCount = this.gridApi.getSelectedNodes().length;
    this.individualDisabled = nodeCount < 1 ? true : false;
    this.combineDisabled = nodeCount > 1 ? false : true;

    this.sendEmailDisabled = this.profile.emailAddress === this.genericAnnuityLogin;
  }
  ngOnDestroy() { // It's a good practice to unsubscribe to ensure no memory leaks
    this.channelUpdate.unsubscribe();
  }
}
