<div class="row">
  <div class="col-md-4">
    <div class="form-group row">
      <label class="col-md-2 col-form-label text-right">Search</label>      
      <div class="col-md-10">
        <input type="text" id="search" class="form-control" (input)="onSearchChanged()" />
      </div>
    </div>
  </div>
</div>
<ag-grid-angular style="width: 100%; height: 640px;"
                 class="ag-theme-alpine"
                 [rowData]="users$ | async"
                 [columnDefs]="columnDefs"
                 [defaultColDef]="defaultColumnDef"
                 rowSelection='single'
                 [rowDeselection]="true"
                 [suppressRowClickSelection]="false"
                 (rowSelected)="onRowSelected($event)"
                 (gridReady)="onGridReady($event)">
</ag-grid-angular>
