import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { channelProduct } from '../../../models/channelProduct';
import { Profile } from '../../../models/profile';
import { CloudStorageService } from '../../../services/cloud-storage';
import { OnPremStorageService } from '../../../services/onprem-storage';
import { SiteParameterService } from '../../../services/siteparameter.service';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {
  profile!: Profile;
  private channelUpdate: Subscription;
  public selectedBusinessChannel: number = 0;
  public showFront = false;
  public showBack = false;
  public showForAnnuity = false;
  public hideFromAnnuityBank = false;
  public channelProduct!: channelProduct;
  public sections!: channelProduct[];

  public slides: Array<string> = [];

  constructor(private cloudStorageService: CloudStorageService, private siteParmService: SiteParameterService, private toastr: ToastrService) {
    this.channelUpdate = this.siteParmService.getChannelUpdate().subscribe
      (message => {
        this.displayPreference();
        this.getProducts();
      });
  }
  ngAfterViewInit(): void {
    $('#menu li a').click(function () {
      if (this.parentElement?.className.indexOf('dropdown') == -1) {
        $('#menu').find("*").removeClass("router-link-active");
        $(this).addClass('router-link-active');
      }
    });
    if (this.profile) {
      if (!this.profile.productOverviewSelected) {
        $('#channel-tab').addClass('router-link-active');
        $('#Channel').addClass('tab-pane active');
      } else {
        $('#overview-tab').addClass('router-link-active');
        $('#Overview').addClass('tab-pane active');
      }
    } else {
      $('#overview-tab').addClass('router-link-active');
      $('#Overview').addClass('tab-pane active');
    }
  }
  ngOnInit(): void {
    this.profile = this.siteParmService.getStaticProfile();
    this.displayPreference();
    this.getProducts();
    if (this.profile.agents.length > 0) {
      if (!this.profile.productsVisited) {
        this.toastr.info('The navigation toggle here provides detailed information for the currently selected business channel ', 'Information', {
          positionClass: 'toast-product-info', tapToDismiss: true, timeOut: 0
        });
        this.profile.productsVisited = true;
        this.siteParmService.setProfile(this.profile);
      }
      this.cloudStorageService.updatePageVisited(this.profile.auth0Id, 2)
        .pipe(take(1))
        .subscribe((resp: boolean) => { });
    } else {
      $('#overview-tab').addClass('router-link-active');
      $('#Overview').addClass('tab-pane active');
    }
  }
  getProducts(): void {
    this.selectedBusinessChannel = this.profile ? this.profile.channelPreferenceValue : 0;
    this.cloudStorageService.getProductsByChannel(this.selectedBusinessChannel)
      .pipe(take(1))
      .subscribe(productList => {
        this.channelProduct = productList;
        this.slides.length = 0;
        for (var i = 0; i < productList.products.length; i++) {
          this.slides.push(productList.products[i].title)
        }
      });
    this.cloudStorageService.getProductSectors()
      .pipe(take(1))
      .subscribe(sections => {
        this.sections = sections;
      });
  }
  updatePreference(overview: boolean) {
    if (this.profile) {
      this.profile.productOverviewSelected = overview;
      this.siteParmService.setProfile(this.profile);
      this.cloudStorageService.updatePreference("Product", this.profile.auth0Id, overview).subscribe((resp: boolean) => { });
    }
  }
  displayPreference(): void {
    this.profile = this.siteParmService.getStaticProfile();
    this.showForAnnuity = this.profile.channelPreference === 'Annuity';
    this.hideFromAnnuityBank = this.profile.channelPreference === 'Annuity [Bank]';
  }
  ngOnDestroy() { // It's a good practice to unsubscribe to ensure no memory leaks
    this.channelUpdate.unsubscribe();
  }
}
