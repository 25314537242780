<!--src/app/app.component.html-->
<div class="container-fluid" *ngIf="auth.isLoading$ | async; else loaded">
</div>
<ng-template #loaded>
  <div class="container-fluid w-100">
    <app-nav-bar *ngIf="auth.isAuthenticated$ | async as user"></app-nav-bar>
  </div>
  <!--<div style="padding: 40px;">-->
  <div class="portal-wrapper">
    <div class="container-fluid vh-90">
      <router-outlet></router-outlet>
    </div>
    <div class="container-fluid">
      <app-footer *ngIf="!auth.isAuthenticated$"></app-footer>
    </div>
  </div>
</ng-template>
