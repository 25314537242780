import { Component, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { Profile } from '../../../models/profile';
import { profileOptions } from '../../../models/profileOptions';
import { Subscription } from 'rxjs';
import { SiteParameterService } from '../../../services/siteparameter.service'
import { CloudStorageService } from '../../../services/cloud-storage';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
})
export class ProfileComponent implements OnInit {
  private setDefaultAgentUpdate: Subscription; //important to create a subscription
  private setDefaultChannelUpdate: Subscription; //important to create a subscription
  public profile!: Profile;
  public profileOptions!: profileOptions;
  public displayName = "";
  private defaultAgentId = "";
  private defaultChannel = "";
  constructor(public auth: AuthService, public cloudStorageService: CloudStorageService, private siteParmService: SiteParameterService, private toastr: ToastrService) {
    this.setDefaultAgentUpdate = this.siteParmService.getDefaultAgentUpdate().subscribe
      (message => {
        this.defaultAgentId = message.value;
      });
    this.setDefaultChannelUpdate = this.siteParmService.getDefaultChannelUpdate().subscribe
      (message => {
        this.defaultChannel = message.value;
      });
  }

  ngOnInit(): void {
    this.profile = this.siteParmService.getStaticProfile();
    this.defaultAgentId = this.profile.selectedAgentNumber;
    this.displayName = this.profile.displayName;
    this.cloudStorageService.getProfileOptions(this.profile.auth0Id)
      .pipe(take(1))
      .subscribe((resp: any) => {
        this.profileOptions = resp;
      });
  }
  onSubmit(formData: any): void {
    this.cloudStorageService.updateProfile(this.profile.auth0Id, this.defaultAgentId, this.displayName)
      .pipe(take(1))
      .subscribe((resp: any) => {
        this.profile.defaultAgentNumber = this.defaultAgentId;
        this.profile.selectedAgentNumber = this.defaultAgentId;
        this.profile.channelPreference = this.defaultChannel;
        this.profile.displayName = this.displayName;
        this.siteParmService.setProfile(this.profile);
        this.toastr.success('Profile Successfully Updated', 'Update', {
          positionClass: 'toast-bottom-center'
        });
        this.siteParmService.sendParmServiceInit(null);
      });
  }
  ngOnDestroy() { // It's a good practice to unsubscribe to ensure no memory leaks
    this.setDefaultAgentUpdate.unsubscribe();
    this.setDefaultChannelUpdate.unsubscribe();
  }
}
