import { Component } from "@angular/core";

@Component({
  selector: 'app-redirecting',
  templateUrl: './redirecting.component.html',
  styles: [
  ]
})
export class RedirectingComponent {
  constructor() { }
}
