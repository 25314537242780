<div class="card card-secondary h-100">
  <div class="card-header">
    <h4 class="card-title w-100">
      <span>Key Performance Indicators</span>
    </h4>
  </div>
  <div class="card-body">
    <div *ngIf="kpis$ | async; else loading;">
      <div *ngFor="let kpimessage of kpimessage$ | async">
        <div class="col-md-12" *ngIf="kpimessage.message.length > 0">
          <div class="info-box bg-gradient-gray-dark">
            <div class="info-box-content">
              <div class="row justify-content-center"><big>{{kpimessage.message}}</big></div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div *ngFor="let kpi of kpis$ | async; let i = index" class="col-md-4">
          <div class="info-box" [ngClass]="{grow: kpi.showInPolicySearch}" (click)="searchKPI(kpi.showInPolicySearch, kpi.description)">
            <span class="info-box-icon-kpi bg-{{kpi.color | lowercase}}"><i class="fad {{kpi.icon}}" style="--fa-primary-color: {{kpi.iconPrimaryColor}}; --fa-secondary-color: {{kpi.iconSecondaryColor}}; "></i></span>
            <div class="info-box-content">
              <div class="info-box-text" style="white-space: normal !important;"><big>{{kpi.description}}</big></div>
              <span class="info-box-number kpi">
                {{kpi.kpiDisplayValue}}
              </span>
              <div class="row justify-content-end"><small><i>{{kpi.subText}}</i>&nbsp;</small></div>
            </div>
          </div>
        </div>
      </div>
    </div>
      <ng-template #loading>
        <div class="row justify-content-center">
          <component-loading></component-loading>
        </div>
      </ng-template>
    </div>
</div>
