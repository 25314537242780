<div class="row justify-content-center">
  <div class="col-md-10">
    <div class="card card-secondary">
      <div class="card-header">
        <h4 class="card-title w-100">
          <span>Agent Link Codes (Admin)</span>
        </h4>
      </div>
    </div>
    <ng-container *ngIf="showRefreshButton">
      <div class="text-right mb-2">
        <input type="button" class="btn btn-secondary input-block-level" value="Refresh List" (click)="onRefreshClicked()">
      </div>
    </ng-container>
    <div class="row admin-box" *ngIf="showList">
      <div class="col-md-12">
        <ag-grid-angular style="height: 300px;"
                         class="ag-theme-alpine"
                         [rowData]="linkCodes$ | async"
                         [columnDefs]="columnDefs"
                         [defaultColDef]="defaultColumnDef"
                         rowSelection='single'
                         [suppressRowClickSelection]="true"
                         (gridReady)="onGridReady($event)"
                         [overlayNoRowsTemplate]="noRowsTemplate"
                         (rowClicked)="onRowClicked($event)"
                         [ag-grid-resize]>
        </ag-grid-angular>
      </div>
    </div>
    <div class="card card-primary" id="LinkCodeDetails" *ngIf="showDetail">
      <div class="card-header">
        <div class="row">
          <div class="col-md-10">
            <h4 class="card-title w-100">
              <span>Agent Link Code Detail</span>
            </h4>
          </div>
          <div class="col-md-2 my-auto text-right">
            <i class="fas fa-2x fa-times-circle" style="cursor:pointer" (click)="closeDetail()"></i>
          </div>
        </div>
      </div>
      <ng-container *ngIf="showUpdateForm">
        <form #agentLinkCodeUpdateForm="ngForm" (ngSubmit)="updateLinkCode(agentLinkCodeUpdateForm.value)">
          <ng-container *ngIf="selectedLinkCode$ | async as linkCode">
            <div class="card-body">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Agent Email</label>
                    <input type="text" class="form-control" name="agentEmail" id="agentEmail" readonly [(ngModel)]="linkCode.emailAddress">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Link Code</label>
                    <input type="text" class="form-control code-input" name="agentLinkCode" id="agentLinkCode" readonly [(ngModel)]="selectedCode">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Agent ID</label>
                    <input type="text" class="form-control" name="agentID" id="agentID" [(ngModel)]="linkCode.agentID"
                           (keyup)="changeAgentID($event)">
                    <input type="button" class="btn btn-secondary mt-2" value="Check Agent ID"
                           (click)="checkAgentID(agentLinkCodeUpdateForm.controls.agentID, agentLinkCodeUpdateForm.controls.agentName)">
                    &nbsp;&nbsp;&nbsp;
                    <span class="align-bottom" *ngIf="showAgentIDFound">
                      <i class="fad fa-check-circle fa-2x" style="--fa-primary-color: green; --fa-secondary-color: green; "></i>&nbsp;
                      Agent ID Found!
                    </span>
                    <span class="align-bottom" *ngIf="showAgentIDNotFound">
                      <i class="fad fa-times-circle fa-2x" style="--fa-primary-color: darkred; --fa-secondary-color: darkred; "></i>&nbsp;
                      Agent ID Not Found!
                    </span>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Agent Name</label>
                    <input type="text" class="form-control" name="agentName" id="agentName" readonly [(ngModel)]="linkCode.agentName">
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <div class="card-footer">
            <div class="text-center">
              <input type="submit" class="btn btn-secondary input-block-level" name="updateButton" id="updateButton" value="Update and Generate Code" [disabled]="disableUpdate">
            </div>
          </div>
        </form>
      </ng-container>
    </div>
  </div>
</div>
