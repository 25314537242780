import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { Profile } from '../../../models/profile';
import { SiteParameterService } from '../../../services/siteparameter.service';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styles: [
  ]
})
export class DocumentsPageComponent {

  public profile!: Profile;
  private channelUpdate: Subscription;
  public message: string = '';

  constructor(private router: Router, private siteParmService: SiteParameterService) {
    this.channelUpdate = this.siteParmService.getChannelUpdate().subscribe
      (message => {
        this.profile = this.siteParmService.getStaticProfile();
        if (this.profile.channelPreferenceValue !== 1 && this.profile.channelPreferenceValue !== 2) {
          this.router.navigate(['/home']);
        }
      });
  }

  ngOnDestroy() { // It's a good practice to unsubscribe to ensure no memory leaks
    this.channelUpdate.unsubscribe();
  }
}
