import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { agentLinkCode } from '../../../models/linkCode';
import { OnPremStorageService } from '../../../services/onprem-storage';
import { SiteParameterService } from '../../../services/siteparameter.service';
import { CellClassParams, RowSelectedEvent } from 'ag-grid-community';
import { AbstractControl } from '@angular/forms';


@Component({
  selector: 'app-linkcode',
  templateUrl: './link-code.component.html',
  styles: [
  ]
})
export class LinkCodeComponent implements OnInit {
  public showList: boolean = true;
  public showDetail: boolean = false;
  public showRefreshButton: boolean = true;
  public showUpdateForm: boolean = false;
  public disableUpdate: boolean = true;
  public showAgentIDFound: boolean = false;
  public showAgentIDNotFound: boolean = false;

  public linkCodes$?: Observable<agentLinkCode[]>;
  public selectedLinkCode$?: Observable<agentLinkCode>;
  public selectedCode: string = "";

  private gridApi: any;
  public noRowsTemplate: string = "";
  private gridColumnApi: any;

  private agentLinkCodeID: number = 0;
  private agentID: string = "";

  columnDefs = [
    { field: 'emailAddress', minWidth: 300 },
    { field: 'agentID' },
    { field: 'code', headerName: 'Link Code' },
    { field: 'codeStatus', cellStyle: cellStyle },
    { field: 'codeCreateDTM', headerName: 'Code Creation Date/Time' },
  ];
  defaultColumnDef = {
    sortable: true,
    resizable: true,
  };

  constructor(private onPremService: OnPremStorageService, private siteParmService: SiteParameterService) {
    this.noRowsTemplate = this.siteParmService.getGridNoResults();
  }
  ngOnInit(): void {
    this.listLinkCodes();
  }

  listLinkCodes() {
    this.linkCodes$ = this.onPremService.getAgentLinkCodeRecords().pipe(map(response => response.data));
  }

  onRefreshClicked() {
    this.listLinkCodes();
  }

  onRowClicked(event: RowSelectedEvent) {
    this.selectedLinkCode$ = this.onPremService.getAgentLinkCodeRecord(event.data.agentLinkCodeID)
      .pipe(map(response => response.data[0]));

    this.agentLinkCodeID = event.data.agentLinkCodeID;
    this.selectedCode = event.data.code;

    this.showList = false;
    this.showRefreshButton = false;
    this.showDetail = true;
    this.showUpdateForm = true;
  }

  closeDetail() {
    this.showAgentIDFound = false;
    this.showAgentIDNotFound = false;
    this.disableUpdate = true;

    this.showDetail = false;
    this.showList = true;
    this.showRefreshButton = true;
  }

  changeAgentID(event: any) {
    this.disableUpdate = true;
    this.showAgentIDFound = false;
    this.showAgentIDNotFound = false;
  }

  updateLinkCode(formData: any): void {
    this.onPremService.updateAgentLinkCodeRecord(this.agentLinkCodeID, formData.agentID)
      .pipe(take(1))
      .subscribe(apiResponse => {
        this.selectedCode = apiResponse.data[0].code;
      });

    this.showAgentIDFound = false;
    this.showAgentIDNotFound = false;
    this.disableUpdate = true;

    //this.showDetail = false;
    //this.showList = true;
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
  }

  checkAgentID(formAgentIdCtrl: AbstractControl, formAgentNameCtrl: AbstractControl) {
    var agentId = formAgentIdCtrl.value;

    this.onPremService.getAgentDetail(agentId)
      .pipe(take(1))
      .subscribe(apiResponse => {
        if (apiResponse.data.length === 0) {
          this.showAgentIDFound = false;
          this.showAgentIDNotFound = true;
          this.disableUpdate = true;
        }
        else {
          this.showAgentIDFound = true;
          this.showAgentIDNotFound = false;
          this.disableUpdate = false;
        }

        formAgentIdCtrl.setValue(apiResponse.data[0].agentID);
        formAgentNameCtrl.setValue(apiResponse.data[0].name);
      });
  }
}

function cellStyle(params: CellClassParams) {
  var color = params.node.data.codeStatusColor.toLowerCase();
  var fontColor = '#28a745';
  switch (color) {
    case 'success':
      fontColor = '#28a745';
      break;
    case 'warning':
      fontColor = '#ffc107';
      break;
    case 'info':
      fontColor = '#17a2b8';
      break;
    case 'danger':
      fontColor = '#dc3545';
      break;
  }

  return {
    color: fontColor,
  };
}
